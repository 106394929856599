import { configureStore, combineReducers } from "@reduxjs/toolkit"
import subscriptionSlice from "./redux/subscription.slice"
import pdfGenerateSlice from "./redux/pdfGenerate.slice"
import altadetectSlice from "./redux/altadetect.slice"
import getRememberChat from "./reducers/RememberChat"
import GetToastMessage from "./reducers/ToastMessage"
import workspaceSlice from "./redux/workspace.slice"
import GetTemConSlice from "./redux/GetTemConSlice"
import getAppSumoInfo from "./reducers/AppSumoInfo"
import templateSlice from "./redux/template.slice"
import projectSlice from "./redux/project.slice"
import storage from "redux-persist/lib/storage"
import getTrialInfo from "./reducers/TrialInfo"
import getChatWords from "./reducers/ChatWords"
import getWorkspace from "./reducers/Workspace"
import { persistReducer } from "redux-persist"
import dialogSlice from "./redux/dialog.slice"
import getAuthUser from "./reducers/AuthUser"
import GetUserTour from "./reducers/UserTour"
import otherSlice from "./redux/other.slice"
import authSlice from "./redux/auth.slice"
import blogSlice from "./redux/blog.slice"
import chatSlice from "./redux/chat.slice"
import FileSlice from "./redux/FileSlice"
import promptSlice from "./redux/prompt.slice"

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: ["GetTemConSlice", "templateSlice", "subscriptionSlice", "dialogSlice", "projectSlice", "altadetectSlice", "promptSlice"]
}

const reducer = combineReducers({
    authSlice,
    chatSlice,
    FileSlice,
    blogSlice,
    otherSlice,
    dialogSlice,
    promptSlice,
    projectSlice,
    templateSlice,
    workspaceSlice,
    altadetectSlice,
    pdfGenerateSlice,
    subscriptionSlice,
    getAuthUser: getAuthUser,
    GetUserTour: GetUserTour,
    getTrialInfo: getTrialInfo,
    getChatWords: getChatWords,
    getWorkspace: getWorkspace,
    GetTemConSlice: GetTemConSlice,
    getAppSumoInfo: getAppSumoInfo,
    GetToastMessage: GetToastMessage,
    getRememberChat: getRememberChat
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;