import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	listView: "GRID",
	editorText: ""
}

const other = createSlice({
	name: "other",
	initialState,
	reducers: {
		setListView: (state, { payload }) => {
			state.listView = payload
		},
		setEditorText: (state, { payload }) => {
			state.editorText = payload
		},
	}
})

export const { setListView, setEditorText } = other.actions;
export default other.reducer;