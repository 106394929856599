export const AuthUser = (data) => {
    return {
        type: "AUTHUSER",
        data,
    }
}

export const setTrialInfo = (data) => {
    return {
        type: "TRIALINFO",
        data,
    }
}

export const setChatWords = (data) => {
    return {
        type: "TOTALWORDS",
        data,
    }
}

export const setRememberChat = (data) => {
    return {
        type: "SETCHAT",
        data,
    }
}

export const setWorkspace = (data) => {
    return {
        type: "WORKSPACE",
        data,
    }
}

export const SetUserTour = (data) => {
    return {
        type: "USERTOUR",
        data,
    }
}

export const SetToastMessage = (data) => {
    return {
        type: "TOASTMESSAGE",
        data,
    }
} 

export const SetAppSumo = (data) => {
    return {
        type: "APPSUMO",
        data,
    }
} 