import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FiList } from "react-icons/fi";
import { Link } from 'react-router-dom';
import axios from 'axios';


const AddPrompt = () => {
    const [prompt, setPrompt] = useState('')
    const [category, setCategory] = useState('')
    const [spinner, setSpinner] = useState(true);
    const [categories, setCategories] = useState([])
    const [customname, setCustomename] = useState('')

    const categoryList = () => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/list-category`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false);
            hideLadingLoader();
        }).catch((err) => {
            setSpinner(false);
            hideLadingLoader();
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSpinner(true);
        const formData = { prompt: prompt, customname: customname, category: category };
        axios.post(`${process.env.REACT_APP_API_URL}/save-prompt-byadmin`, formData, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setPrompt('');
                setCategory('');
                setCustomename('');
                toast.success(res.data.message);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    useEffect(() => {
        categoryList();
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Add prompt</Card.Title>
                <Link to="/admin/prompt/list" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> prompt</Link>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className='m-auto'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Custome Name</Form.Label>
                                <Form.Select className="form-select" name="category" value={category} onChange={(e) => { setCategory(e.target.value) }}>
                                    <option defaultValue="">Select</option>
                                    {
                                        categories && categories.map((item, index) => {
                                            return (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Custome Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Custom name" name="customname" value={customname} onChange={(e) => { setCustomename(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control as="textarea" type="text" placeholder="Enter prompt" rows={5} name="prompt" value={prompt} onChange={(e) => { setPrompt(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                {
                                    (category === '' || customname === '' || prompt === '') ?
                                        <Button type="button" variant='success disabled text-capitalize'>submit</Button> :
                                        <Button type="submit" variant='success text-capitalize'>submit</Button>

                                }
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default AddPrompt;