import { FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { Fragment, forwardRef } from 'react'
// import { uid } from 'uid'

const CustomInput = forwardRef(({ label, name, value, id, type, onChangeFun, labelClass, placeholder, startIcon }, ref) => {

  return (
    <Fragment>
      {label &&
        <InputLabel htmlFor={id} className={`mb-1 capitalize !text-gray-800 ${labelClass ? labelClass : ""}`}>{label}</InputLabel>
      }
      <FormControl size="small" variant="outlined" fullWidth>
        <OutlinedInput
          id={id}
          rows={2}
          startAdornment={startIcon || ""}
          type={type}
          name={name}
          inputRef={ref}
          defaultValue={value}
          onChange={onChangeFun}
          placeholder={placeholder}
          multiline={type === "textarea"}
          inputComponent={type === "textarea" ? "textarea" : "input"}
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #8b6dba",
            }
          }}
        />
      </FormControl>
    </Fragment>
  )
})

export default CustomInput