import layoutStyles from "../../assets/css/layout.module.css"
import AppSumoSidebar from "../frontend/AppSumoSidebar"
import { Outlet, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import { addUser } from "../../redux/auth.slice"
import Siderbar from "../frontend/Siderbar"
import { SetUserTour } from "../../actions"
import { useTour } from "@reactour/tour"
import { Cookies } from "react-cookie"
import { styled } from "@mui/material"
import { headers } from "../../helper"
import axios from "axios"

const Div = styled("div")(() => ({}))

const UserLayout = () => {
	const [active, setActive] = useState(false)
	const cookies = new Cookies()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { setIsOpen } = useTour()
	const cookie = cookies.get("auth")
	const handleHideSidebar = () => { setActive(false) }
	const handleShowSidebar = () => { setActive(true) }
	const user = JSON.parse(localStorage.getItem("authuser"))
	const { user: auth } = useSelector(state => state.authSlice)
	const { activeWorkspace } = useSelector(state => state.workspaceSlice)

	const checkPlan = () => {
		if (!auth?.appsumocode && !auth?.currentplan && Object.keys(activeWorkspace)?.length === 0) {
			navigate("/dashboard")
			return true
		}
		if (auth?.currentplan && Object.keys(activeWorkspace)?.length === 0) {
			if (auth?.status === "free" || auth?.status === "canceled" || auth?.status === "expired") {
				navigate("/dashboard")
				return true
			}
		}
		if (!auth?.currentplan && Object.keys(activeWorkspace)?.length > 0) {
			if (activeWorkspace?.userId?.status === "free" || activeWorkspace?.userId?.status === "canceled" || activeWorkspace?.userId?.status === "expired") {
				if (!auth?.appsumocode && activeWorkspace?.userId?.login_type !== "appsumo") {
					navigate("/dashboard")
					return true
				}
			}
		}
	}

	const varifyAdmin = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/admin/verification`, {
			headers: headers()
		}).then((res) => {
			if (res.data?.success === true) {
				if (res?.data?.tour?.isCompleted === false || res?.data?.tour === null) {
					setIsOpen(true)
				} else {
					setIsOpen(false)
				}
				dispatch(SetUserTour(res.data.tour ? res.data.tour : {}))
				if (user?._id === res?.data?.user?._id && res?.data?.user?.appsumocode === null) {
					dispatch(addUser(res?.data?.user))
				}
				if (res.data?.data === "user" && cookie !== null && cookie !== undefined) {
					return true;
				}

				if (res.data?.data === "admin" && res.data?.data !== "user" && cookie !== null && cookie !== undefined) {
					return navigate("/admin");
				}
			}
		})

		if (document.querySelector(".user-sidebar") !== null && document.querySelector(".user-sidebar") !== undefined) {
			document.querySelector(".user-sidebar").classList.remove("sidebar-width");
		}
	}

	useEffect(() => {
		if (!cookie || cookie === "undefined") {
			return navigate("login")
		}
		!checkPlan() && varifyAdmin()

		// eslint-disable-next-line
	}, [])

	return ((cookie && cookie !== "undefined") &&
		<Fragment>
			<Div className={`${layoutStyles["container-wrapper"]} ${active ? layoutStyles["active"] : ""}`}>
				<Div
					onClick={handleHideSidebar}
					className={`${layoutStyles["sidebar-bg-layer"]}`}
				/>
				{(user?.login_type === "appsumo") ?
					<AppSumoSidebar handleSidebar={handleHideSidebar} /> :
					<Siderbar handleSidebar={handleHideSidebar} />
				}
				<Div className={`${layoutStyles["main-content-wrapper"]}`}>
					<Outlet context={[handleShowSidebar]} />
				</Div>
			</Div>
		</Fragment>
	)
}

export default UserLayout;