import { useNavigate, useSearchParams } from "react-router-dom"
import { varifyEmailReq } from "../../../redux/auth.slice"
import { hideDialog } from "../../../redux/dialog.slice"
import { useDispatch, useSelector } from "react-redux"
import Images from "../../../assets/images"
import Icons from "../../../assets/icons"
import OTPInput from "react-otp-input"
import { useState } from "react"
import {
  Box, CircularProgress, DialogContent,
  DialogTitle, Divider, IconButton, Paper,
  Typography
} from "@mui/material"

function OtpDialog() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [otp, setOtp] = useState("")
  const [searchParams] = useSearchParams()
  const planId = searchParams.get("pricing_id")
  const shareToken = searchParams.get("token_name")
  const auth = useSelector(state => state.authSlice)
  const { otpData } = useSelector(state => state.dialogSlice)

  const handleVarify = (value) => {
    setOtp(value)
    if (value.length === 6) {
      const data = { ...otpData, otp: value }
      if (planId) {
        dispatch(varifyEmailReq({ data, planId, navigate }))
      } else if (shareToken) {
        dispatch(varifyEmailReq({ data, shareToken, navigate }))
      } else {
        dispatch(varifyEmailReq({ data, navigate }))
      }
    }
  }
  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="OtpDialog">
      <DialogTitle component="div" className="p-2 relative">
        <Box className=" inline-block absolute top-[50%] right-[10px] translate-y-[-50%]">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Typography component="h6" className="title">Email varification!</Typography>
      </DialogTitle>
      <Divider className="!border-gray-500" />
      <DialogContent>
        <Box className="text-center">
          <img className="h-[50px] w-[150px] object-contain dialog-img" src={Images.logo} alt="logo" />
        </Box>
        <Typography className="content mb-4">Please enter the verification code sent to {otpData?.email}!</Typography>
        <OTPInput
          containerStyle="otp-parent justify-center gap-[2px] my-6"
          value={otp}
          numInputs={6}
          renderSeparator={<span> </span>}
          onInput={() => { console.log(otp) }}
          onChange={(value) => { handleVarify(value) }}
          renderInput={(props) => <input {...props} />}
        />
        {auth?.loading &&
          <Box className="p-8 grid place-content-center">
            <CircularProgress className="ms-2 !text-deluge-400" size={50} thickness={4} />
          </Box>
        }
      </DialogContent>
    </Paper>
  )
}

export default OtpDialog