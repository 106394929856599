import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURL, checktoken, headers } from "../helper"
import { hideDialog } from "./dialog.slice"
import { Cookies } from "react-cookie"
import { toast } from "react-toastify"
import axios from "axios"

const cookies = new Cookies()
// Contact sales team
export const sendContectEmailReq = createAsyncThunk("sendContectEmailReq", (data, { rejectWithValue, dispatch }) => {
	try {
		const response = axios.post(`${BASEURL}/contact/sales`, data, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			}
		}).then((res) => {
			dispatch(hideDialog())
			if (res?.data?.success) {
				toast.success(res.data.message);
			}
			return res?.data
		}).catch((err) => {
			dispatch(hideDialog())
			checktoken(err)
			if (err.response !== undefined) {
				if (err.response.data !== undefined) {
					toast.error(err.response.data.error);
				}
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get all subscription plans
export const getAllSubscriptions = createAsyncThunk("getAllSubscriptions", (id, { rejectWithValue }) => {
	try {
		const response = axios.get(`${BASEURL}/pricing-list`)
			.then((res) => {
				if (res.data.success === true) {
					return { id, data: res.data.data }
				}
			}).catch((err) => {
				checktoken(err)
				return rejectWithValue(err)
			})
		return response
	} catch (error) {
		console.log(error)
	}
})

// Cancel subscription plans
export const cancelSubscriptionReq = createAsyncThunk("cancelSubscriptionReq", (id, { rejectWithValue }) => {
	try {
		axios.delete(`${BASEURL}/user/payment/link/cancel/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			}
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message)
				// subscriptionDetails();
			}
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})
	} catch (error) {
		console.log(error)
	}
})

// Create payment request
export const createPaymentLinkReq = createAsyncThunk("sendContectEmailReq", ({ planId, navigate }, { rejectWithValue }) => {
	try {
		const response = axios.post(`${BASEURL}/payment-create-link`, { planId }, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				window.location.replace(res.data.data.url);
			}
			return res
		}).catch((err) => {
			navigate(`/pricing`)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

const initialState = {
	loading: false,
	allSubscriptions: {},
	currentSubscription: {}
}
const subscription = createSlice({
	name: "subscription",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Contact sales team
		builder.addCase(sendContectEmailReq.pending, (state) => {
			state.loading = true
		})
		builder.addCase(sendContectEmailReq.fulfilled, (state) => {
			state.loading = false
		})
		builder.addCase(sendContectEmailReq.rejected, (state) => {
			state.loading = false
		})

		// Get all subscription plans
		builder.addCase(getAllSubscriptions.pending, (state) => {
			state.allSubscriptions.loading = true
		})
		builder.addCase(getAllSubscriptions.fulfilled, (state, { payload }) => {
			const subs = payload.data?.find((item) => {
				const key = process.env.REACT_APP_ENV_MODE === "development" ? item.local_key : item.live_key
				return key === payload.id
			})
			state.currentSubscription = subs
			delete state.allSubscriptions.loading
			state.allSubscriptions.data = payload?.data
		})
		builder.addCase(getAllSubscriptions.rejected, (state) => {
			delete state.allSubscriptions.loading
		})

		// Logout
		builder.addCase("LOGOUT", () => initialState)
	}
})

export default subscription.reducer;