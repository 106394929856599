
import Favicon from '../../../assets/images/favicone.png';
import { ThreeDots } from 'react-loader-spinner';
import React, { Fragment } from 'react';

function ChatSpinner() {
  return (
    <Fragment>
      <div className="d-flex chat-response mx-auto p-3 mb-2 align-items-start false w-100">
        <div className="d-block">
          <span className='prompt-small-icon'><img src={Favicon} alt="img" style={{ width: '15px', filter: 'brightness(0) invert(1)' }} /></span>
        </div>
        <div className="d-block flex-grow-1">
          <ThreeDots
            height="30"
            width="50"
            radius="9"
            color="#7959a6"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default ChatSpinner;