import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import { BsFilePdfFill, BsRobot, BsBookmarkFill, BsFillFileEarmarkPdfFill } from "react-icons/bs"
import { MdOutlineAttachFile } from "react-icons/md"
import { TbFileAnalytics } from "react-icons/tb"
import { IoDocumentText } from "react-icons/io5"
import { HiOutlineMail } from "react-icons/hi"
import { BiEditAlt } from "react-icons/bi"
import { RxCross2 } from "react-icons/rx"
import { TbApiApp } from "react-icons/tb"

import {
  FaCode, FaRegEdit, FaHandPointer,
  FaHandHoldingUsd, FaStar
} from "react-icons/fa"
import {
  FiLayers, FiGrid, FiList, FiCalendar,
  FiMoreHorizontal, FiUser, FiTrash,
  FiRefreshCw, FiMenu, FiBarChart2,
  FiFileText, FiHash, FiClipboard,
  FiSend, FiBookmark, FiAward, FiPlus,
  FiTrash2, FiShare2, FiArrowRight,
  FiCheck, FiSearch, FiEdit, FiInfo,
  FiSunrise, FiArrowLeft, FiShield,
  FiUserCheck, FiHeadphones, FiSettings,
  FiBook, FiLock, FiMessageSquare, FiFile,
  FiCreditCard, FiUsers, FiDollarSign,
  FiLogOut, FiHeart, FiMinusCircle
} from "react-icons/fi"

export const MuiIcons = {
  DownArrow: KeyboardArrowDownRoundedIcon
}

const Icons = {
  MuiCrossBtnSqr: DisabledByDefaultRoundedIcon,
  MuiCloudUpload: CloudUploadRoundedIcon,
  MuiSearchIcon: SearchRoundedIcon,
  MuiLink: LinkRoundedIcon,

  FaHandHoldingUsd: FaHandHoldingUsd,
  FileAnalysis: TbFileAnalytics,
  FiThreeDot: FiMoreHorizontal,
  FaHandPointer: FaHandPointer,
  FiArrowRight: FiArrowRight,
  FiHeadphones: FiHeadphones,
  Pin: MdOutlineAttachFile,
  FiArrowLeft: FiArrowLeft,
  FiUserCheck: FiUserCheck,
  FiSettings: FiSettings,
  Clipboard: FiClipboard,
  FiCalendar: FiCalendar,
  BarChart: FiBarChart2,
  Bookmark: FiBookmark,
  Refresh: FiRefreshCw,
  FiSunrise: FiSunrise,
  Mail: HiOutlineMail,
  DOC: IoDocumentText,
  PDF: BsFilePdfFill,
  FiLayers: FiLayers,
  FiShield: FiShield,
  FiTrash2: FiTrash2,
  FiShare2: FiShare2,
  FiSearch: FiSearch,
  BsRobot: BsRobot,
  Edit1: FaRegEdit,
  FiTrash: FiTrash,
  File: FiFileText,
  FiCheck: FiCheck,
  Cross: RxCross2,
  FiLock: FiLock,
  FiBook: FiBook,
  Edit: BiEditAlt,
  FiSend: FiSend,
  FiInfo: FiInfo,
  FiMenu: FiMenu,
  FiEdit: FiEdit,
  Badge: FiAward,
  FiGrid: FiGrid,
  FiList: FiList,
  FiUser: FiUser,
  FaStar: FaStar,
  FiPlus: FiPlus,
  FiMessageSquare,
  BsBookmarkFill,
  Code: FaCode,
  Hash: FiHash,
  FiCreditCard,
  FiDollarSign,
  FiMinusCircle,
  TbApiApp,
  FiLogOut,
  FiUsers,
  FiFile,
  FiHeart,
  BsFillFileEarmarkPdfFill,
}

export default Icons