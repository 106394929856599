import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism } from 'react-syntax-highlighter';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import 'github-markdown-css';
import { memo } from 'react';


function MarkdownViewer({ content }) {

  const renderers = {
    code({ children, className, node, ...rest }) {
      const match = /language-(\w+)/.exec(className || '')
      return match ?
        <Prism
          {...rest}
          PreTag="div"
          children={String(children).replace(/\n$/, '')}
          language={match[1]}
          style={oneLight}
        /> :
        <code {...rest} className={className}>
          {children}
        </code>
    }
  };

  return (
    <div className="markdown-body">
      <ReactMarkdown components={renderers} rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
    </div>
  );
}

export default memo(MarkdownViewer);