import { FiAward, FiLogOut, FiUser, FiDollarSign, FiCreditCard, FiUsers } from "react-icons/fi"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { getAllWorksapace, loginWorkspaceReq } from "../../redux/workspace.slice"
import layoutStyles from "../../assets/css/layout.module.css"
import { setChatWords, SetAppSumo } from "../../actions/index"
import { Button, IconButton, Stack, styled } from "@mui/material"
import AltacopyDropdown from "../common/AltacopyDropdown"
import { Nav, Dropdown, Badge } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { showDialog } from "../../redux/dialog.slice"
import { googleLogout } from "@react-oauth/google"
import { useState, useEffect } from "react"
import { checktoken } from "../../helper"
import { TbApiApp } from "react-icons/tb"
import SideMenu from "../common/SideMenu"
import Icons from "../../assets/icons"
import { Cookies } from "react-cookie"
import Loader from "./TextLoader"
import Toaster from "./Toaster"
import axios from "axios"

const Div = styled("div")(() => ({}))

const menus = [
	{
		path: "/user/chat",
		startIcon: <Icons.FiMessageSquare />,
		name: <><span className="alta-text">Alta</span>Chat</>,
	},
	{
		name: "Projects",
		path: "/user/projects",
		startIcon: <Icons.FiLayers />,
	},
	{
		path: "/user/brand",
		name: "Brand Voice",
		startIcon: <Icons.BarChart />,
	},
	{
		path: "/",
		name: "Templates",
		startIcon: <Icons.FiFile />,
	},
	{
		path: "/user/alta-detect",
		startIcon: <Icons.FiMessageSquare />,
		name: <><span className="alta-text">Alta</span>Detect</>,
	},
]

export default function Siderbar({ handleSidebar }) {
	const cookies = new Cookies()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [user, setUser] = useState("")
	const [searchParams] = useSearchParams()
	const template = searchParams.get("template")
	const { user: auth } = useSelector(state => state.authSlice)
	const { totalWords } = useSelector(state => state.chatSlice)
	const message = useSelector((state) => state.GetToastMessage)
	const { loading,
		allSharedWorkSapce,
		activeWorkspace } = useSelector(state => state.workspaceSlice)

	const handleNavigate = (path) => {
		handleSidebar()
		if (Object?.keys(activeWorkspace)?.length > 0) {
			if (activeWorkspace?.userId?.status === "active" || activeWorkspace?.userId?.status === "trialing") {
				return navigate(path)
			}
			return dispatch(showDialog("ReachLimit"))
		}
		if (auth?.currentplan?.packagename === "SOLO" &&
			totalWords >= 50000) {
			return dispatch(showDialog("ReachLimit"))
		} else {
			return navigate(path)
		}
	}

	const logOut = () => {
		googleLogout();
		dispatch({ type: "LOGOUT" })
		dispatch(setChatWords(0));
		localStorage.clear();
		cookies.remove("auth", { path: "/" });
		cookies.remove("workspaceauth", { path: "/" });
		navigate("/login");
	}

	const getUserData = (e) => {
		axios.get(`${process.env.REACT_APP_API_URL}/profile-edit/`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			}
		}).then((res) => {
			if (res.data.success === true) {
				setUser(res.data.data)
				dispatch(SetAppSumo(res.data.data?.appsumouser))
				localStorage.removeItem("authuser");
				localStorage.setItem("authuser", JSON.stringify(res.data.data));
			}
		}).catch((err) => { checktoken(err) })
	}

	const handleSwitchUser = (data) => {
		dispatch(loginWorkspaceReq({ data, navigate }))
	}

	const handleGetWorkspace = () => {
		dispatch(getAllWorksapace(navigate))
	}

	useEffect(() => {
		handleGetWorkspace();
		getUserData();
		if (template !== undefined && template !== null) {
			dispatch(showDialog("AllTemplates"))
		}

		// eslint-disable-next-line
	}, [navigate, message]);

	return (
		loading ?
			<Loader /> :
			<Div className={`${layoutStyles["sidebar-container"]} custom-scrollbar`}>
				<div className="sidebar-header position-relative flex justify-between items-center gap-[8px] mx-2">
					<Link to="/" className="mx-auto"><img className="mx-auto" src="https://storage.googleapis.com/goalta_bucket/logo.png" style={{ width: "100px" }} alt="img" /></Link>
					<IconButton
						size="small"
						onClick={handleSidebar}
						sx={{
							color: "white",
							bgcolor: "#7959a6 !important",
							display: { xs: "flex", laptop: "none" },
						}}
					><Icons.Cross />
					</IconButton>
				</div>

				<Stack spacing={0.5}>
					<SideMenu
						menu={menus[0]}
						addClass="tour-step-1"
						active={pathname === "/user/chat"}
						handleNavigate={() => { handleNavigate("/user/chat") }}
					/>
					<SideMenu
						menu={menus[1]}
						addClass="tour-step-6"
						active={pathname === "/user/projects"}
						handleNavigate={() => { handleNavigate("/user/projects") }}
					/>
					<SideMenu
						menu={menus[2]}
						addClass="tour-step-7"
						active={pathname.includes("brand")}
						handleNavigate={() => { handleNavigate("/user/brand") }}
					/>
					<AltacopyDropdown />
					<SideMenu
						menu={menus[4]}
						addClass="tour-step-9"
						active={pathname === "/user/alta-detect"}
						handleNavigate={() => { handleNavigate("/user/alta-detect", true) }}
						proBadge={auth?.currentplan?.packagename === "SOLO" && Object.keys(activeWorkspace)?.length === 0}
					/>
				</Stack>

				<Nav className="side-menu flex-column mt-auto" style={{ height: "auto" }}>
					{(auth?.currentplan?.packagename !== "PRO") && (auth?.currentplan?.status !== "free") &&
						<Button
							to="/pricing"
							variant="contained"
							LinkComponent={Link}
							endIcon={<Icons.Badge />}
							sx={{ mx: 2 }}
							className="fill-btn clr-primary !mb-2"
						>Upgrade to Pro+
						</Button>
					}

					<Nav.Item>
						<Dropdown>
							<Dropdown.Toggle id="dropdown-basic" className="text-capitalize">
								<div className="d-flex align-items-center">
									<span className="user-side-info d-flex align-items-center justify-content-center text-uppercase">{user?.name?.split(" ")[0]?.split("")[0]}{user?.name?.split(" ")[1]?.split("")[0]}</span>
									<div className="flex-grow-1 text-start ps-2">
										<span className="text-capitalize d-block">personal workspace </span>
										<span className="text-capitalize d-block" style={{ fontSize: "12px" }}>
											{(auth?.currentplan?.status === "canceled" || auth?.currentplan?.status === "active") && "paid plan"}
											{(auth?.currentplan?.status === "trialing") && "trial plan"}
											{user?.appsumocode !== null && "Paid plan"}
										</span>
									</div>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="w-100 pt-0">
								{allSharedWorkSapce?.length === 0 &&
									<div className="d-block user-detail">
										<div className="p-2 workspace-email d-flex align-items-center justify-content-between">
											{user?.email}
										</div>
										<div className="d-flex user-info-user align-items-center p-2 active-workspace">
											<span className="user-side-info d-flex align-items-center justify-content-center text-uppercase">{user?.name?.split(" ")[0]?.split("")[0]}{user?.name?.split(" ")[1]?.split("")[0]}</span>
											<div className="flex-grow-1 text-start ps-2">
												<span className="text-capitalize d-block">personal workspace</span>
												<span className="text-capitalize d-block" style={{ fontSize: "12px" }}>
													{(user.appsumocode === null && user.status === "active") && "Paid Plan"}
													{(user.appsumocode === null && user.plan === "Solo") && "Paid Plan"}
													{user.appsumocode !== null && "Paid plan"}
												</span>
											</div>
										</div>
									</div>
								}

								{allSharedWorkSapce?.length > 0 &&
									<div className="d-block user-detail">
										{(Object.keys(activeWorkspace).length === 0) &&
											<div className="d-block" style={{ cursor: "pointer" }}>
												<div className="p-2 workspace-email d-flex align-items-center justify-content-between">
													{user.email}
												</div>
												<div className="d-flex user-info-user align-items-center p-2 active-workspace">
													<span className="user-side-info d-flex align-items-center justify-content-center text-uppercase">{user?.name?.split(" ")[0]?.split("")[0]}{user?.name?.split(" ")[1]?.split("")[0]}</span>
													<div className="flex-grow-1 text-start ps-2">
														<span className="text-capitalize d-block">personal workspace</span>
														<span className="text-capitalize d-block" style={{ fontSize: "12px" }}>
															{(user.appsumocode === null && user.status === "active") && "Paid Plan"}
															{user.appsumocode !== null && "Paid plan"}
														</span>
													</div>
												</div>
											</div>
										}
										{allSharedWorkSapce &&
											allSharedWorkSapce.map((item, index) => {

												return (
													<div key={index} className={`${item.current_workspace === 1 && "active-user"}`}>
														<div className="p-2 workspace-email d-flex align-items-center justify-content-between">
															{item?.userId?.email}
														</div>
														<div className={`d-flex user-info-user align-items-center p-2 ${item.current_workspace === 1 && "active-workspace"}`} onClick={() => handleSwitchUser({ id: item._id, email: item.userId.email })} type="submit" style={{ background: "#f3f9f9" }}>
															<span className="user-side-info d-flex align-items-center justify-content-center text-uppercase">{item?.userId?.name.split(" ")[0]?.split("")[0]}{item?.userId?.name.split(" ")[1]?.split("")[0]}</span>
															<div className="flex-grow-1 text-start ps-2">
																<span className="text-capitalize d-block">personal workspace</span>
																<span className="text-capitalize d-block" style={{ fontSize: "12px" }}>
																	{"Paid plan"}
																</span>
															</div>
														</div>
													</div>
												)
											})
										}
										{(Object.keys(activeWorkspace).length > 0) &&
											<div className="d-block" style={{ cursor: "pointer" }}>
												<div className="p-2 workspace-email d-flex align-items-center justify-content-between">
													{user.email}
												</div>
												<div className="d-flex user-info-user align-items-center p-2" onClick={() => handleSwitchUser({ id: activeWorkspace._id, email: activeWorkspace.userId.email, status: "remove" })} style={{ background: "#f3f9f9" }}>
													<span className="user-side-info d-flex align-items-center justify-content-center text-uppercase">{user?.name?.split(" ")[0]?.split("")[0]}{user?.name?.split(" ")[1]?.split("")[0]}</span>
													<div className="flex-grow-1 text-start ps-2">
														<span className="text-capitalize d-block">personal workspace</span>
														<span className="text-capitalize d-block" style={{ fontSize: "12px" }}>
															{user.appsumocode === null && (user.status === "free") && "free plan"}
															{(user.appsumocode === null && user.status === "active" || user.status === "canceled") && "Paid Plan"}
															{user.appsumocode !== null && "Paid plan"}
														</span>
													</div>
												</div>
											</div>
										}
									</div>
								}

								<Dropdown.Divider />
								<div className="d-block px-2">
									<Link to="https://goalta.firstpromoter.com/" target="_blank" className="dropdown-item me-2" onClick={handleSidebar}><FiUsers className="me-2" />Affiliate</Link>

									<Dropdown.Item to="/user/subscription" as={Link} onClick={handleSidebar}><FiCreditCard className="me-2" /> Subscription</Dropdown.Item>
									<Dropdown.Item to="#" as={Link} onClick={handleSidebar}><TbApiApp className="fs-6" style={{ marginRight: "6px" }} /> Model Library &nbsp;
										<Badge className="ms-auto text-white pro-badge text-soon" style={{ fontSize: "9px" }}>Coming Soon</Badge>
									</Dropdown.Item>

									<Dropdown.Item
										as={Link}
										type="link"
										onClick={handleSidebar}
										to={`${auth?.currentplan?.packagename !== "PRO" ? "/pricing" : "/user/shared/user"}`}
									><FiUsers className="me-2" /> Shared Workspace
										{auth?.currentplan?.packagename !== "PRO" && Object.keys(activeWorkspace)?.length === 0 && <Badge className="ms-auto text-white pro-badge text-soon" style={{ fontSize: "9px" }}>Pro</Badge>}
									</Dropdown.Item>

									<Dropdown.Item to="/user/payment-list" as={Link} onClick={handleSidebar}><FiDollarSign className="me-2" /> Transactions</Dropdown.Item>
									<Dropdown.Item to="/user/profile" as={Link} onClick={handleSidebar}><FiUser className="me-2" /> Profile</Dropdown.Item>
								</div>
								<Dropdown.Divider />
								<div className="d-block px-2">
									<Dropdown.Item href="#" onClick={(e) => { e.preventDefault(); logOut(); }}><FiLogOut className="me-2" />Logout </Dropdown.Item>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</Nav.Item>
				</Nav>

				<Toaster />
			</Div>
	)
}



