import { Box, Grid, IconButton, Paper, Skeleton, Stack, Typography } from "@mui/material"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import DialogContentText from "@mui/material/DialogContentText"
import { createProjectReq } from "../../../redux/project.slice"
import { Fragment, useEffect, useRef, useState } from "react"
import { hideDialog } from "../../../redux/dialog.slice"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useDispatch, useSelector } from "react-redux"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import Icons from "../../../assets/icons"
import CustomInput from "../CustomInput"
import {
  getCustomTemplatesReq,
  getTemplateCateReq,
  getTemplateListReq,
  getTemplatesByCateReq
} from "../../../redux/template.slice"


function AllTemplates() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const param = searchParams.get("template")
  const descriptionElementRef = useRef(null)
  const [activeCat, setActiveCat] = useState("")
  const [activeTemp, setActiveTemp] = useState("")
  const { show } = useSelector(state => state.dialogSlice)
  const { categories, templates } = useSelector(state => state.templateSlice)

  let timer;
  const handleSearch = (e) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (activeCat !== "" && activeCat !== "custom") {
        dispatch(getTemplatesByCateReq({ id: activeCat, text: e.target.value }))
      } else {
        dispatch(getTemplateListReq({ text: e.target.value }))
      }
    }, 2000)
  }

  const handleClose = () => {
    if (param === "") { navigate(pathname) }
    dispatch(hideDialog())
  }

  const handleGetAllTemplates = () => {
    setActiveCat("")
    dispatch(getTemplateListReq({ text: "" }))
  }

  const getCustomTemp = () => {
    setActiveCat("custom")
    dispatch(getCustomTemplatesReq({ text: "" }))
  }
  const getTempByCate = (id) => {
    setActiveCat(id)
    dispatch(getTemplatesByCateReq({ id, text: "" }))
  }

  const handleCreateProject = () => {
    dispatch(createProjectReq({ name: "demo", templateId: activeTemp, navigate }))
  }

  useEffect(() => {
    dispatch(getTemplateCateReq())
    dispatch(getTemplateListReq({ text: "" }))

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (show) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [show]);

  return (
    <Fragment>
      <DialogTitle component="div"  >
        <Box className="flex gap-[8px] items-center">
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={handleGetAllTemplates}
              className="fill-btn clr-dark text-nowrap"
            >All Templates
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                navigate("/user/saved/template")
                dispatch(hideDialog())
              }}
              className="fill-btn clr-dark text-nowrap !ms-1"
            >Saved Copies
            </Button>
          </Box>
          <Box className="flex-grow">
            <CustomInput
              type="text"
              onChangeFun={handleSearch}
              startIcon={<Icons.MuiSearchIcon
                className="text-deluge-600"
              />}
            />
          </Box>
          <IconButton
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="!text-3xl text-deluge-600" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={handleGetAllTemplates}
            className="fill-btn clr-primary text-nowrap"
          >All Templates
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              navigate("/user/saved/template")
              dispatch(hideDialog())
            }}
            className="fill-btn clr-primary text-nowrap !ms-1"
          >Saved Copies
          </Button>
        </Box>

      </DialogTitle>
      <DialogContent
        dividers
        id="AllTemplates"
        className="gap-[8px] custom-scroll"
        sx={{
          position: { sm: "relative" },
          display: { xs: "block", sm: "flex" },
        }}
      >
        <Stack
          gap={1.5}
          sx={{
            top: { sm: "0" },
            overflowY: "auto",
            minWidth: { xs: "100%", sm: "200px" },
            marginBottom: { xs: "10px", sm: "0" },
            flexWrap: { xs: "wrap", sm: "nowrap" },
            position: { xs: "unset", sm: "sticky" },
          }}
          className="custom-scroll"
          direction={{ xs: "row", sm: "column" }}
        >
          {categories?.loading ?
            new Array(5).fill(0).map((_, i) => (
              <Skeleton key={i} className="!h-[35px] w-full !transform-none" />
            )) :
            <Fragment>
              <Button
                sx={{ mr: 0.5 }}
                onClick={getCustomTemp}
                variant={activeCat === "custom" ? "contained" : "outlined"}
                className={`clr-primary !justify-start ${activeCat === "custom" ? "fill-btn" : "border-btn"}`}
              >Custom
              </Button>
              {categories?.data?.map((cat) => (
                <Button
                  key={cat?._id}
                  sx={{ mr: 0.5 }}
                  onClick={() => { getTempByCate(cat?._id) }}
                  variant={activeCat === cat?._id ? "contained" : "outlined"}
                  className={`clr-primary !justify-start ${activeCat === cat?._id ? "fill-btn" : "border-btn"}`}
                >{cat?.name}
                </Button>
              ))
              }
            </Fragment>
          }
        </Stack>
        <DialogContentText ref={descriptionElementRef} component="div" className="flex-grow">
          <Grid container spacing={1}>
            {templates?.loading ?
              new Array(6).fill(0).map((_, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Skeleton className="!h-[120px] w-full !transform-none" />
                </Grid>
              )) :
              <Fragment>
                {templates?.data && templates?.data?.map((temp, i) => (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                    <Paper
                      onClick={() => { setActiveTemp(temp?._id) }}
                      className={`single-template transitions ${activeTemp === temp?._id ? "active" : ""}`}
                    >
                      <Box><Icons.File className="!text-2xl" /></Box>
                      <Box>
                        <Typography className="!font-ff-poppins !text-lg capitalize text-gray-800">{temp?.name}</Typography>
                      </Box>
                      <Box>
                        <Typography className="!font-ff-lato !text-md capitalize text-gray-600">{temp?.description}</Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))
                }
              </Fragment>
            }
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!justify-between !px-[24px]">
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/user/create-template")
            dispatch(hideDialog())
          }}
          className="border-btn clr-primary !border-deluge-600"
        >Create Template
        </Button>
        <Button
          disabled={activeTemp === ""}
          variant="contained"
          onClick={activeTemp !== "" ? handleCreateProject : null}
          className="fill-btn clr-primary"
        >Start New Copy
        </Button>
      </DialogActions>
    </Fragment>
  );
}

export default AllTemplates