import { hideDialog } from "../../../redux/dialog.slice"
import Images from "../../../assets/images"
import CustomSelect from "../CustomSelect"
import Icons from "../../../assets/icons"
import { useDispatch, useSelector } from "react-redux"
import CustomInput from "../CustomInput"
import {
  Box, Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, Divider,
  Grid, IconButton, Paper, Typography
} from "@mui/material"
import { useRef } from "react"
import { sendContectEmailReq } from "../../../redux/subscription.slice"

const options = [
  { value: "2-10", text: "2-10" },
  { value: "11-20", text: "11-20" },
  { value: "21-30", text: "21-30" },
  { value: "31-40", text: "31-40" },
  { value: "41 & more", text: "41 & more" },
]

function SalesDialog() {
  const dispatch = useDispatch()
  const numberOfUser = useRef("[object Object]")
  const { loading } = useSelector(state => state.subscriptionSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.numberofuser = numberOfUser?.current?.value?.value
    dispatch(sendContectEmailReq(formData))
  }
  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="SalesDialog" component="form" onSubmit={handleSubmit}>
      <DialogTitle component="div" className="p-2 relative">
        <Box className=" inline-block absolute top-[50%] right-[10px] translate-y-[-50%]">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Typography component="h6" className="title">Contact Sales Team!</Typography>
      </DialogTitle>
      <Divider className="!border-gray-500" />
      <DialogContent>
        <Box className="text-center">
          <img className="h-[50px] w-[150px] object-contain mb-4 dialog-img" src={Images.logo} alt="logo" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomInput
              id="name"
              type="text"
              name="name"
              label="name"
              placeholder="Enter your name"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              id="email"
              name="email"
              type="email"
              label="email address"
              placeholder="Enter your email"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              type="text"
              id="company"
              name="companyname"
              label="company name"
              placeholder="Enter company name"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              id="numberofuser"
              ref={numberOfUser}
              options={options}
              label="Numbers of users"
              select="Select the agency"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              type="textarea"
              label="Message"
              id="description"
              name="description"
              placeholder="Enter description"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider className="!border-gray-500" />
      <DialogActions sx={{ justifyContent: "end", px: "24px" }}>
        <Button
          size="small"
          type="submit"
          variant="contained"
          className="fill-btn clr-primary"
        >Send Request
          {loading &&
            <CircularProgress
              size={20}
              thickness={5.5}
              sx={{ display: "flex", ml: "4px", color: "white" }}
            />
          }
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default SalesDialog