import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createPaymentLinkReq } from "./subscription.slice"
import { hideDialog, showOtpDialog } from "./dialog.slice"
import { Cookies } from "react-cookie"
import { toast } from "react-toastify"
import { BASEURL } from "../helper"
import axios from "axios"

const cookies = new Cookies()

// Forgot password request
export const forgotPassReq = createAsyncThunk("forgotPassReq", (data, { rejectWithValue }) => {
	try {
		const response = axios.post(`${BASEURL}/send-link-email`, data, {
			withCredentials: true,
			credentials: "include",
			headers: { "Content-Type": "application/json" }
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message)
			}
			return res
		}).catch((err) => {
			if (err.response !== undefined) {
				toast.error(err.response.data.error)
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Reset password request
export const resetPassReq = createAsyncThunk("forgotPassReq", ({ data, navigate }, { rejectWithValue }) => {
	try {
		const response = axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, data, {
			withCredentials: true,
			credentials: "include",
			headers: { "Content-Type": "application/json" }
		}).then((res) => {
			if (res.data.success === true) {
				toast.error("Password changed!")
				navigate("/login", { state: "reset-password" })
			}
			return res
		}).catch((err) => {
			if (err.response !== undefined) {
				toast.error(err.response.data.message)
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get user info from google account
export const getGoogleInfoReq = createAsyncThunk("getGoogleInfoReq", async ({ token, planId, shareToken, navigate }, { dispatch }) => {
	try {
		await new Promise(resolve => {
			const xhr = new XMLHttpRequest()
			xhr.open("GET", `https://www.googleapis.com/oauth2/v3/userinfo`)
			xhr.setRequestHeader("Authorization", `Bearer ${token}`)
			xhr.onload = function () {
				if (this.status >= 200 && this.status < 300) {
					if (planId) {
						dispatch(googleLoginReq({ data: this.responseText, planId, navigate }))
					} else if (shareToken) {
						dispatch(googleLoginReq({ data: this.responseText, shareToken, navigate }))
					} else {
						dispatch(googleLoginReq({ data: this.responseText, navigate }))
					}
					resolve(JSON.parse(this.responseText))
				} else {
					resolve({ err: "404" })
				}
			}
			xhr.send()
		})
	} catch (error) {
		console.log(error)
	}
})

// Login with google request
export const googleLoginReq = createAsyncThunk("googleLoginReq", ({ data, planId, shareToken, navigate }, { rejectWithValue, dispatch }) => {
	try {
		const response = axios.post(`${process.env.REACT_APP_API_URL}/google-signup-new`, data, {
			withCredentials: true,
			credentials: "include",
			headers: {
				"Content-Type": "application/json"
			}
		}).then((res) => {
			if (res?.data?.success) {
				if (res.data.role === 'user') {
					cookies.set('auth', res.data.token, { path: '/' })
					localStorage.setItem('authuser', JSON.stringify(res.data.user))
					if (!res.data.user?.currentplan && res.data.user?.login_type !== "appsumo") {
						if (planId) {
							dispatch(createPaymentLinkReq({ planId, navigate }))
						} else if (shareToken) {
							navigate(`/dashboard?token_name=${shareToken}`)
						} else {
							navigate('/pricing')
						}
					} else {
						navigate('/user/chat', { state: "Login" })
					}
					return res.data?.user
				} else {
					toast.error("Unable to login Please try again!")
					cookies.remove('auth', { path: '/' })
					navigate('/login')
				}
			} else {
				toast.error(res.data.message)
			}
			return res.data
		}).catch((err) => {
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Registeration request
export const handleRegisterReq = createAsyncThunk("googleLoginReq", (data, { rejectWithValue, dispatch }) => {
	try {
		const response = axios.post(`${process.env.REACT_APP_API_URL}/register`, data
		).then((res) => {
			console.log(res)
		}).catch((err) => {
			if (err?.response?.data?.success === false) {
				if (err.response.data?.status === "email") {
					dispatch(showOtpDialog({
						type: "OtpDialog",
						data: { email: data?.email, password: data?.password }
					}))
				}
				if (err.response.data?.error !== undefined) {
					toast.error(err.response.data.error)
				}
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Login request
export const handleLoginReq = createAsyncThunk("googleLoginReq", ({ data, planId, shareToken, navigate }, { rejectWithValue, dispatch }) => {
	try {
		const response = axios.post(`${process.env.REACT_APP_API_URL}/login`, data, {
			withCredentials: true,
			credentials: "include",
			headers: {
				"Content-Type": "application/json"
			}
		}).then((res) => {
			if (res.data.success === true) {
				if (res.data.role === "user") {
					localStorage.setItem("authuser", JSON.stringify(res.data.authuser))
					cookies.set("auth", res.data.token, { path: "/" })
					dispatch(addUser(res.data.authuser))
					if (!res.data.authuser?.currentplan) {
						if (res.data.authuser?.login_type !== "appsumo") {
							if (planId) {
								dispatch(createPaymentLinkReq({ planId, navigate }))
							} else if (shareToken) {
								navigate(`/dashboard?token_name=${shareToken}`)
							} else {
								navigate("/pricing")
							}
						} else {
							navigate("/user/chat", { state: "Login" })
						}
					} else {
						navigate("/user/chat", { state: "Login" })
					}
					return res.data?.authuser
				} else {
					toast.error("Unable to login Please try again!")
					cookies.remove("auth", { path: "/" })
					navigate("/")
				}
			}
			if (!res?.data?.success) {
				toast.error(res?.data?.error)
			}
			return res.data
		}).catch((err) => {
			if (err.response !== undefined) {
				if (err?.response?.data?.success === false) {
					if (err.response.data?.status === "email") {
						dispatch(showOtpDialog({ type: "OtpDialog", data }))
					}
					if (err.response.data.error !== undefined) {
						toast.error(err.response.data.error)
					}
				}
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Varify email request
export const varifyEmailReq = createAsyncThunk("googleLoginReq", ({ data, planId, shareToken, navigate }, { rejectWithValue, dispatch }) => {
	try {
		const response = axios.post(`${process.env.REACT_APP_API_URL}/verify/email`, data
		).then((res) => {
			dispatch(hideDialog())
			if (res?.data?.success) {
				if (res?.data?.role === "user") {
					cookies.set("auth", res?.data?.token, { path: "/" })
					localStorage.setItem("authuser", JSON.stringify(res?.data?.user))
					if (planId) {
						dispatch(createPaymentLinkReq({ planId, navigate }))
					} else if (shareToken) {
						navigate(`/dashboard?token_name=${shareToken}`)
					} else {
						navigate(`/pricing`)
					}
				} else {
					toast.error("Unable to login Please try again!")
					cookies.remove("auth", { path: "/" })
					navigate("/login")
				}
			} else {
				toast.error(res?.data?.message)
			}
			return res?.data?.user
		}).catch((err) => {
			dispatch(hideDialog())
			if (err.response !== undefined) {
				if (err.response.data.error !== undefined) {
					toast.error(err.response.data.error)
				}
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

const initialState = {
	loading: false
}
const auth = createSlice({
	name: "auth",
	initialState,
	reducers: {
		addUser: (state, { payload }) => {
			state.user = payload
		},
		removeUser: (state) => {
			state.user = {}
		}
	},
	extraReducers: (builder) => {
		// Forgot password request
		builder.addCase(forgotPassReq.pending, (state) => {
			state.loading = true
		})
		builder.addCase(forgotPassReq.fulfilled, (state) => {
			state.loading = false
		})
		builder.addCase(forgotPassReq.rejected, (state) => {
			state.loading = false
		})

		// Login request
		builder.addCase(googleLoginReq.pending, (state) => {
			state.loading = true
		})
		builder.addCase(googleLoginReq.fulfilled, (state, { payload }) => {
			state.loading = false
			state.user = payload
		})
		builder.addCase(googleLoginReq.rejected, (state) => {
			state.loading = false
		})

		// Logout
		builder.addCase("LOGOUT", () => initialState)
	}
})

export const { addUser, removeUser } = auth.actions
export default auth.reducer