import { Box, Divider, Grid, IconButton, Input, Skeleton, Stack, Typography } from "@mui/material"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import DialogContentText from "@mui/material/DialogContentText"
import { Fragment, useEffect, useRef, useState } from "react"
import { hideDialog } from "../../../redux/dialog.slice"
import DialogContent from "@mui/material/DialogContent"
import { useDispatch, useSelector } from "react-redux"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"
import Icons from "../../../assets/icons"
import CustomInput from "../CustomInput"
import {
  getCustomTemplatesReq,
  getTemplateListReq,
  getTemplatesByCateReq
} from "../../../redux/template.slice"
import Toaster from "../../frontend/Toaster"
import { getCategoryPromptReq, getPromptCategoryReq } from "../../../redux/prompt.slice"


function AllPrompts() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const param = searchParams.get("template")
  const descriptionElementRef = useRef(null)
  const [create, setCreate] = useState(false)
  const [activeCat, setActiveCat] = useState("")
  const [promptText, setPromptText] = useState("")
  const [activePrompt, setActivePrompt] = useState("")
  const { show } = useSelector(state => state.dialogSlice)
  const { categories, prompts } = useSelector(state => state.promptSlice)
  console.log(create);


  let timer;
  const handleSearch = (e) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (activeCat !== "" && activeCat !== "custom") {
        dispatch(getTemplatesByCateReq({ id: activeCat, text: e.target.value }))
      } else {
        dispatch(getTemplateListReq({ text: e.target.value }))
      }
    }, 2000)
  }

  const handleClose = () => {
    if (param === "") { navigate(pathname) }
    dispatch(hideDialog())
  }

  const getCustomTemp = () => {
    setActiveCat("custom")
    dispatch(getCustomTemplatesReq({ text: "" }))
  }
  const getPromptByCate = (id) => {
    setActiveCat(id)
    dispatch(getCategoryPromptReq(id))
  }

  const handleActivePrompt = (item) => {
    setActivePrompt(item?._id)
    setPromptText(item?.prompt)
  }

  useEffect(() => {
    dispatch(getPromptCategoryReq())

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // if (show) {
    // const { current: descriptionElement } = descriptionElementRef
    // if (descriptionElement !== null) {
    //   descriptionElement.focus();
    // }
    // }

    // eslint-disable-next-line
  }, [show]);

  return (
    <Fragment>
      <DialogTitle component="div" className="!py-[8px]"  >
        <Box className="flex gap-[8px] items-center">
          <Box sx={{ display: "flex" }}>
            <Typography
              component="h5"
              sx={{
                color: "#160647",
                fontWeight: "600",
                fontFamily: "Lato",
                fontSize: { xs: "16px", laptop: "20px" },
              }}
            >Prompts
            </Typography>
          </Box>
          <Box className="flex-grow">
            <Box className="inline-block">
              <CustomInput
                type="text"
                onChangeFun={handleSearch}
                startIcon={<Icons.MuiSearchIcon
                  className="text-deluge-600"
                />}
              />
            </Box>
          </Box>
          <IconButton
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="!text-3xl text-deluge-600" />
          </IconButton>
        </Box>

      </DialogTitle>
      <DialogContent
        dividers
        id="AllTemplates"
        className="gap-[8px] custom-scroll"
        sx={{
          position: { sm: "relative" },
          display: { xs: "block", sm: "flex" },
        }}
      >
        <Stack
          gap={1.5}
          sx={{
            top: { sm: "0" },
            overflowY: "auto",
            minWidth: { xs: "100%", sm: "200px" },
            marginBottom: { xs: "10px", sm: "0" },
            flexWrap: { xs: "wrap", sm: "nowrap" },
            position: { xs: "unset", sm: "sticky" },
          }}
          className="custom-scroll"
          direction={{ xs: "row", sm: "column" }}
        >
          {categories?.loading ?
            new Array(5).fill(0).map((_, i) => (
              <Skeleton key={i} className="!h-[35px] w-full !transform-none" />
            )) :
            <Fragment>
              <Button
                sx={{ mr: 0.5 }}
                onClick={getCustomTemp}
                variant={activeCat === "custom" ? "contained" : "outlined"}
                className={`clr-primary !justify-start ${activeCat === "custom" ? "fill-btn" : "border-btn"}`}
              >Custom
              </Button>
              {categories?.data?.map((cat) => (
                <Button
                  key={cat?._id}
                  sx={{ mr: 0.5 }}
                  onClick={() => { getPromptByCate(cat?._id) }}
                  variant={activeCat === cat?._id ? "contained" : "outlined"}
                  className={`clr-primary !justify-start ${activeCat === cat?._id ? "fill-btn" : "border-btn"}`}
                >{cat?.name}
                </Button>
              ))
              }
            </Fragment>
          }
        </Stack>

        <Divider sx={{ opacity: "1" }} flexItem orientation="vertical" />

        <Stack
          gap={1.5}
          sx={{
            top: { sm: "0" },
            overflowY: "auto",
            minWidth: { xs: "100%", sm: "200px" },
            marginBottom: { xs: "10px", sm: "0" },
            flexWrap: { xs: "wrap", sm: "nowrap" },
            position: { xs: "unset", sm: "sticky" },
          }}
          className="custom-scroll"
          direction={{ xs: "row", sm: "column" }}
        >
          {prompts?.loading ?
            new Array(5).fill(0).map((_, i) => (
              <Skeleton key={i} className="!h-[35px] w-full !transform-none" />
            )) :
            <Fragment>
              {create ?
                <Input
                  type="text"
                  placeholder="Prompt Name"
                  sx={{
                    px: "4px",
                    width: "100%",
                    borderRadius: "4px",
                    border: "1px solid #7959a6",
                    ":after": { display: "none" },
                    ":before": { display: "none" },
                  }}
                /> :
                <Button
                  sx={{
                    px: "4px",
                    textWrap: "nowrap",
                    justifyContent: "start",
                    fontSize: "14px !important",
                    fontWeight: "300 !important",
                  }}
                  size="small"
                  variant="contained"
                  startIcon={<Icons.FiPlus />}
                  className="clr-primary fill-btn"
                  onClick={() => { setCreate(true) }}
                >create custom prompt
                </Button>
              }
              {prompts?.data?.map((cat) => (
                <Button
                  key={cat?._id}
                  sx={{ mr: 0.5 }}
                  onClick={() => { handleActivePrompt(cat) }}
                  variant={activePrompt === cat?._id ? "contained" : "outlined"}
                  className={`clr-primary !justify-start ${activePrompt === cat?._id ? "fill-btn" : "border-btn"}`}
                >{cat?.customname}
                </Button>
              ))
              }
            </Fragment>
          }
        </Stack>

        <Divider sx={{ opacity: "1" }} flexItem orientation="vertical" />
        <DialogContentText ref={descriptionElementRef} component="div" className="flex-grow">
          <Grid container spacing={1}>
            {categories?.loading ?
              <Grid item xs={12}>
                <Skeleton className="!h-[120px] w-full !transform-none" />
              </Grid> :
              <Grid item xs={12}>
                <Input
                  type="text"
                  sx={{
                    p: 1,
                    width: "100%",
                    borderRadius: "6px",
                    border: "1px solid gray",
                    ":after": { display: "none" },
                    ":before": { display: "none" },

                    "textarea": {
                      color: "#160647",
                      fontSize: "14px",
                      fontFamily: "Lato",
                      minHeight: "200px",
                    }
                  }}
                  value={promptText}
                  inputComponent="textarea"
                />
                <Typography
                  variant="body2"
                  sx={{ fontSize: "13px", mt: "2px" }}
                ><Icons.FiInfo /> Your custom prompts will be shared across your workspace</Typography>
              </Grid>
            }

            <Grid item xs={12}>
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  mt: 1,
                  justifyContent: "end",
                }}
              >
                {create &&
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => { setCreate(false) }}
                    className="border-btn clr-primary"
                    sx={{ border: "1px solid !important" }}
                  >Cancel
                  </Button>
                }
                <Button
                  size="small"
                  disabled={true}
                  variant="contained"
                  className="fill-btn clr-primary"
                >Save new prompt
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <Toaster />
    </Fragment >
  );
}

export default AllPrompts