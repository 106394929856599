// 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { headers, BASEURL, checktoken } from '../helper';
import axios from 'axios';

export const GetTemConReq = createAsyncThunk('GetTemConReq', async (id) => {
    try {
        const response = await axios.get(`${BASEURL}/content/${id}`, { headers: headers() })
        console.log(response.data)
        return response.data
    } catch (error) {
        checktoken(error)
    }
})

const GetTemConSlice = createSlice({
    name: 'GetTemConSlice',
    initialState: {
        data: null,
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(GetTemConReq.pending, (state, action) => {
            state.loading = true
            state.data = null
        })
        builder.addCase(GetTemConReq.fulfilled, (state, action) => {
            state.loading = false
            state.data = [action?.payload?.data]
        })
        builder.addCase(GetTemConReq.rejected, (state, action) => {
            state.loading = false
            state.data = null
            state.error = 'Error occured'
        })
    }
})

export default GetTemConSlice.reducer