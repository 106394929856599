import { FiMenu, FiBookmark, FiClipboard, FiTrash2 } from "react-icons/fi"
import { useOutletContext, Link } from "react-router-dom"
import { headers, checktoken, hideLadingLoader } from "../../helper"
import Loader from "../../components/frontend/TextLoader"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Toaster from "../../components/frontend/Toaster"
import Favicon from "../../assets/images/favicone.png"
import { Fragment, useEffect, useState } from "react"
import { Alert, Col, Spinner } from "react-bootstrap"
import * as DOMPurify from "dompurify"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import axios from "axios"

const Bookmark = () => {
	const [handleSidebar] = useOutletContext();
	const [prompts, setPrompts] = useState([]);
	const [username, setUsername] = useState("");
	const [spinner, setSpinner] = useState(false);
	const [FSpinner, setFSpinner] = useState(false);
	const authUser = JSON.parse(localStorage.getItem("authuser"));

	const handleCopy = (content) => {
		(content) ? toast.success("Copy Saved!") : toast.error("Content not found !");
	};

	const handleFavorite = (id) => {
		setFSpinner(true);
		axios.put(`${process.env.REACT_APP_API_URL}/add/favorite/prompt/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				UserFavoriteContents();
			}
			setFSpinner(false);
		}).catch((err) => { checktoken(err); setFSpinner(false) })
	}

	const UserFavoriteContents = () => {
		setSpinner(true)
		axios.get(`${process.env.REACT_APP_API_URL}/user/favorite/prompts`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setPrompts(res.data.data);
			}
			setSpinner(false)
		}).catch((err) => { checktoken(err); setPrompts([]); setSpinner(false) })
	}

	useEffect(() => {
		hideLadingLoader();
		if (authUser) {
			setUsername(authUser.name);
		}
		UserFavoriteContents();

		// eslint-disable-next-line
	}, [])


	return (
		<Fragment>
			<Helmet>
				<title>AltaCopy</title>
				<meta property="og:title" content={"AltaCopy"} />
			</Helmet>
			{spinner === true ? <Loader /> : ""}
			<Toaster />
			<div className="user-dashboard-header d-flex border-bottom justify-content-between">
				<div className="flex-grow-1">
					<FiBookmark className="me-2" /> Favorite answers
				</div>
				<div className="d-flex d-xl-none mb-3 justify-content-between">
					<button className="d-inline-block text-center text-decoration-none page-header-btn-dark btn menu-btn me-2" onClick={handleSidebar}><FiMenu /></button>
				</div>
				<Link to="/user/chat" className="d-inline-flex text-center text-decoration-none page-header-btn-dark page-header-btn me-2 align-items-center fill-btn clr-primary !border-deluge-500 small-btn">AltaChat</Link>
			</div>
			<div className="page-projects">
				<Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12} className="m-auto">
					{prompts.map((item, index) => {
						return (
							<Fragment key={index}>
								{(item?.result?.promptrole === "user" && item?.result?.prompt !== "") &&
									<div className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}>
										<div className="d-flex ms-auto flex-grow-1 align-items-start">
											<div className="d-block">
												<span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2" style={{ fontSize: "13px" }}>{username.split(" ")[0]?.split("")[0]}{username.split(" ")[1]?.split("")[0]}</span>
											</div>
											<div className="leading-loose w-100" style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
												<div className="question" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.result?.prompt.replaceAll("\\n", "").replaceAll("<br>", "")) }} /></div>
										</div>
									</div>
								}
								{
									item?.result?.promptresponserole === "assistant" &&
									<div className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}>
										<div className="d-block">
											<span className="prompt-small-icon"><img src={Favicon} alt="img" style={{ width: "15px", filter: "brightness(0) invert(1)" }} /></span>
										</div>
										<div className="d-block flex-grow-1 answer-div">
											<div className="leading-loose" style={{ whiteSpace: "break-spaces", wordBreak: "break-word", fontWeight: 500 }}>
												{(item?.result?.promptresponse.indexOf("textend", "") === -1) ?
													<div className="answer-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.result?.promptresponse) }} /> :
													<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.result?.promptresponse.slice(0, item?.result?.promptresponse.indexOf("textend", "")).replaceAll("...", "")) }} />
												}

												{item?.result?.promptresponse.indexOf("textend", "") !== -1 && item?.result?.promptresponse.slice(item?.result?.promptresponse.indexOf("textend", "")).replace("textend", "", "").split(",").map((item, index) => {
													return (
														<div key={index} className="mt-1 d-flex">
															[{index + 1}] : <a href={item} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 400 }}>{item}</a>
														</div>
													)
												})}
											</div>
											{item?.result?.promptresponse && item?.result?._id &&
												<div className="d-flex mt-3 align-items-center justify-content-end">
													<div className="d-flex">
														<CopyToClipboard text={item?.result?.promptresponse}>
															<button className="btn p-0 d-flex align-items-center like-btns me-2" onClick={() => handleCopy(item?.result?.promptresponse)}>
																<FiClipboard className="me-1" /> Copy
															</button>
														</CopyToClipboard>
													</div>
													<button className="btn p-0 d-flex align-items-center like-btns ms-2" onClick={() => handleFavorite(item?._id)}>
														<FiTrash2 className="me-1" /> Remove {FSpinner === true && <Spinner animation="border" size="sm" className="ms-2" />}
													</button>
												</div>
											}
										</div>
									</div>
								}
							</Fragment>
						)
					})}
				</Col>
				{prompts.length === 0 && <Alert variant={`warning`}>No favourite answers saved yet!</Alert>}
			</div>
		</Fragment>
	)
}

export default Bookmark;