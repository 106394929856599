import { headers, checktoken, hideLadingLoader } from '../../../helper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { FiList } from "react-icons/fi";
import axios from 'axios';

const EditCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('')

    const getCategoryDetail = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/prompt/category/edit/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setName(res.data.data.name);
            }
            hideLadingLoader();
        }).catch((err) => {
            checktoken(err);
            hideLadingLoader();
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = { name: name };
        axios.put(`${process.env.REACT_APP_API_URL}/prompt/category/update/${id}`, formData, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message);
                navigate('/admin/prompts-categories');
            }
        }).catch((err) => {
            checktoken(err)
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        if (id !== null && id !== undefined) {
            getCategoryDetail(id);
        }
    }, [])

    return (
        <Card className="shadow-lg border-0">
            <ToastContainer newestOnTop={true} theme="dark" pauseOnHover closeOnClick autoClose={10000} />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Edit category</Card.Title>
                <Link to="/admin/prompts-categories" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> category</Link>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className='m-auto'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Label>Name</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Enter Name" name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant='success text-capitalize'>submit</Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default EditCategory;