const intialState = {};
const GetUserTour = (state = intialState, action) => {
    switch (action.type) {
        case "USERTOUR":
            return action.data;
        default:
            return state;
    }
}

export default GetUserTour;