import { Nav, Button, Form, Row, Col, Tab, Modal } from "react-bootstrap"
import TemplateLoader from "../../components/frontend/TemplateLoader"
import React, { useEffect, useMemo, useState } from "react"
import { Button as MuiButton } from "@mui/material"
import { toast } from "react-toastify"
import { headers } from "../../helper"
import { Cookies } from "react-cookie"
import Icons from "../../assets/icons"
import Toaster from "./Toaster"
import axios from "axios"

const ChatSidebar = (props) => {
	const cookies = new Cookies();
	const [sCat, setSCat] = useState("");
	const [open, setOpen] = useState(false);
	const [promptID, setPromptID] = useState("");
	const [spinner, setSpinner] = useState(false);
	const [catSpin, setCatSpin] = useState(false);
	const [newprompt, setNewprompt] = useState("");
	const [catprompt, setCatprompt] = useState([]);
	const [categories, setCategories] = useState([]);
	const [promptlist, setPromptlist] = useState([]);
	const [useprompt, setUseprompt] = useState(false);
	const [editPrompt, setEditPrompt] = useState(false);
	const [updatePrompt, setUpdatePrompt] = useState("");
	const [customprompt, setCustomprompt] = useState("");
	const [createPrompt, setCreatePrompt] = useState(false);
	const [updateCustomPrompt, setUpdateCustomPrompt] = useState("");

	const promptText = (text) => {
		setNewprompt(text);
	}

	const handleClose = () => {
		setOpen(false)
	}

	const categoryList = () => {
		setCatSpin(true)
		axios.get(`${process.env.REACT_APP_API_URL}/list-category`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setCategories(res.data.data);
			} else {
				toast.error(res.data.error);
			}
			setCatSpin(false)
		}).catch((err) => {
			setCatSpin(false)
			if (err?.response?.data?.error !== undefined) {
				toast.error(err.response.data.error);
			}
		})
	}

	const getCategoryPrompts = (id) => {
		setSpinner(true);
		setCatprompt([]);
		axios.get(`${process.env.REACT_APP_API_URL}/category/prompt/${id}`, {
			headers: headers()
		}).then((res) => {
			setSpinner(false);
			if (res.data.success === true) {
				setNewprompt("");
				setCatprompt(res.data.data);
			} else {
				setNewprompt("");
				setCatprompt([]);
				toast.error(res.data.error);
			}
		}).catch((err) => {
			setSpinner(false);
			if (err?.response?.data?.error !== undefined) {
				toast.error(err.response.data.error);
			}
		})
	}

	const getCustomPrompt = () => {
		setSpinner(true)
		axios.get(`${process.env.REACT_APP_API_URL}/custom-prompt-list`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setPromptlist(res.data.data)
			}
			if (res.data.success === false) {
				toast.error(res.data.message);
			}
			setSpinner(false)
		}).catch((err) => { setSpinner(false) })
	}

	const savePrompt = () => {
		const formData = {
			prompt: newprompt,
			customname: customprompt,
		}
		axios.post(`${process.env.REACT_APP_API_URL}/create/prompt/question`, formData, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`
			}
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				setCustomprompt("");
				getCustomPrompt();
				setNewprompt("");
			}
			if (res.data.success === false) {
				toast.error(res.data.message);
			}
		}).catch((err) => {
			if (err?.response?.data?.error !== undefined) {
				toast.error(err.response.data.error);
			}
		})
	}

	const updatePromptFun = (e) => {
		e.preventDefault();
		const formData = { customname: updateCustomPrompt, prompt: updatePrompt }
		axios.put(`${process.env.REACT_APP_API_URL}/prompt/update/${promptID}`, formData, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`
			}
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				setOpen(false);
				setUpdateCustomPrompt("");
				setEditPrompt(false);
				setUpdatePrompt("");
				setPromptID("");
				getCustomPrompt();
			}
			if (res.data.success === false) {
				toast.error(res.data.message);
			}
		}).catch((err) => {
			if (err?.response?.data?.error !== undefined) {
				toast.error(err.response.data.error);
			}
		})
	}
	const deltePromptFun = (id) => {
		axios.delete(`${process.env.REACT_APP_API_URL}/user/prompt/delete/${id}`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`
			}
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				getCustomPrompt();
			}
		}).catch((err) => {
			if (err?.response?.data?.error !== undefined) {
				toast.error(err.response.data.error);
			}
		})
	}

	useMemo(() => {
		if (props.searchPrompt.length > 0) {
			setPromptlist(props.searchPrompt)
			setCatprompt(props.searchPrompt)
			return (promptlist, catprompt);
		} else {
			setPromptlist([])
			if (sCat !== "") {
				return getCategoryPrompts(sCat);
			}
			return getCustomPrompt();
		}
	}, [props.searchPrompt])

	useEffect(() => {
		setUseprompt(false);
		getCustomPrompt();
		categoryList()
		setNewprompt(props.prompt);
	}, [])

	return (
		<Row>
			<Toaster />
			<Col xxl={7} xl={7} lg={7} md={8} sm={6} xs={12}>
				<Tab.Container id="left-tabs-example" defaultActiveKey="custom">
					<Row className="h-full">
						<Col xxl={5} xl={5} lg={5} md={5} sm={6} xs={12} className="position-relative">
							{catSpin == true && <TemplateLoader />}
							<div className="scrollbar mb-0 !h-full custom-scroll shadow-sm shadow-sm-none py-1">
								<div className="force-overflow">
									<Nav className="side-menu flex-sm-column">
										<Nav.Item>
											<Nav.Link eventKey="custom" onClick={() => { setNewprompt(""); setSCat(""); props.setIsCategory(""); setUseprompt(false) }} className="!bg-gray-950 text-white">Custom </Nav.Link>
										</Nav.Item>
										{
											categories && categories.map((item, index) => {
												return (
													<Nav.Item key={index}>
														<Nav.Link eventKey={item.name} onClick={() => { props.setIsCategory(item._id); setSCat(item._id); getCategoryPrompts(item._id) }} className="!bg-deluge-700 text-white">{item.name} </Nav.Link>
													</Nav.Item>
												)
											})
										}
									</Nav>
								</div>
							</div>
						</Col>
						<Col xxl={7} xl={7} lg={7} md={7} sm={6} xs={12} className="position-relative">
							{spinner == true && <TemplateLoader />}
							<div className="scrollbar mb-0 !h-full custom-scroll shadow-md-none shadow-sm p-1">
								<div className="force-overflow">
									<Tab.Content>
										<Tab.Pane eventKey="custom">
											{
												((newprompt !== "" && editPrompt === false) || (createPrompt !== false && editPrompt === false)) ?
													<Form.Control type="text" name="customprompt" value={customprompt} className="border" placeholder="Prompt Name" onChange={(e) => { setCustomprompt(e.target.value) }} /> :
													<MuiButton
														size="small"
														onClick={(e) => {
															setCreatePrompt(true);
															setEditPrompt(false)
														}}
														variant="contained"
														startIcon={<Icons.FiPlus />}
														className="fill-btn w-full clr-primary !font-ff-lato small-btn !capitalize"
													>create custom prompt
													</MuiButton>
											}
											<Nav className="side-menu bg-white flex-sm-column mt-2">
												{
													promptlist && promptlist.map((item, index) => {
														return (
															<Nav.Item key={index} className="p-0">
																<Nav.Link to="/" onClick={(e) => { e.preventDefault(); }} className="d-flex justify-content-between py-1">
																	<div className="py-2 flex-grow-1" onClick={() => { promptText(item.prompt); setUseprompt(true); }}>
																		{item.customname}
																	</div>
																	{item.createdBy !== null &&
																		<div>
																			<Button variant="light pt-0 pb-1 px-2 me-2" onClick={() => {
																				setPromptID(item?._id);
																				setUpdatePrompt(item.prompt);
																				setUpdateCustomPrompt(item.customname);
																				setOpen(true)
																			}}><Icons.FiEdit /></Button>
																			<Button variant="light pt-0 pb-1 px-2" onClick={() => { deltePromptFun(item._id) }}><Icons.FiTrash2 /></Button>
																		</div>
																	}
																</Nav.Link>
															</Nav.Item>
														)
													})
												}
											</Nav>
										</Tab.Pane>
										{
											categories && categories.map((item, index) => {
												return (
													<Tab.Pane key={index} eventKey={item.name}>
														<Nav className="side-menu bg-white flex-sm-column mt-2">
															{
																catprompt && catprompt.map((item, index) => {
																	return (
																		<Nav.Item key={index} className="p-0">
																			<Nav.Link to="/" onClick={(e) => { e.preventDefault(); promptText(item.prompt); setUseprompt(true); }} className="!bg-deluge-700 text-white">{item.customname} </Nav.Link>
																		</Nav.Item>
																	)
																})
															}
														</Nav>
													</Tab.Pane>
												)
											})
										}

									</Tab.Content>
								</div>
							</div>
						</Col>
					</Row>
				</Tab.Container>
			</Col>
			<Col xxl={5} xl={5} lg={5} md={4} sm={6} xs={12}>
				<div className="prompt-box bg-white shadow-none py-1">
					{
						(createPrompt === false && newprompt === "") ? <Form.Control as="textarea" className="border disabled" rows={10} value={newprompt} name="newprompt" readOnly onChange={(e) => { setNewprompt(e.target.value) }} style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }} /> :
							<Form.Control as="textarea" className="border" rows={10} value={newprompt.replaceAll("\\n", "")} name="newprompt" onChange={(e) => { setNewprompt(e.target.value) }} style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }} />
					}
					<Form.Text id="passwordHelpBlock" muted><Icons.FiInfo /> Your custom prompts will be shared across your workspace</Form.Text>
				</div>
				<div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
					{
						(useprompt === false && createPrompt === true) &&
						<MuiButton
							size="small"
							variant="outlined"
							onClick={() => {
								setCreatePrompt(false)
								setNewprompt("")
							}}
							className="border-btn !border-[2px] clr-primary small-btn !font-ff-lato !capitalize me-2"
						>cancel
						</MuiButton>
					}
					{
						(newprompt !== "" && createPrompt === false) &&
						<MuiButton
							size="small"
							variant="outlined"
							onClick={() => {
								setCreatePrompt(false)
								setNewprompt("")
							}}
							className="border-btn !border-[2px] clr-primary small-btn !font-ff-lato !capitalize me-2"
						>cancel
						</MuiButton>
					}
					{
						useprompt === false ?
							<MuiButton
								size="small"
								variant="contained"
								onClick={savePrompt}
								disabled={newprompt === ""}
								className="fill-btn clr-primary small-btn !font-ff-lato !capitalize me-2"
							>save new prompt
							</MuiButton> :
							<MuiButton
								size="small"
								onClick={() => {
									props.setUsePrompt(newprompt)
									props.closemodel()
								}}
								variant="contained"
								className="fill-btn clr-primary !font-ff-lato small-btn !capitalize me-2"
							>use prompt
							</MuiButton>
					}
				</div>
			</Col>
			<Modal show={open} onHide={handleClose} className="update-prompt-modal">
				<Modal.Header closeButton>
					<Modal.Title>Update Prompt</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={updatePromptFun}>
						<Form.Group className="mb-3">
							<Form.Control type="text" name="customprompt" value={updateCustomPrompt} className="border" placeholder="Prompt Name" onChange={(e) => { setUpdateCustomPrompt(e.target.value) }} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Control as={"textarea"} type="text" name="customprompt" value={updatePrompt} className="border" placeholder="Prompt Name" onChange={(e) => { setUpdatePrompt(e.target.value) }} />
						</Form.Group>
						<Form.Group>
							<Button variant="primary d-block " className="template-modal-btn save-btn" type="submit">update prompt</Button>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
		</Row>
	)
}

export default ChatSidebar;



