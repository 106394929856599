const Footer = () => {
    const year = new Date().getFullYear()

    return (
        <footer className="admin-footer py-3">
            <p className="m-0 text-center">&copy; All Rights Reserved {year} by Goalta.ai</p>
        </footer>
    )
}

export default Footer;