import { Button, Form, OverlayTrigger, Badge, Popover } from 'react-bootstrap';
import { useState, Fragment, useEffect } from "react";
import { FiBarChart2 } from "react-icons/fi";
import { headers } from '../../../helper'
import { Link } from 'react-router-dom';
import React, { useRef } from 'react';
import axios from 'axios';

function BrandVoicePop(props) {
  const popoverRef = useRef(null);
  const [brandList, setbrandList] = useState([]);
  const [defaultName, setDefaultName] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };
  // send data other page ---------------------
  // sendBrandName(defaultName);

  // get brand list -------------------------
  const getBrandList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/brand/list`, {
      headers: headers()
    }).then((res) => {
      if (res.data.success === true) {
        const successData = res.data.data;
        setbrandList(successData);
        if (successData.length > 0) {
          successData.map((item, index) => {
            if (item.is_check === 'default') {
              setDefaultName(item.name);
              props.setBrandData(item.content);
            }
          });
        }
      }
    }).catch((err) => { })
  }

  useEffect(() => {
    getBrandList();
  }, []);

  return (
    <Fragment>
      <div ref={popoverRef}>
        <OverlayTrigger
          show={showPopover}
          trigger="click"
          key="top"
          placement='top'
          rootClose={true}
          overlay={
            <Popover id={`popover-positioned-top`}>
              <Popover.Header as="h3">
                {brandList.length === 0 ?
                  <Link to="/user/brand">
                    <FiBarChart2 className="me-1" /> No Brand Voice
                  </Link> :
                  <Fragment>
                    <FiBarChart2 className="me-1" />Brand Voice
                  </Fragment>

                }
              </Popover.Header>
              {brandList.length > 0 ?
                <Popover.Body className='p-0'>
                  {brandList && brandList.map((item, index) => {
                    return (
                      <div className="d-flex align-items-center justify-content-between px-2" key={index}>
                        <Form.Check className='text-capitalize' type='radio' id={`${index + 1}default-radio`} label={item?.name} name='name' checked={defaultName === item.name} value={item?.content} onChange={(e) => { setDefaultName(item.name); setDefaultValue(e.target.value); props.setBrandData(e.target.value) }} />
                        {(item?.is_check === 'default') && <Badge className='ms-1'>Default</Badge>}
                      </div>
                    )
                  })}
                  <hr className='my-1' />
                  <div className="d-block position-relative px-2">
                    <Form.Check type='radio' id={`null`} label={`No Brand Voice`} value={`No Brand Voice`} name="name" checked={defaultName === 'No Brand Voice'} onChange={(e) => { setDefaultName(e.target.value); setDefaultValue(e.target.value); props.setBrandData(e.target.value) }} />
                  </div>
                </Popover.Body>
                :
                <Popover.Body className="p-0">
                  <div className='d-block p-3'>
                    <p>Effortlessly generate content infused with your brand’s authentic voice.</p>
                    <Link to="/user/brand-new" className="page-header-btn-dark page-header-btn btn w-auto d-inline-flex align-items-center justify-content-center text-white">Add Brand Voice</Link>
                  </div>
                  <div className='p-3 border-top'>
                    <Form.Check type='radio' id={`default-radio`} label={`Default radio`} checked={`default`} />
                  </div>
                </Popover.Body>
              }
            </Popover>
          }
        >
          <button className="btn btn-light chat-btn text-capitalize d-flex align-items-center" onClick={handleButtonClick}><FiBarChart2 className="me-1" />  {defaultName !== '' ? defaultName : 'No Brand Voice'}</button>
        </OverlayTrigger>
      </div>
    </Fragment>
  )
}

export default BrandVoicePop;