import { Spinner } from 'react-bootstrap';

const TemplateLoader = ()=>{
    return (
        <div className="position-absolute top-0 end-0 start-0 bottom-0 d-flex align-items-center justify-content-center bg-white" style={{zIndex:'9'}}>
            <Spinner animation="border" />
        </div>
    )
}

export default TemplateLoader;