import { headers, checktoken, hideLadingLoader } from '../../../helper';
import { Table, Card, Form, Badge, Pagination } from 'react-bootstrap';
import Loader from '../../../components/frontend/TextLoader';
import Toaster from '../../../components/frontend/Toaster';
import { FiUserPlus, FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';

const UserList = () => {
    const cookies = new Cookies();
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [pagination, setPagination] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const handleDelete = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/user-delete/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message);
                userList(1);
            } else {
                toast.error(res.data.error);
            }
        }).catch((err) => {
            checktoken(err)
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    const userList = (page) => {
        setSpinner(true)
        axios.get(`${process.env.REACT_APP_API_URL}/user-list?page=${page}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('auth')}`
            }
        }).then((res) => {
            if (res.data.success === true) {
                setUsers(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            }
            setSpinner(false);
            hideLadingLoader();
        }).catch((err) => { setSpinner(false); checktoken(err); hideLadingLoader(); })
    }

    const handleSearch = (searchQuery, page) => {
        if (searchQuery !== '') {
            axios.get(`${process.env.REACT_APP_API_URL}/admin/searching?name=${searchQuery}&page=${page}`, {
                headers: headers()
            }).then((res) => {
                if (res.data.success === true) {
                    setUsers(res.data.data);
                    setPage(res.data.current);
                    setTotalRecords(res.data.total);
                    let updatePag = [];
                    for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                    setPagination(updatePag);
                } else {
                    toast.error(res.data.error)
                }
            }).catch((err) => { checktoken(err) })
        } else {
            userList(1);
        }

    }

    useEffect(() => {
        userList(1);
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">user list <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                <div className="d-flex">
                    <Form.Control type="search" placeholder="Search" className="me-2 search-form" aria-label="Search" name="searchQuery" value={searchQuery} onKeyUp={(e) => { handleSearch(searchQuery, 1) }} onChange={(e) => { setSearchQuery(e.target.value) }} />
                    <Link to="/admin/add-user" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiUserPlus /> add users</Link>
                </div>
            </Card.Header>
            <Card.Body>
                {
                    users.length > 0 &&
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Wallet Amount</th>
                                    <th>status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users && users.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {page > 1 ?
                                                        (10 * page) + (index - 9) : index + 1
                                                    }
                                                </td>
                                                <td className="text-capitalize">{item.name}</td>
                                                <td>{item.email}</td>
                                                <td className="fw-bold">${item.wallet_amount}</td>
                                                <td className="fw-bold">
                                                    <Badge bg={`${item.status === 'free' ? 'warning' : item.status === 'trialing' ? 'primary' : item.status === 'canceled' ? 'danger' : item.status === 'active' ? 'success' : ''}`}>
                                                        {item.status}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <Link className="me-2 btn btn-danger btn-sm" type="button" onClick={() => { handleDelete(item._id); }}><FiTrash2 /></Link>
                                                    {/* <Link className="btn btn-primary btn-sm" to={`/admin/edit-user/${item._id}`}><FiEdit /></Link> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        {
                            pagination.length > 1 &&
                            <Pagination className='mt-3 justify-content-center flex-wrap'>
                                {
                                    pagination.map((item, index) => {
                                        return (
                                            <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); userList(item) }}>{item}</Pagination.Item>)
                                    })
                                }
                            </Pagination>
                        }
                    </div>
                }
            </Card.Body>
        </Card>
    )
}

export default UserList;