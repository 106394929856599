import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURL, checktoken } from "../helper"
import { hideDialog } from "./dialog.slice"
import { Cookies } from "react-cookie"
import { toast } from "react-toastify"
import store from "../store"
import axios from "axios"

const cookies = new Cookies()

// Get all workspace request
export const getAllWorksapace = createAsyncThunk("getAllWorksapace", () => {
	try {
		const response = axios.get(`${BASEURL}/users/workspace/get-access`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			}
		}).then((res) => {
			if (res?.data?.success) {
				if (res?.data?.token) {
					localStorage.setItem("newProjId", res?.data?.projectid)
					cookies.set("workspaceauth", res?.data?.token, { path: "/" })
					const activeSpace = res.data.data.find((item) => item.current_workspace === 1)
					if (activeSpace) {
						// navigate("/user/chat")
					}
				}
				return res.data
			}
		}).catch((err) => {
			checktoken(err)
			return err
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Share workspace email request
export const workspaceEmailReq = createAsyncThunk("workspaceEmailReq", (data, { rejectWithValue, dispatch }) => {
	try {
		const response = axios.post(`${BASEURL}/user/subscription/mail`, data, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			},
		}).then((res) => {
			if (res?.data?.success) {
				toast.success(res.data.message)
			}
			dispatch(hideDialog())
		}).catch((err) => {
			console.log(err)
			if (!err?.response?.data?.success) {
				toast.error(err.response.data.error)
			}
			dispatch(hideDialog())
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Login to workspace request
export const loginWorkspaceReq = createAsyncThunk("loginWorkspaceReq", ({ data, navigate }, { rejectWithValue, dispatch }) => {
	const state = store.getState()
	try {
		const response = axios.post(`${BASEURL}/workspace/login`, data, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			}
		}).then((res) => {
			if (res?.data?.success) {
				localStorage.removeItem("newProjId")
				if (res?.data?.status === "removed") {
					cookies.remove("workspaceauth", { path: "/" })
				}
				if (state?.authSlice?.user?.currentplan) {
					window.location.reload(true)
				} else {
					navigate("/dashboard")
				}
				dispatch(getAllWorksapace(navigate))
			}
			return res.data
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Accept shared workspace request
export const acceptWorkspaceReq = createAsyncThunk("workspaceEmailReq", ({ shareToken, navigate }, { rejectWithValue }) => {
	try {
		const response = axios.post(`${process.env.REACT_APP_API_URL}/users/workspace/accept-users/`,
			{ data: shareToken }).then((res) => {
				if (res?.data?.success) {
					toast.error(res?.data?.message)
					navigate("/dashboard")
				} else {
					toast.error(res.data.error)
					navigate("/login")
				}
			}).catch((err) => {
				if (err?.response) {
					if (err?.response?.data?.error) {
						toast.error(err?.response?.data?.error)
					}
				}
				return rejectWithValue(err)
			})

		return response
	} catch (error) {
		console.log(error)
	}
})

const initialState = {
	loading: false,
	activeWorkspace: {},
	allSharedWorkSapce: [],
}

const workspace = createSlice({
	name: "workspace",
	initialState,
	reducers: {
		setAllWorkspace: ((state, { payload }) => {
			state.allSharedWorkSapce = payload || []
		}),
		setActiveWorkspace: ((state, { payload }) => {
			state.activeWorkspace = payload || {}
		})
	},
	extraReducers: (builder) => {
		// Get all shered workspace
		builder.addCase(getAllWorksapace.pending, (state) => {
			state.loading = true
		})
		builder.addCase(getAllWorksapace.fulfilled, (state, { payload }) => {
			const activeSapce = payload?.data?.find((space) => space?.current_workspace === 1)
			state.loading = false
			state.allSharedWorkSapce = payload?.data
			state.activeWorkspace = activeSapce || {}
		})
		builder.addCase(getAllWorksapace.rejected, (state) => {
			state.loading = false
		})
		// Login workspace
		builder.addCase(loginWorkspaceReq.pending, (state) => {
			state.loading = true
		})
		builder.addCase(loginWorkspaceReq.fulfilled, (state) => {
			state.activeWorkspace = {}
		})
		builder.addCase(loginWorkspaceReq.rejected, (state) => {
			state.loading = false
		})

		// Share worksapce email
		builder.addCase(workspaceEmailReq.pending, (state) => {
			state.loading = true
		})
		builder.addCase(workspaceEmailReq.fulfilled, (state) => {
			state.loading = false
		})
		builder.addCase(workspaceEmailReq.rejected, (state) => {
			state.loading = false
		})

		// Logout
		builder.addCase("LOGOUT", () => initialState)
	}
})

export const { setAllWorkspace, setActiveWorkspace } = workspace.actions
export default workspace.reducer