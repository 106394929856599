import { Box, DialogContent, IconButton, Paper } from "@mui/material"
import { hideDialog } from "../../../redux/dialog.slice"
import Icons from "../../../assets/icons"
import { useDispatch } from "react-redux"

function VideoDialog() {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="VideoDialog">
      <DialogContent sx={{ pt: "30px" }} className="relative">
        <Box className=" inline-block absolute top-0 right-0">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="text-red-400" />
          </IconButton>
        </Box>
        <Box sx={{ height: { xs: "300px", md: "500px" } }}>
          <video
            loop
            autoPlay
            controls
            preload="auto"
            className="h-full w-full object-contain"
            src="https://storage.googleapis.com/goalta_bucket/AFFILIATE%20FINAL.mov" />
        </Box>
      </DialogContent>
    </Paper>
  )
}

export default VideoDialog