import EditTemplateCategory from "../pages/admin/templates/EditTemplateCategory";
import BlogCategoryList from "../pages/admin/BlogCategory/BlogCategoryList";
import AddBlogCategory from "../pages/admin/BlogCategory/AddBlogCategory";
import AddTempCate from "../pages/admin/templates/AddTemplateCategory";
import EditTemplates from "../pages/admin/templates/EditTemplates";
import EditCategory from "../pages/admin/promptscats/EditCategory";
import TempCate from "../pages/admin/templates/TemplatesCategory";
import AddCategory from "../pages/admin/promptscats/AddCategory";
import ChangePassword from "../components/admin/ChangePassword";
import AddTemplate from "../pages/admin/templates/AddTemplate";
import PaymentList from "../pages/admin/Payments/PaymentList";
import EditPrompt from "../pages/admin/prompts/EditPrompt";
import Category from "../pages/admin/promptscats/Category";
import Templates from "../pages/admin/templates/Templates";
import AddPrompt from "../pages/admin/prompts/AddPrompt";
import AllPrompt from "../pages/admin/prompts/AllPrompt";
import EditBlogs from "../pages/admin/Blog/EditBlogs";
import Prompts from "../pages/admin/prompts/Prompts";
import UserList from "../pages/admin/Users/UserList";
import UserEdit from "../pages/admin/Users/UserEdit";
import EditTone from "../pages/admin/tones/EditTone";
import AddUser from "../pages/admin/Users/AddUser";
import AddTone from "../pages/admin/tones/AddTone";
import AddBlog from "../pages/admin/Blog/AddBlog";
import Dashboard from "../pages/admin/Dashboard";
import Tones from "../pages/admin/tones/Tones";
import Blogs from "../pages/admin/Blog/Blogs";
import Setting from "../pages/admin/Setting";
import Bard from "../pages/admin/Bard";

const AdminRoute = [
    { path: '/admin/bard', name: 'Bard', component: <Bard /> },
    { path: '/admin/tones', name: 'Tones', component: <Tones /> },
    { path: '/admin', name: 'Dashboard', component: <Dashboard /> },
    { path: '/admin/users', name: 'UserList', component: <UserList /> },
    { path: '/admin/add/tone', name: 'AddTone', component: <AddTone /> },
    { path: '/admin/add-user', name: 'AddUser', component: <AddUser /> },
    { path: '/admin/templates', name: 'Templates', component: <Templates /> },
    { path: '/admin/add-prompt', name: 'AddPrompt', component: <AddPrompt /> },
    { path: '/admin/edit-tone/:id', name: 'EditTone', component: <EditTone /> },
    { path: '/admin/list/prompt/:id', name: 'Prompts', component: <Prompts /> },
    { path: '/admin/edit-user/:id', name: 'UserEdit', component: <UserEdit /> },
    { path: '/admin/prompt/list/', name: 'AllPrompt', component: <AllPrompt /> },
    { path: '/admin/add-template', name: 'AddTemplate', component: <AddTemplate /> },
    { path: '/admin/edit/prompt/:id', name: 'EditPrompt', component: <EditPrompt /> },
    { path: '/admin/prompts-categories', name: 'Category', component: <Category /> },
    { path: '/admin/templates-categories', name: 'TempCate', component: <TempCate /> },
    { path: '/admin/template/category/edit/:id', name: 'EditTemplateCategory', component: <EditTemplateCategory /> },
    { path: '/admin/add-prompts-cate', name: 'AddCategory', component: <AddCategory /> },
    { path: '/admin/prompt/category/edit/:id', name: 'EditCategory', component: <EditCategory /> },
    { path: '/admin/template/edit/:id', name: 'EditTemplates', component: <EditTemplates /> },
    { path: '/admin/add-template-categories', name: 'AddTempCate', component: <AddTempCate /> },
    { path: '/admin/add-blog-categories', name: 'AddBlogCategory', component: <AddBlogCategory /> },
    { path: '/admin/list-blog-categories', name: 'BlogCategoryList', component: <BlogCategoryList /> },
    { path: '/admin/add-blog', name: 'AddBlog', component: <AddBlog /> },
    { path: '/admin/list-blog', name: 'Blogs', component: <Blogs /> },
    { path: '/admin/edit-blog/:id', name: 'EditBlogs', component: <EditBlogs /> },
    { path: '/admin/list-transactions', name: 'PaymentList', component: <PaymentList /> },
    { path: '/admin/setting', name: 'Setting', component: <Setting /> },
    { path: '/admin/change-password', name: 'ChangePassword', component: <ChangePassword /> },
];

export default AdminRoute;