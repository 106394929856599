import { useState, Fragment, useEffect, useRef, useCallback, useMemo } from "react"
import { Link, useNavigate, useParams, useOutletContext } from "react-router-dom"
import { headers, checktoken, hideLadingLoader, BASEURL } from "../../../helper"
import Loader from "../../../components/frontend/TextLoader"
import Toaster from "../../../components/frontend/Toaster"
import { getTotalWordsReq } from "../../../redux/chat.slice"
import TemplateOutput from "../templates/TemplateOutput"
import { useDispatch, useSelector } from "react-redux"
import { Button, IconButton } from "@mui/material"
import Spinner from "react-bootstrap/Spinner"
import { Col, Form } from "react-bootstrap"
import Select from "react-dropdown-select"
import Icons from "../../../assets/icons"
import Description from "./Description"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import axios from "axios"

const SingleTemplate = () => {
	const des = useRef("")
	const count = useRef(0)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [tone, setTone] = useState("")
	const [chat, setChat] = useState([])
	const [title, setTitle] = useState("")
	const [save, setSave] = useState(false)
	const [fields, setFields] = useState([])
	const [create, setCreate] = useState(true)
	const [handleSidebar] = useOutletContext()
	const [spinner, setSpinner] = useState(true)
	const [toneList, setToneList] = useState([])
	const { projectid, templateid } = useParams()
	const [contentId, setContentId] = useState("")
	const [projectname, setProjectname] = useState("")
	const [templateTitle, setTemplateTitle] = useState("")
	const [RoundSpinner, setRoundSpinner] = useState(false)
	const { user } = useSelector(state => state.authSlice)
	const { totalWords: chatWords } = useSelector(state => state.chatSlice)

	const handleCopy = (content) => {
		(content) ? toast.success("Copy Saved!") : toast.error("Content not found !");
	};

	const UpdateProject = (data) => {
		const id = localStorage.getItem("newProjId");
		axios.put(`${process.env.REACT_APP_API_URL}/project/update/${id}`, { name: data }, {
			headers: headers()
		}).then((res) => {
			setSpinner(false);
			if (res.data.success === true) {
				setProjectname(res.data.data.name);
				localStorage.removeItem("newProjId");
				localStorage.setItem("newProjId", res.data.data._id);
				toast.success(res.data.message);
			}
		}).catch((err) => { setSpinner(false); checktoken(err) })
	}

	const getSingleTemplate = (id) => {
		setSpinner(true)
		setContentId("");
		axios.get(`${BASEURL}/template/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setTemplateTitle(res.data.data.name);
				const fields = JSON.parse(res.data.data.fields);
				setFields(fields);
				const title = fields.find((x) => x.Field === "text")
				setTitle(title?.Placeholder);
				const desc = fields.find((x) => x.Name === "description")
				des.current = desc.Placeholder;
				count.current = desc.Placeholder.replaceAll("\\n", "").replaceAll("\n", "").length;
				ToneList();
				setTone("");
			}
			setSpinner(false);
		}).catch((err) => { setSpinner(false); checktoken(err) })
	}

	const ToneList = () => {
		setToneList([])
		axios.get(`${BASEURL}/tone/list/`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				const values = [];
				res.data.data.forEach((item) => {
					values.push({ label: `${item.imoji} ${item.name}`, value: item.name });
				})
				setToneList(values)
			}
		}).catch((err) => { checktoken(err) })
	}

	const handleChange = useCallback((value) => {
		des.current = value;

		// eslint-disable-next-line
	}, [des.current])

	const handleSubmit = async (e) => {
		e.preventDefault();
		setRoundSpinner(true);
		if (chatWords >= 30000 && (user?.payments?.plan === "Solo" && user?.payments?.type === "month") && user?.payments?.status === "active") {
			navigate("/pricing");
			return false;
		}
		if (chatWords >= 30000 && (user?.payments?.plan === "Solo" && user?.payments?.type === "year") && user?.payments?.status === "active") {
			navigate("/pricing");
			return false;
		}
		const formData = { title: title, description: des.current.replaceAll("<br>", "\n").replaceAll("<span>", "").replaceAll("**", ""), tone: tone }
		try {
			const response = await fetch(`${BASEURL}/create/content`, {
				method: "POST",
				responseType: "stream",
				headers: headers(),
				body: JSON.stringify(formData),
			});
			//eslint-disable-next-line
			const readData = response.body.pipeThrough(new TextDecoderStream()).getReader();
			setRoundSpinner(false);
			let aiRes = "";
			while (true) {
				scrollChat();
				const { done, value } = await readData.read();
				if (done) { break; }
				aiRes += value;
				setChat([{ content: aiRes }]);
				scrollChat();
			}
			dispatch(getTotalWordsReq(user))
			if (aiRes.length > 10) {
				setTimeout(function () {
					const formData = { content: aiRes, projectId: projectid, templateId: templateid, }
					SaveTemplate(formData);
				}, 200);
			}
		} catch (err) {
			checktoken(err);
			setRoundSpinner(false);
			if (err.response.data.error !== undefined) {
				toast.error(err.response.data.error);
			}
		}
	}

	const scrollChat = () => {
		setTimeout(function () {
			document.body.scrollIntoView({ behavior: "smooth" });
		});
	}

	const getProjectName = (id) => {
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/project/single/${id}`, {
			headers: headers()
		}).then((res) => {
			setSpinner(false);
			if (res.data.success === true) {
				setProjectname(res.data.data.name);
			}
		}).catch((err) => { setSpinner(false); checktoken(err); })
	}

	const SaveTemplate = (data) => {
		axios.post(`${BASEURL}/save/generated/content`, data, { headers: headers() }).then((res) => {
			if (res.data.success === true) {
				setChat([res.data.data]);
			}
		}).catch((err) => { setSpinner(false); checktoken(err); })
	}

	const handleRemove = (id) => {
		axios.delete(`${process.env.REACT_APP_API_URL}/delete/content/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
			}
		}).catch((err) => { checktoken(err); })
	}

	useMemo(() => {
		return chat;
	}, [chat])

	useEffect(() => {
		hideLadingLoader();
		dispatch(getTotalWordsReq(user))
		getSingleTemplate(templateid);
		getProjectName(projectid)
		setChat([])

		// eslint-disable-next-line
	}, [navigate])

	return (
		<Fragment>
			<Helmet>
				<title>AltaCopy</title>
				<meta property="og:title" content={"AltaCopy"} />
			</Helmet>
			{spinner === true ? <Loader /> : ""}
			<Toaster />
			<div className="user-dashboard-header flex border-bottom justify-between items-center">
				<div className="d-flex d-xl-none mb-3 justify-content-between">
					<IconButton
						size="small"
						onClick={handleSidebar}
						className="sidebar-menu-btn"
						sx={{ display: { laptop: "none" } }}
					><Icons.FiMenu />
					</IconButton>
				</div>
				<Form.Group controlId="exampleForm.ControlInput1 project-name" className="flex-xl-grow-1">
					<Form.Control type="text" value={projectname} onChange={(e) => { setProjectname(e.target.value) }} onBlur={() => UpdateProject(projectname)} style={{ maxWidth: "500px" }} />
				</Form.Group>
			</div>

			<div className="page-projects">
				<div className="page-project-header d-flex align-items-center justify-content-between mb-4">
					<h5 className="m-0 page-topbar-header d-flex">
						{(create === true) &&
							<Fragment>
								<Icons.FiLayers className="me-2" /> {templateTitle}
							</Fragment>
						}
						{save} {create}
					</h5>
					<div className="d-flex">
						{
							save === true &&
							<Button
								to="/"
								onClick={(e) => {
									e.preventDefault()
									setSave(false)
									setCreate(true)
								}}
								variant="contained"
								LinkComponent={Link}
								className="fill-btn clr-primary !leading-[1.5] small-btn !capitalize me-2"
							>create
							</Button>
						}
						<Button
							variant="contained"
							LinkComponent={Link}
							to="/user/saved/template"
							className="fill-btn clr-primary !leading-[1.5] small-btn !capitalize me-2"
						>saved
						</Button>
					</div>
				</div>
				<div className="d-block">
					{
						create === true &&
						<Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12} className="m-auto">
							<Form className="template-form" onSubmit={(e) => { setChat([]); handleSubmit(e) }}>
								{
									fields && fields.map((item, index) => {
										return (
											<Form.Group className="mb-3 d-flex" key={index}>
												<span className="count">{index + 1}</span>
												<div className="template-form-fields">
													<Form.Label>
														{item.Label}
													</Form.Label>
													{(item.Field === "text") &&
														<Form.Control placeholder={item.Placeholder} value={title} name={item.Name} onChange={(e) => { setTitle(e.target.value) }} />
													}
													{(item.Field === "textarea") &&
														<Fragment>
															<Description text={des.current} handleChange={handleChange} />
															<div className="text-counter">
																<span id="counter">{count.current} </span>/ 1500
															</div>
														</Fragment>
													}
												</div>
											</Form.Group>
										)
									})
								}
								{
									fields.length > 0 &&
									<Fragment>
										<Form.Group className="mb-3 d-flex">
											<span className="count">{3}</span>
											<div className="d-block w-100">
												<Form.Label>Choose a tone: <span className="ms-2 placeholder-text">Enter a custom tone or choose a preset tone</span>
												</Form.Label>
												{
													toneList.length > 0 ?
														<Select style={{ height: "50px", fontSize: "16px" }} className="text-capitalize" options={toneList} onChange={(values) => { setTone(values[0].value); }}></Select> :
														<Form.Select className="text-capitalize">
															<option value="">Select</option>
														</Form.Select>
												}
											</div>
										</Form.Group>
										<Form.Group className="d-flex">
											<span className="count" style={{ background: "transparent" }}></span>
											<div className="d-grid template-form-fields">
												{tone !== "" ?
													<Fragment>
														<Button
															type="submit"
															variant="contained"
															disabled={RoundSpinner}
															className="fill-btn clr-primary !font-ff-lato !capitalize"
														>create content {RoundSpinner && <Spinner animation="border" size="sm" className="ms-2" />}
														</Button>
													</Fragment> :
													<Button
														disabled
														variant="contained"
														className="fill-btn clr-primary !font-ff-lato !capitalize"
													>create content
													</Button>
												}
											</div>
										</Form.Group>
									</Fragment>
								}
							</Form>
							<TemplateOutput chat={chat} contentId={contentId} handleCopy={handleCopy} handleRemove={handleRemove} />
						</Col>
					}
				</div>
			</div>
		</Fragment>
	)
}

export default SingleTemplate;