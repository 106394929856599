import { Card, Form, Row, Col, Button} from 'react-bootstrap';
import Toaster from '../../components/frontend/Toaster';
import { useState, Fragment, useEffect } from "react";
import { FiUser,FiEyeOff,FiEye} from "react-icons/fi";
import { Link,useNavigate } from 'react-router-dom';
import Loader from '../frontend/TextLoader';
import { toast } from 'react-toastify';
import { headers } from '../../helper';
import axios from 'axios';

const ChangePassword = ()=>{
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    
    const [data, setData] = useState({
        current_password:'',
        password:'',
        confirm_password:'',
    });
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e)=>{
        let value = e.target.value;
        let name = e.target.name;
        setData({
            ...data,
            [name]:value
        })
    }

    useEffect(()=>{
        setSpinner(true);
        setTimeout(()=>{
            setSpinner(false);
        },200)
    },[]);

    const handleSubmit = (e)=>{
        e.preventDefault();
        axios.put(`${process.env.REACT_APP_API_URL}/admin/chnage-password`, data,{
            headers: headers()
        }).then((res)=>{
            if(res.data.success === true){
                toast.success(res.data.message);
                setData({current_password:'', password:'',confirm_password:''});
                localStorage.setItem('authuser', JSON.stringify(''));
                navigate('/admin/login');
            }else{
                toast.error(res.data.error);
            }
        }).catch((err)=>{
            if(err.response.data.success === false){
                toast.error(err.response.data.error);
            }
        })
    }


    return (
        <Fragment>
            <Card className="shadow-lg border-0">
                { spinner === true &&  <Loader/> }
                <Toaster/>
                <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                    <Card.Title className="m-0 text-capitalize">Change Password</Card.Title>
                    <Link to="/admin/setting" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiUser/>Profile</Link>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="m-auto">
                                <Row className="my-5">
                                    <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                        <Form.Label>Current Password</Form.Label>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="password" placeholder="current password" name="current_password" value={data.current_password} onChange={handleChange}/>
                                        </Form.Group>
                                    </Col>

                                    <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="password" placeholder="new password" name="password" value={data.password} onChange={handleChange}/>
                                        </Form.Group>
                                    </Col>

                                    <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Group className="mb-3">
                                        <Form.Control type={showPassword ? 'text' : 'password'} name="confirm_password" placeholder="confirm account number" value={data.confirm_password} onChange={handleChange}/>
                                        <span className="password-eyes" onClick={togglePasswordVisibility}>
                                            {showPassword ? <FiEyeOff/> : <FiEye/>}
                                        </span>
                                        </Form.Group>
                                    </Col>
                                    <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                        <Form.Group>
                                            <Button type="submit" className="text-capitalize">Change Password</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>    
        </Fragment>
    )
}

export default ChangePassword;