import React, { lazy } from 'react';
const Faq = lazy(() => import('../pages/frontend/Faq'));
const Home = lazy(() => import('../pages/frontend/Home'));
const Login = lazy(() => import('../pages/frontend/Login'));
const Signup = lazy(() => import('../pages/frontend/Signup'));
const Blog = lazy(() => import('../pages/frontend/blog/Blog'));
const Appsumo = lazy(() => import('../pages/appsumo/Appsumo'));
const Mission = lazy(() => import('../pages/frontend/Mission'));
const Privacy = lazy(() => import('../pages/frontend/Privacy'));
const Features = lazy(() => import('../pages/frontend/Features'));
const AdminLogin = lazy(() => import('../pages/admin/AdminLogin'));
const TermsService = lazy(() => import('../pages/frontend/TermsService'));
const SingleBlog = lazy(() => import('../pages/frontend/blog/SingleBlog'));
const ResetPassword = lazy(() => import('../pages/frontend/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/frontend/ForgotPassword'));
const Affiliate = lazy(() => import('../pages/frontend/affiliate/Affiliate'));
const PricingPage = lazy(() => import('../pages/frontend/payment/PricingPage'));
const PaymentForm = lazy(() => import('../pages/frontend/payment/PaymentForm'));
const SuccessPage = lazy(() => import('../pages/frontend/payment/SuccessPage'));

const FrontendRoute = [
    { path: '/', name: 'Home', component: <Home /> },
    { path: '/faq', name: 'Faq', component: <Faq /> },
    { path: '/blog', name: 'Blog', component: <Blog /> },
    { path: '/login', name: 'Login', component: <Login /> },
    { path: '/signup', name: 'Signup', component: <Signup /> },
    { path: '/appsumo', name: 'Appsumo', component: <Appsumo /> },
    { path: '/privacy', name: 'Privacy', component: <Privacy /> },
    { path: '/mission', name: 'Mission', component: <Mission /> },
    { path: '/features', name: 'Features', component: <Features /> },
    { path: '/pricing', name: 'Pricing', component: <PricingPage /> },
    { path: '/blog/category/:slug', name: 'Blog', component: <Blog /> },
    { path: '/affiliate', name: 'Affiliate', component: <Affiliate /> },
    { path: '/payment', name: 'PaymentForm', component: <PaymentForm /> },
    { path: '/admin/login', name: 'AdminLogin', component: <AdminLogin /> },
    { path: '/single-blog/:slug', name: 'SingleBlog', component: <SingleBlog /> },
    { path: '/payment-success', name: 'SuccessPage', component: <SuccessPage /> },
    { path: '/terms-of-service', name: 'TermsService', component: <TermsService /> },
    { path: '/reset-password', name: 'ResetPassword', component: <ResetPassword /> },
    { path: '/forgot-password', name: 'ForgotPassword', component: <ForgotPassword /> },
];

export default FrontendRoute;

