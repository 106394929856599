import { acceptWorkspaceReq, setActiveWorkspace, setAllWorkspace } from "../../redux/workspace.slice"
import { BASEURL, checktoken, hideLadingLoader } from "../../helper"
import { useNavigate, useSearchParams } from "react-router-dom"
import TextLoader from "../../components/frontend/TextLoader"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useEffect, useState } from "react"
import Images from "../../assets/images"
import { Cookies } from "react-cookie"
import Icons from "../../assets/icons"
import axios from "axios"
import {
	Avatar, Box, Button, Dialog,
	DialogActions, DialogContent,
	Divider, Menu, MenuItem, Paper,
	Typography
} from "@mui/material"

function Dashboard() {
	const cookies = new Cookies()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const [spinner, setSpinner] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const shareToken = searchParams.get("token_name")
	const { user } = useSelector(state => state.authSlice)
	const {
		allSharedWorkSapce,
		activeWorkspace } = useSelector(state => state.workspaceSlice)

	const handleSwitchUser = (data) => {
		setSpinner(true)
		if (Object.keys(activeWorkspace).length > 0) {
			data = { ...data, status: "remove" }
		}
		axios.post(`${BASEURL}/workspace/login`, data, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			}
		}).then((res) => {
			if (res?.data?.success) {
				localStorage.removeItem("newProjId")
				if (res?.data?.status === "removed") {
					cookies.remove("workspaceauth", { path: "/" })
				} else {
					cookies.set("workspaceauth", res?.data?.token, { path: "/" })
				}
				window.location.reload(true)
			}
			dispatch(setActiveWorkspace({}))
		}).catch((err) => {
			checktoken(err)
			setSpinner(false)
		})
	}

	const handleGetWorkspace = () => {
		setSpinner(true)
		axios.get(`${BASEURL}/users/workspace/get-access`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			}
		}).then((res) => {
			setSpinner(false)
			if (res?.data?.success) {
				if (res?.data?.token) {
					localStorage.setItem("newProjId", res?.data?.projectid)
					cookies.set("workspaceauth", res?.data?.token, { path: "/" })
				}
				const spaceUsers = res?.data?.data?.find((item) => item?.current_workspace === 1)
				if (spaceUsers) {
					dispatch(setActiveWorkspace(spaceUsers))
					return navigate("/user/chat")
				}
				dispatch(setAllWorkspace(res?.data?.data))
			}
		}).catch((err) => {
			checktoken(err)
			setSpinner(false)
		})
	}

	const handleNavigate = () => {
		navigate("/pricing")
	}

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {
		const isLogin = cookies.get("auth")
		if (!isLogin || isLogin === "undefined") {
			if (shareToken) {
				return navigate(`/login?token_name=${shareToken}`)
			} else {
				return navigate("/login")
			}
		}

		if (shareToken) {
			dispatch(acceptWorkspaceReq({ shareToken, navigate }))
		}

		if (user?.status === "trialing" ||
			user?.status === "active" ||
			Object.keys(activeWorkspace).length > 0 ||
			user?.appsumocode) {
			navigate("/user/chat")
		}
		handleGetWorkspace()
		setTimeout(() => {
			hideLadingLoader()
		}, 500)

		// eslint-disable-next-line
	}, [])

	return (
		<Box className="grid place-content-center">
			{spinner ?
				<TextLoader /> :
				<Dialog
					fullWidth
					open={true}
					maxWidth="sm"
					id="#MuiDialog"
					sx={{
						".MuiPaper-root": { borderRadius: "20px" },
						"& .MuiBackdrop-root": { backdropFilter: "blur(1px)", backgroundColor: "#00000050" },
					}}
				>
					<Paper>
						<DialogContent
							sx={{ p: { xs: "20px 24px", md: "40px 24px" } }}
						>
							<Box className="grid gap-12">
								<Box className="text-center">
									<img className="h-[50px] w-[150px] object-contain dialog-img" src={Images.logo} alt="logo" />
								</Box>
								<Typography className="text-center !text-xl font-ff-poppins text-gray-600">Upgrade to our Solo or Pro+ plan to unlock advanced features and boost your productivity!</Typography>
							</Box>
						</DialogContent>
						<Divider className="!border-gray-500" />
						<DialogActions sx={{ justifyContent: "center" }}>
							{allSharedWorkSapce?.length > 0 &&
								<Fragment>
									<Button
										size="small"
										variant="contained"
										onClick={handleClick}
										className="fill-btn clr-dark text-nowrap"
										sx={{
											px: { xs: 2, sm: 4 },
											fontSize: { xs: "14px !important", sm: "16px !important" },
										}}
									>Switch to workspace
									</Button>
									{/* Shared worksapce */}
									<Menu
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "left",
										}}
										transitionDuration={{ appear: 100, enter: 100, exit: 0 }}
									>
										{allSharedWorkSapce?.map((item, index) => (
											<MenuItem key={index} className="p-0">
												<Button
													size="small"
													className="workspace-list-btn worksapce-item px-2"
													startIcon={<Avatar sx={{ width: 30, height: 30, bgcolor: "#1f2937" }} />}
													onClick={() => handleSwitchUser({ id: item._id, email: item.userId.email })}
												>
													<Box>
														{item?.userId?.name.split(" ")[0]}'s workspace
														<span className="text-capitalize d-block text-start leading-[1] text-[12px]">Paid plan</span>
													</Box>
												</Button>
											</MenuItem>
										))
										}
									</Menu>
									{/* Shared worksapce */}
								</Fragment>
							}
							<Button
								size="small"
								variant="contained"
								onClick={handleNavigate}
								endIcon={<Icons.Badge className="!text-sm" />}
								className="fill-btn clr-primary"
								sx={{
									px: { xs: 2, sm: 4 },
									width: { sm: "180px" },
									fontSize: { xs: "14px !important", sm: "16px !important" },
								}}
							>Upgrade
							</Button>
						</DialogActions>
					</Paper>
				</Dialog>
			}
		</Box>
	)
}

export default Dashboard