import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURL, headers } from "../helper"
import store from "../store"
import axios from "axios"

export const getTotalWordsReq = createAsyncThunk("getTotalWordsReq", (user, { rejectWithValue }) => {
  const state = store.getState()
  const activeWorkspace = state?.workspaceSlice?.activeWorkspace
  try {
    if (user?.appsumouser?.type === "Pro" ||
      Object.keys(activeWorkspace)?.length > 0) { return }
    const routePath = user?.appsumouser?.type === "Solo" ?
      "/appsumo/prompt/counter" :
      "/prompt/counter"

    const response = axios.get(BASEURL + routePath, {
      headers: headers()
    }).then((res) => {
      return res?.data?.data
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const handleSaveChatReq = createAsyncThunk("handleSaveChatReq", (data, { rejectWithValue }) => {
  try {
    const response = axios.post(`${BASEURL}/prompt/create`, data, {
      headers: headers()
    }).then((res) => {
      return res?.data?.data
    }).catch((err) => {
      return err
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  loading: false,
  totalWords: 0,
  savedPrompt: []
}

const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Word Counter
    builder.addCase(getTotalWordsReq.fulfilled, (state, { payload }) => {
      state.totalWords = payload
    })

    // Save Prompt
    builder.addCase(handleSaveChatReq.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(handleSaveChatReq.fulfilled, (state, { payload }) => {
      state.loading = false
      state.savedPrompt = payload
    })
    builder.addCase(handleSaveChatReq.rejected, (state) => {
      state.loading = true
    })

    // Logout
    builder.addCase("LOGOUT", () => initialState)

  }
})

export default chat.reducer;