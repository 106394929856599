import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from "../../../components/frontend/Toaster";
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { FiList } from "react-icons/fi";
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import ReactQuill from 'react-quill';
import axios from 'axios';


const AddBlog = () => {
    const cookies = new Cookies();
    const navigate = useNavigate()
    const [file, setFile] = useState('');
    const [name, setName] = useState('');
    const [width, setWidth] = useState('');
    const [image, setImage] = useState('');
    const [height, setHeight] = useState('');
    const [spinner, setSpinner] = useState(true)
    const [subtitle, setSubtitle] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [categories, setCategories] = useState([])
    const [description, setDescription] = useState('')
    const [blogCategory, setBlogCategory] = useState('')
    const [thumbnailfile, setThumbnailFile] = useState('');

    const handleChangeImage = (e) => {
        setImage(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const handleChangeThumbnail = (e) => {
        setThumbnail(e.target.files[0]);
        setThumbnailFile(URL.createObjectURL(e.target.files[0]));
    }

    const categoryList = () => {
        setSpinner(true)
        axios.get(`${process.env.REACT_APP_API_URL}/admin/blog/category/list`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false)
        }).catch((err) => {
            setSpinner(false)
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error[0]);
                }
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('width', width);
        formData.append('image', image);
        formData.append('height', height);
        formData.append('subtitle', subtitle);
        formData.append('thumbnail', thumbnail);
        formData.append('blogCategory', blogCategory);
        formData.append('description', description);
        axios.post(`${process.env.REACT_APP_API_URL}/admin/create/blog`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${cookies.get('auth')}`,
            },
        }).then((res) => {
            if (res.data.success === true) {
                setFile('');
                navigate('/admin/list-blog');
                toast.success(res.data.message);
            } else {
                toast.error(res.data.error);
            }
        }).catch((err) => {
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
        categoryList();
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Add Blog</Card.Title>
                <Link to="/admin/list-blog" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> Blog</Link>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Category name</Form.Label>
                                <Form.Select className="form-select" name="blogCategory" value={blogCategory} onChange={(e) => { setBlogCategory(e.target.value) }}>
                                    <option value="">Select</option>
                                    {
                                        categories && categories.map((item, index) => {
                                            return (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Blog Title</Form.Label>
                                <Form.Control type="text" placeholder="Enter blog title" name="name" value={name} onChange={(e) => { setName(e.target.value); setSubtitle(e.target.value.toLowerCase().replaceAll(' ', '-')) }} />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>

                            <Form.Group className="mb-3">
                                <Form.Label>Blog Subtitle</Form.Label>
                                <Form.Control type="text" placeholder="Enter blog subtitle" name="subtitle" value={subtitle} onChange={(e) => { setSubtitle(e.target.value) }} readOnly />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Blog Image</Form.Label>
                                <Form.Control type="file" accept=".webp,.png,.jpg,.jpeg" name="file" onChange={handleChangeImage} />
                            </Form.Group>
                        </Col>

                        <Col sm={12}>
                            <Form.Text className="fw-bold text-danger">Note: for the resize image , upload new image.</Form.Text>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Add Image Width</Form.Label>
                                        <Form.Control type="number" name="width" value={width} onChange={(e) => { setWidth(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Add Image Height</Form.Label>
                                        <Form.Control type="number" name="height" value={height} onChange={(e) => { setHeight(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} >
                            {(file !== "") &&
                                <div className='mb-2'>
                                    <img className="blog-image border p-1 rounded" src={file} />
                                </div>
                            }
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Blog Thumbnail</Form.Label>
                                <Form.Control type="file" accept=".webp,.png,.jpg,.jpeg" name="thumbnail" onChange={handleChangeThumbnail} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} >
                            {(thumbnailfile !== "") &&
                                <div className='mb-2'>
                                    <img className="blog-image border p-1 rounded" src={thumbnailfile} />
                                </div>
                            }
                        </Col>

                        <Col sm={12}>
                            <Form.Group className="mb-3">
                                <ReactQuill theme="snow" value={description} onChange={setDescription} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                {
                                    (blogCategory === '' || name === '' || description === '') ?
                                        <Button type="button" variant='success disabled text-capitalize'>submit</Button> :
                                        <Button type="submit" variant='success text-capitalize'>submit</Button>

                                }
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default AddBlog;