const intialState = 0;
const getChatWords = (state = intialState, action) => {
    switch(action.type){
        case "TOTALWORDS":
            return action.data;
        default:
            return state;
    }
}

export default getChatWords;