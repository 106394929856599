import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { Card, Form, Button, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FiList } from "react-icons/fi";
import { toast } from 'react-toastify';
import axios from 'axios';

const EditTone = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [imoji, setImoji] = useState('');
    const [toneid, setToneid] = useState('');
    const [spinner, setSpinner] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = { name: name, imoji: imoji };

        axios.put(`${process.env.REACT_APP_API_URL}/update/tone/${toneid}`, formData, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.error(res.data.message);
                navigate('/admin/tones', { state: "Updated" })
            } else {
                toast.error(res.data.error);
            }
        }).catch((err) => {
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    const getTone = (id) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/tone/list/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setName(res.data.data.name);
                setImoji(res.data.data.imoji);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
        if (id !== null && id !== undefined && id !== '') {
            getTone(id);
            setToneid(id);
        } else {
            setSpinner(false);
        }
    }, [])

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">add tone</Card.Title>
                <Link to="/admin/tones" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> tone</Link>
            </Card.Header>
            <Card.Body>
                <Col xxl={6} xl={6} lg={6} md={6} sm={10} xs={12} className="m-auto">
                    <Form onSubmit={handleSubmit} encType="multipart/form-data">
                        <Form.Group className="mb-3">
                            <Form.Label>Tone Name</Form.Label>
                            <Form.Control name="name" value={name} type="text" placeholder='Enter tone name' onChange={(e) => { setName(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="text-capitalize">icon</Form.Label>
                            <Form.Control type="text" name="imoji" value={imoji} onChange={(e) => { setImoji(e.target.value); }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Button type="submit" variant='primary'>Submit</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Card.Body>
        </Card>
    )
}

export default EditTone;