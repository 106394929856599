import { Card, Form, Row, Col, Button} from 'react-bootstrap';
import Toaster from '../../components/frontend/Toaster';
import { useState, Fragment, useEffect } from "react";
import Loader from '../frontend/TextLoader';
import { toast } from 'react-toastify';
import { headers } from '../../helper';
import axios from 'axios';

const AdminProfile = ()=>{
    const [spinner, setSpinner] = useState(true);

    const [data, setData] = useState({
        name:'',
        email:'',
        phone:'',
    });

    const handleChange = (e)=>{
        let value = e.target.value;
        let name = e.target.name;
        setData({
            ...data,
            [name]:value
        })
    }
    const getUserDetails=(e)=>{
        setSpinner(true)
        axios.get(`${process.env.REACT_APP_API_URL}/profile-edit/`,{
            headers: headers()
        }).then((res)=>{
            if(res.data.success===true){
                setData(res.data.data);
            }
            setSpinner(false)          
        }).catch((err)=>{setSpinner(false)})
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        axios.put(`${process.env.REACT_APP_API_URL}/profile-update`, data,{
            headers:headers()
        }).then((res)=>{
            if(res.data.success === true){
                toast.success(res.data.message);
                setData({name:'', email:'',phone:''});
                getUserDetails();
            }else{
                toast.error(res.data.error);
            }
        }).catch((err)=>{
            if(err.response.data.success===false){
                let errors = err.response.data.error;
                for(let x in errors){
                    toast.error(errors[x]);
                }
            }
        })
    }

    useEffect(()=>{
        getUserDetails();
    },[]);

    return (
        <Fragment>
            { spinner === true && <Loader/>}
            <Toaster/>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="m-auto">
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Name" name="name" value={data.name} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter Email" name="email" value={data.email} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>phone</Form.Label>
                                <Form.Control type="tel" placeholder="Enter phone" name="phone" value={data.phone} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Button type="submit" className="text-capitalize">update</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Fragment>
    )
}

export default AdminProfile;