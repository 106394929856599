import { showDialog } from "../redux/dialog.slice"
import store from "../store"
import PizZip from "pizzip"

export function checkWordLimitOnNavigate() {
  const getStore = store.getState()
  if (getStore?.authSlice?.user) {
    console.log("here");

  }
}

// Alta detect file read functions Starts

export function str2xml(str) {
  if (str.charCodeAt(0) === 65279) {
    str = str.substr(1);
  }
  return new DOMParser().parseFromString(str, "text/xml");
}

export function getParagraphs(content) {
  const zip = new PizZip(content);
  const xml = str2xml(zip.files["word/document.xml"].asText());
  const paragraphsXml = xml.getElementsByTagName("w:p");
  const paragraphs = [];

  for (let i = 0, len = paragraphsXml.length; i < len; i++) {
    let fullText = "";
    const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
    for (let j = 0, len2 = textsXml.length; j < len2; j++) {
      const textXml = textsXml[j];
      if (textXml.childNodes) {
        fullText += textXml.childNodes[0].nodeValue;
      }
    }
    if (fullText) {
      paragraphs.push(fullText);
    }
  }
  return paragraphs;
}

// Alta detect file read functions Ends

export const onAppsumoNavigation = (navigate, path, isProPath) => {
  const state = store.getState()
  const appsumoPlan = state?.authSlice?.user?.appsumouser
  const chatWords = state?.chatSlice?.totalWords
  const workspace = state?.workspaceSlice?.activeWorkspace
  if (Object.keys(workspace)?.length > 0) {
    navigate && navigate(path)
    return true
  }
  if (appsumoPlan?.type === "Solo" && chatWords >= 30000) {
    store.dispatch(showDialog("ReachLimit"))
    return false
  } else {
    if (isProPath) {
      navigate && navigate("/pricing")
      return false
    } else {
      if (path) navigate(path)
      return true
    }
  }
}