import ShareWorksapce from "./ShareWorksapce"
import { useSelector } from "react-redux"
import AllTemplates from "./AllTemplates"
import VideoDialog from "./VideoDialog"
import SalesDialog from "./SalesDialog"
import { Dialog } from "@mui/material"
import ReachLimit from "./ReachLimit"
import OtpDialog from "./OtpDialog"
import SelectFile from "./SelectFile"
import ImportUrl from "./ImportUrl"
import AllPrompts from "./AllPrompts"

function DialogContainer() {
  const dialog = useSelector(state => state.dialogSlice)
  const { show, type } = useSelector(state => state.dialogSlice)

  return (
    <Dialog
      fullWidth
      open={show}
      scroll="paper"
      id="#MuiDialog"
      maxWidth={type === "VideoDialog" ? "md" : (type === "AllTemplates") ? "xl" : "sm"}
      sx={{
        ".MuiPaper-root": {
          borderRadius: (type !== "AllTemplates") ? "20px" : "",
          height: (type === "AllTemplates") ? "100%" : "auto"
        },
        "& .MuiBackdrop-root": { backdropFilter: "blur(1px)", backgroundColor: "#00000050" },
      }}
    >
      {type === "OtpDialog" && <OtpDialog />}
      {type === "ImportUrl" && <ImportUrl />}
      {type === "ReachLimit" && <ReachLimit />}
      {type === "SelectFile" && <SelectFile />}
      {type === "AllPrompts" && <AllPrompts />}
      {type === "VideoDialog" && <VideoDialog />}
      {type === "SalesDialog" && <SalesDialog />}
      {type === "AllTemplates" && <AllTemplates />}
      {type === "ShareWorksapce" && <ShareWorksapce shareToken={dialog?.shareToken} />}
    </Dialog>
  )
}

export default DialogContainer