import { headers, checktoken, hideLadingLoader } from '../../../helper';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import Loader from '../../../components/frontend/TextLoader';
import Toaster from '../../../components/frontend/Toaster';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FiList } from "react-icons/fi";
import { toast } from 'react-toastify';
import axios from 'axios';

const AddUser = () => {
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirm_password: ''
    });

    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        setSpinner(true)
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/adduser`, data, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message);
                setData({ name: '', email: '', phone: '', password: '', confirm_password: '' });
                navigate('/admin/users');
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false)
        }).catch((err) => {
            setSpinner(false)
            if (err.response.data.success === false) {
                toast.error(err?.response?.data?.error);
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">add user</Card.Title>
                <Link to="/admin/users" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> users</Link>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Label>Name</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Enter Name" name="name" value={data.name} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Label>Email</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="email" placeholder="Enter Email" name="email" value={data.email} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Label>phone</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="tel" placeholder="Enter phone" name="phone" value={data.phone} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Label>password</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="password" placeholder="Enter Password" name="password" value={data.password} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Label>confirm password</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="password" placeholder="Enter Confirm Password" name="confirm_password" value={data.confirm_password} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group>
                                <Button type="submit" className="text-capitalize">add user</Button>
                            </Form.Group>
                        </Col>
                    </Row>

                </Form>
            </Card.Body>
        </Card>
    )
}

export default AddUser;