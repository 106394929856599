import LogoImg from '../../assets/images/logo.png'
import { FaDiscord, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { Row, Col, Container } from 'react-bootstrap';
import { Fragment } from 'react';

export default function Footer() {
  const year = new Date().getFullYear()

  return (
    <Fragment>
      <footer className="pb-0">
        <Container className="pb-5">
          <Row>
            <Col xxl={6} xl={6} md={12} sm={12} className="mb-3 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0">
              <h2 className="footer-logo"><a href="/"><img src={LogoImg} style={{ width: '200px' }} alt="Logo" /></a></h2>
            </Col>
            <Col xxl={6} xl={6} md={12} sm={12} className="mt-3 mt-lg-0">
              <Row>
                <Col xxl={4} xl={4} lg={4} md={4} sm={6}>
                  <h4 className="text-capitalize mt-3">product</h4>
                  <ul className="nav footer-links flex-column">
                    <li className="nav-item">
                      <a href="/features" className="nav-link">features</a>
                    </li>
                    <li className="nav-item">
                      <a href="/pricing" className="nav-link">pricing</a>
                    </li>
                    <li className="nav-item">
                      <a href="/affiliate" className="nav-link">affiliate</a>
                    </li>
                  </ul>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={6}>
                  <h4 className="text-capitalize mt-3">company</h4>
                  <ul className="nav footer-links flex-column">
                    <li className="nav-item">
                      <a href="/mission" className="nav-link">mission</a>
                    </li>
                    <li className="nav-item">
                      <a href="/terms-of-service" className="nav-link">terms</a>
                    </li>
                    <li className="nav-item">
                      <a href="/privacy" className="nav-link">privacy</a>
                    </li>
                  </ul>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={6}>
                  <h4 className="text-capitalize mt-3">support</h4>
                  <ul className="nav footer-links flex-column">
                    <li className="nav-item">
                      <a href="/blog" className="nav-link">blog</a>
                    </li>
                    <li className="nav-item">
                      <a href="/faq" className="nav-link">FAQ</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="https://discord.gg/nJqmyz4dK7" target="_blank">Community</a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="footer-bottom border-top">
            <Row className="align-items-center">
              <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="footer-text">All rights reserved © {year} - GoAlta, Ltd.</div>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="d-flex align-items-center justify-content-center">
                <div className="footer-text text-center">Made with 💜 in Switzerland🇨🇭</div>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                <ul className="nav footer-social justify-content-center justify-content-md-end mt-lg-0 mt-3">
                  <li className="nav-item me-2">
                    <a className="nav-link" href="https://twitter.com/GoAltaAI" target="_blank">
                      <FaTwitter />
                    </a>
                  </li>
                  <li className="nav-item me-2">
                    <a className="nav-link" href="https://www.linkedin.com/company/goalta/" target="_blank">
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li className="nav-item me-2">
                    <a className="nav-link" href="https://www.instagram.com/goalta.ai/" target="_blank">
                      <FaInstagram />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://discord.gg/nJqmyz4dK7" target="_blank">
                      <FaDiscord />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
      {/* <a type="button" className={`${scrl >= 400 ? 'd-flex' : 'd-none'} align-items-center justify-content-center position-fixed end-0 bottom-0 scroll-btn`} style={{zIndex:'9999'}} onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}><FiArrowUp/></a> */}
    </Fragment>
  )
}
