import { circularProgressClasses } from "@mui/material/CircularProgress"
import { FileContext } from "../../components/frontend/ContextProvider"
import { Fragment, useContext, useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { detectContentReq } from "../../redux/altadetect.slice"
import Toaster from "../../components/frontend/Toaster"
import { useDispatch, useSelector } from "react-redux"
import { showDialog } from "../../redux/dialog.slice"
import { hideLadingLoader } from "../../helper"
import Icons from "../../assets/icons"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import {
  Box, Button, CircularProgress,
  Divider, Grid, IconButton, Paper,
  Stack, styled, Typography
} from "@mui/material"

const Textarea = styled("textarea")(() => ({ width: "100%" }))
function AltaDetect() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [score, setScore] = useState({})
  const { text } = useContext(FileContext)
  const [handleSidebar] = useOutletContext()
  const { user } = useSelector(state => state.authSlice)
  const { show } = useSelector(state => state.dialogSlice)
  const { loading } = useSelector(state => state.altadetectSlice)
  const [textAreaContent, setTextAreaContent] = useState(text || "")
  const { activeWorkspace } = useSelector(state => state.workspaceSlice)

  const checkPlan = () => {
    if (user?.appsumocode === null) {
      if (user?.currentplan?.packagename !== "PRO" && Object.keys(activeWorkspace)?.length === 0) {
        navigate("/pricing");
        return false
      } else if (activeWorkspace?.userId?.status === "free" || activeWorkspace?.userId?.status === "expired" || activeWorkspace?.userId?.status === "canceled") {
        navigate("/pricing");
        return false
      }
    } else {
      navigate("/pricing");
      return false
    }
  }

  const handleTextAreaContent = (e) => {
    setTextAreaContent(e.target.value)
  }

  const handleUploadFile = () => {
    dispatch(showDialog("SelectFile"))
  }

  const handleClick = () => {
    if (textAreaContent.length < 300) {
      return toast.error("Minimum 300 characters required!")
    }
    if (textAreaContent.length > 1500) {
      return toast.error("Word limit exceeded!")
    }

    dispatch(detectContentReq(JSON.stringify(textAreaContent))
    ).unwrap().then((res) => {
      setScore(res)
      setTextAreaContent("")
    }).catch((err) => {
      console.error(err)
    })
  }

  useEffect(() => {
    setTextAreaContent(text || "");
    setTimeout(() => {
      hideLadingLoader()
    }, 500)
    checkPlan()

    // eslint-disable-next-line
  }, [show, activeWorkspace])
  return (
    <Fragment>
      <Helmet>
        <title>AltaDetect</title>
        <meta property="og:title" content="AltaDetect" />
      </Helmet>
      <Box id="AltaDetect">
        <Box className="user-dashboard-header border-bottom">
          <Box className="flex items-center gap-2">
            <IconButton
              size="small"
              onClick={handleSidebar}
              className="sidebar-menu-btn"
              sx={{ display: { laptop: "none" } }}
            ><Icons.FiMenu />
            </IconButton>
            <Typography className="page-header-title">alta detect</Typography>
          </Box>
        </Box>

        <Box className="p-4">
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={handleUploadFile}
                        startIcon={<Icons.MuiCloudUpload />}
                        className="border-btn clr-primary small-btn"
                      >File upload
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box className="mt-3">

                {(Object.keys(score).length > 0 && !loading) ?
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="border px-[14px] rounded">
                        <Box className="py-[14px]">
                          {score?.humanScore?.sentences?.map((p, i) => (
                            <Typography
                              key={i}
                              sx={{
                                color: "white",
                                fontSize: "14px",
                                display: "inline",
                                backgroundColor: (Math.ceil(p?.score) > 84) ? "#16a34a" :
                                  (Math.ceil(p?.score) > 44 && Math.ceil(p?.score) < 85) ? "#fef08a" : "#ef4444"
                              }}
                            >{p?.text}</Typography>
                          ))
                          }
                        </Box>
                        <Box className="border-t py-[14px]">
                          <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                            <Box className="flex flex-nowrap gap-[4px] items-center">
                              <span className="h-[18px] w-[18px] bg-[#ef4444] rounded-[2px] inline-block"></span>
                              <span className="text-xs font-ff-lato font-normal text-gray-950">Likely AI generated</span>
                            </Box>
                            <Box className="flex flex-nowrap gap-[4px] items-center">
                              <span className="h-[18px] w-[18px] bg-[#fef08a] rounded-[2px] inline-block"></span>
                              <span className="text-xs font-ff-lato font-normal text-gray-950">Possibly AI generated</span>
                            </Box>
                            <Box className="flex flex-nowrap gap-[4px] items-center">
                              <span className="h-[18px] w-[18px] bg-[#16a34a] rounded-[2px] inline-block"></span>
                              <span className="text-xs font-ff-lato font-normal text-gray-950">Unlikely AI generated</span>
                            </Box>
                          </Stack>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className="flex justify-end">
                        <Button
                          variant="contained"
                          className="fill-btn clr-primary"
                          onClick={() => { setScore({}) }}
                        >Scan text again
                        </Button>
                      </Box>
                    </Grid>
                  </Grid> :
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Textarea value={textAreaContent} onChange={handleTextAreaContent} className="text-content" rows={15} name="content" />
                    </Grid>
                    <Grid item xs={12}>
                      <Box className="flex gap-2">
                        <Typography className="text-counter flex-grow">{textAreaContent.length > 0 ? textAreaContent.split(" ").length : 0} words / {textAreaContent.length} characters / {textAreaContent.length > 0 ? textAreaContent.split(" ").length : 0} credits</Typography>
                        <Button
                          variant="contained"
                          className="fill-btn clr-primary"
                          disabled={textAreaContent === "" || loading}
                          onClick={(textAreaContent === "" || !loading) ? handleClick : null}
                        >Scan text
                          {loading &&
                            <CircularProgress
                              size={20}
                              thickness={5}
                              sx={{ color: "white", ml: 1, display: "flex" }}
                            />
                          }
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box className="p-3 rounded border report-sec">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box className="p-3 rounded border">
                      <Paper className="shadow-none flex flex-col">
                        <Typography className="title">Human Score</Typography>
                        <Divider className="my-2 !border-[black]" />
                        <Box className="grid place-content-center relative">
                          <CircularProgress
                            sx={{
                              animationDuration: "550ms",
                              [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: "round",
                              },
                            }}
                            value={Math.ceil(score?.humanScore?.score) || 0.5}
                            size={150}
                            thickness={5}
                            variant="determinate"
                            color={
                              Math.ceil(score?.humanScore?.score) > 84 ? "success" :
                                (Math.ceil(score?.humanScore?.score) > 44 && Math.ceil(score?.humanScore?.score) < 85) ?
                                  "warning" : "error"
                            }
                          />
                          <span className="progress-value">{Math.ceil(score?.humanScore?.score) || 0} %</span>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="p-3 rounded border">
                      <Paper className="shadow-none flex flex-col">
                        <Typography className="title">Plagiarism</Typography>
                        <Divider className="my-2 !border-[black]" />
                        <Box className="grid place-content-center relative">
                          <CircularProgress
                            sx={{
                              animationDuration: "550ms",
                              [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: "round",
                              },
                            }}
                            value={Math.ceil(score?.plagiarismScore?.score) || 0.5}
                            size={150}
                            thickness={5}
                            variant="determinate"
                            color={
                              Math.ceil(score?.plagiarismScore?.score) > 84 ? "success" :
                                (Math.ceil(score?.plagiarismScore?.score) > 44 && Math.ceil(score?.plagiarismScore?.score) < 85) ?
                                  "warning" : "error"
                            }
                          />
                          <span className="progress-value">{Math.ceil(score?.plagiarismScore?.score) || 0} %</span>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Toaster />
      </Box>
    </Fragment>
  )
}

export default AltaDetect