import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { useState, Fragment, useEffect, useRef } from "react"
import Loader from "../../components/frontend/TextLoader"
import Toaster from "../../components/frontend/Toaster"
import { useDispatch, useSelector } from "react-redux"
import { showDialog } from "../../redux/dialog.slice"
import { setListView } from "../../redux/other.slice"
import { NavDropdown, Form } from "react-bootstrap"
import { hideLadingLoader } from "../../helper"
import Select from "react-dropdown-select"
import Icons from "../../assets/icons"
import { Helmet } from "react-helmet"
// import Moment from "react-moment"
import moment from "moment"
import {
	deleteMultipleProjectReq, deleteSingleProjectReq,
	filterProjectReq, getAllProjectsReq,
	renameProjectReq, searchProjects
} from "../../redux/project.slice"
import {
	Box, Button, IconButton, Typography,
	// Grid,Paper, Input, Menu,
	//  MenuItem,Collapse
} from "@mui/material"

const Project = () => {
	const inputRef = useRef([]);
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const productNameRef = useRef([])
	const [Index, setIndex] = useState("")
	const [edit, setEdit] = useState(false)
	const [handleSidebar] = useOutletContext()
	// const [anchorEl, setAnchorEl] = useState(null)
	const [allProjects, setAllProjects] = useState([])
	const [projectName, setProjectName] = useState("")
	const [currentProject, setCurrentProject] = useState("")
	const { user } = useSelector(state => state.authSlice)
	const chatWords = useSelector(state => state.getChatWords)
	const workspace = useSelector(state => state.getWorkspace)
	const { listView } = useSelector(state => state.otherSlice)
	const [selectedProjects, setSelectedProjects] = useState([])
	const { loading } = useSelector(state => state.projectSlice)
	// const open = Boolean(anchorEl)
	const filterText = [
		{ label: "last modified", value: -1 },
		{ label: "date created", value: 1 },
		{ label: "alphabetical", value: "A" },
	];

	// const handleClick = (event) => {
	// 	setAnchorEl(event.currentTarget);
	// };
	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	const checkPlan = () => {
		if (user?.appsumocode === null) {
			if (chatWords >= 50000 && user?.currentplan?.packagename === "SOLO" && Object.keys(workspace).length === 0) {
				navigate("/pricing");
				return false
			} else if (workspace?.userId?.status === "free" || workspace?.userId?.status === "expired" || workspace?.userId?.status === "canceled") {
				dispatch(showDialog("ReachLimit"))
				return false
			}
		} else {
			if (user?.appsumouser?.type === "Solo" && chatWords >= 30000 && Object.keys(workspace).length === 0) {
				navigate("/pricing");
				return false
			}
		}
		return true
	}

	const handleProjectId = (e) => {
		if (e?.target?.checked) {
			setSelectedProjects([...selectedProjects, e?.target?.value])
		} else {
			const newArray = selectedProjects.filter(id => id !== e?.target?.value)
			setSelectedProjects(newArray)
		}
	}

	const projectList = () => {
		dispatch(getAllProjectsReq({ navigate })
		).unwrap().then((data) => {
			setAllProjects(data)
		})
	}

	const formatDate = (dateString) => {
		return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
	};

	const handleDelete = (id) => {
		dispatch(deleteSingleProjectReq(id)
		).unwrap().then(() => {
			projectList()
		})
	}

	const deleteSelectedProjects = () => {
		dispatch(deleteMultipleProjectReq(selectedProjects)
		).unwrap().then((data) => {
			projectList()
		})
		window.location.reload()
	}

	const renameAction = (status, name, index) => {
		setEdit(true);
		setIndex(index);
		setProjectName(name);
	}

	const enterKeyPress = (e, id, index) => {
		e.keyCode === 13 && updateProject(projectName, id, index);
	}

	const updateProject = (projectname, id, index) => {
		dispatch(renameProjectReq({ id, name: projectname })
		).unwrap().then((data) => {
			projectList();
		})
		setEdit(false);
	}

	const projectFilter = (text) => {
		dispatch(filterProjectReq(text)
		).unwrap().then((data) => {
			setAllProjects(data)
		})
	}

	let intervalId;
	const SearchProject = (value) => {
		clearInterval(intervalId)
		intervalId = setTimeout(() => {
			value === "" ?
				projectList() :
				dispatch(searchProjects(value)
				).unwrap().then((data) => {
					setAllProjects(data)
				})
		}, 2000)
	}

	useEffect(() => {
		setTimeout(() => {
			hideLadingLoader();
		}, 500)
		if (localStorage.getItem("newProjId") !== "") {
			setCurrentProject(localStorage.getItem("newProjId"))
		}
		projectList();

		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title>Projects</title>
				<meta property="og:title" content={"Projects"} />
			</Helmet>
			<Box id="Project">
				{loading && <Loader />}
				<Box
					className="user-dashboard-header flex items-center justify-between border-bottom"
				>
					<Box className="flex items-center gap-2">
						<IconButton
							size="small"
							onClick={handleSidebar}
							className="sidebar-menu-btn"
							sx={{ display: { laptop: "none" } }}
						><Icons.FiMenu />
						</IconButton>
						<Typography className="page-header-title">my projects</Typography>
					</Box>
					<Button
						size="small"
						variant="contained"
						onClick={() => {
							checkPlan() ?
								navigate("/user/chat?create_project=new") :
								navigate("/pricing")
						}}
						className="fill-btn clr-primary small-btn"
					>New Project
					</Button>
				</Box>

				<div className="page-projects">
					<div className="page-project-header d-flex align-items-start align-align-items-sm-center justify-content-between mb-4">
						<h5 className="m-0 page-topbar-header d-flex"><Icons.FiLayers className="me-2" /> projects</h5>
						<div className="d-flex flex-column flex-sm-row">
							{selectedProjects.length > 0 &&
								<div className="d-flex justify-content-between justify-content-sm-end">
									<Button
										size="small"
										disableRipple
										variant="outlined"
										className="border-btn clr-primary !text-xs me-2"
										startIcon={<Icons.FiPlus className="!text-sm" />}
									>{selectedProjects.length} Selected
									</Button>
									<Button
										size="small"
										variant="outlined"
										onClick={deleteSelectedProjects}
										className="border-btn clr-primary !text-xs me-2"
										startIcon={<Icons.FiTrash className="!text-sm" />}
									>Delete
									</Button>
								</div>
							}
							<div className="d-flex mt-2 mt-sm-0">
								<Form.Control className="me-2" type="text" placeholder="Search project" onChange={(e) => { SearchProject(e.target.value) }} style={{ height: "36px" }} />
								<div className="grid-setting me-3 d-flex">
									<button
										className={`btn-sm border-0 me-1 ${listView === "GRID" && "grid-active"}`}
										onClick={() => { dispatch(setListView("GRID")) }}
									><Icons.FiGrid /></button>
									<button
										className={`btn-sm border-0 ${listView === "LIST" && " grid-active"}`}
										onClick={() => { dispatch(setListView("LIST")) }}
									><Icons.FiList /></button>
								</div>
								<Select searchable={false} className="text-capitalize filter-dropdown" options={filterText} onChange={(values) => { projectFilter(values[0].value) }}></Select>
							</div>
						</div>
					</div>

					{/* <Box className="py-[16px]">
						{allProjects.length > 0 &&
							<Grid container spacing={2}>
								{allProjects && allProjects.map((project, i) => (
									<Grid key={i} item xs={12} md={4} lg={3}>
										<Paper sx={{ boxShadow: 0 }} className={`single-project transitions ${currentProject === project._id ? "active" : ""}`}>
											<Box className="flex justify-between gap-2 items-start">
												{(edit === false) ?
													<Link className="title" to={`/user/chat?project_id=${project._id}`}>{project.name}</Link> :
													(Index === i) ?
														<Input
															type="text"
															onKeyUp={enterKeyPress}
															defaultValue={project.name}
															className="project-name-input"
															onChange={(e) => { setProjectName(e.target.value) }}
														/> :
														<Link className="title" to={`/user/chat?project_id=${project._id}`}>{project.name}</Link>
												}
												<Input type="checkbox" onChange={handleProjectId} className="project-checkbox" />
											</Box>
											<Box className="flex justify-between">
												<Box sx={{ width: "calc(100% - 24px)" }}>
													<Box className="content flex items-center">
														<Icons.FiCalendar className="me-[6px]" />
														<Typography component={Moment} className="content" format="MMMM Do YYYY, h:mm:ss a">{projectList.updated_at}</Typography>
													</Box>
													<Box className="content flex items-center">
														<Icons.FiUser className="me-[6px]" />
														<Typography className="content">{projectList.status === 1 ? "Shared to Workspace" : "Private"}</Typography>
													</Box>
												</Box>
												<IconButton
													size="small"
													disableRipple
													onClick={handleClick}
												><Icons.FiThreeDot /></IconButton>
												<Menu
													open={open}
													anchorEl={anchorEl}
													onClose={handleClose}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'right',
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													sx={{
														".MuiList-root": { padding: "4px" },
														"& .MuiPaper-root": { boxShadow: "1px 1px 8px #00000020" },
													}}
													transitionDuration={{ appear: 100, enter: 100, exit: 0 }}
												>
													<Collapse in={true} timeout="auto" unmountOnExit>
														<MenuItem className="p-0 mb-1">
															<MuiButton
																size="small"
																variant="text"
																className="project-dropdown-btn"
																startIcon={<Icons.Edit className="!text-sm" />}
																onClick={() => {
																	renameAction(true, project.name, i)
																	console.log(project._id)
																}}
															>rename
															</MuiButton>
														</MenuItem>
														<MenuItem className="p-0">
															<MuiButton
																size="small"
																variant="text"
																className="project-dropdown-btn"
																startIcon={<Icons.FiTrash className="!text-sm" />}
															// onClick={handleClose}
															>delete
															</MuiButton>
														</MenuItem>
													</Collapse>
												</Menu>
											</Box>
										</Paper>
									</Grid>
								))
								}
							</Grid>
						}
					</Box> */}

					<div className={`page-project-list ${listView === "GRID" && " page-project-list-grid"}`}>
						{allProjects.length > 0 ?
							<Fragment>
								{allProjects && allProjects.map((projectList, index) => (
									<div key={index} className={`single-project rounded mb-2 !block p-3 ${listView === "LIST" ? "d-flex align-items-start align-items-sm-center mb-3 flex-row" : "flex-col"} ${currentProject === projectList._id && "active"}`} >
										<div className={`list-item-info d-flex ${listView === "LIST" ? "mb-0 align-items-start align-items-sm-center flex-grow-1" : "mb-3 justify-content-between"}`} >
											{
												listView === "LIST" &&
												<div className="item-check me-3">
													<input type="checkbox" className={`${listView === "LIST" ? "mt-2" : ""}`} name="itemid" value={projectList._id} onChange={handleProjectId} />
												</div>
											}

											{
												(edit === false) ?
													<Link style={{ width: `${listView === "GRID" && "calc(100% - 35px)"}` }} to={`/user/chat?project_id=${projectList._id}`} className="text-decoration-none text-dark" ref={(el) => productNameRef.current[index] = el}>
														<h5 className="title flex-grow-1 mb-0">{projectList.name}</h5>
													</Link> :
													(Index === index) ?
														<Form.Control className={`${listView === "LIST" && "me-3"}`} type="text" name="projectName" value={projectName} onChange={(e) => { setProjectName(e.target.value) }} onKeyUp={(e) => { enterKeyPress(e, projectList._id, index) }} ref={(el) => inputRef.current[index] = el} /> :
														<Link to={`/user/chat?project_id=${projectList._id}`} className="text-decoration-none text-dark" ref={(el) => productNameRef.current[index] = el}>
															<h5 className="title flex-grow-1">{projectList.name}</h5>
														</Link>
											}

											{
												listView === "GRID" &&
												<div className="item-check ms-3">
													<input type="checkbox" name="itemid" value={projectList._id} onChange={handleProjectId} />
												</div>
											}
										</div>
										<div className={`list-item-footer mt-2 mt-sm-0 ${listView === "LIST" ? "ms-sm-auto" : "flex-grow-1"}`}>
											<div className="item-date d-flex justify-content-between">
												<div className={listView === "LIST" ? "d-flex" : "d-flex flex-column"} style={{ width: "calc(100% - 24px)" }}>
													<p className={`m-0 d-flex align-items-center ${listView === "LIST" && "me-3"}`}>
														<Icons.FiCalendar className="me-1" />
														<span>{formatDate(projectList.updated_at)}</span>
													</p>
													<p className={`m-0 d-flex align-items-center ${listView === "LIST" && "me-3"}`}>
														<Icons.FiUser className="me-1" />
														<span>{projectList.status === 1 ? "Shared to Workspace" : "Private"}</span>
													</p>
												</div>
												<div className="item-dropdown" style={{ width: "24px" }}>
													<NavDropdown title={<Icons.FiThreeDot className="me-1" />} id="basic-nav-dropdown">
														<NavDropdown.Item onClick={() => { renameAction(true, projectList.name, index) }}>Rename</NavDropdown.Item>
														<NavDropdown.Item href="/" onClick={(e) => { e.preventDefault(); handleDelete(projectList._id); }}>delete</NavDropdown.Item>
													</NavDropdown>
												</div>
											</div>
										</div>
									</div>
								))}
							</Fragment> : ""
						}
					</div>
				</div>
			</Box>
			<Toaster />
		</Fragment>
	)
}

export default Project;