import DialogContainer from "./components/common/dialogs/DialogContainer"
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminLayout from "./components/admin/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import Loader from './components/frontend/TextLoader';
import UserLayout from "./components/user/UserLayout";
import ProtectedRoute from './utils/ProtectedRoute';
import FrontendRoute from './router/FrontendRoute';
import React, { Suspense, useEffect } from 'react';
import Dashboard from "./pages/frontend/Dashboard";
import Layout from './components/frontend/Layout';
import NotFound from './pages/frontend/NotFound';
import { TourProvider } from '@reactour/tour';
import AdminRoute from './router/AdminRoute';
import { SetUserTour } from './actions';
import UserRoute from "./router/UserRoute";
import SimpleBar from 'simplebar-react';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import './index.css'

function App() {
  const radius = 10;
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  axios.defaults.credentials = 'include';
  const isLogin = Boolean(cookies.get('auth'));
  const UserTour = useSelector((state) => state.GetUserTour)
  axios.defaults.headers.common['Authorization'] = `Bearer ${cookies.get('auth')}`;
  axios.defaults.headers.common['Content-Type'] = `application/json`;
  const steps = [
    {
      selector: '.tour-step-1',
      // selector: '.first-step',
      content: 'AltaChat is your friendly and powerful chatbot ready to assist you with everyday tasks.',
    },
    {
      selector: '.tour-step-2',
      // selector: '.second-step',
      content: 'The prompts list offers you sophisticated and weekly-updated prompts for all use cases.',
    },
    {
      selector: '.tour-step-3',
      // selector: '.eight-step',
      content: 'The Upload feature lets you easily upload documents or images for AltaChat to analyze.',
    },
    {
      selector: '.tour-step-4',
      // selector: '.third-step',
      content: 'The Improve feature helps you fine-tune your input, ensuring you communicate effectively with the AI for optimal results.',
    },
    {
      selector: '.tour-step-5',
      // selector: '.seventh-step',
      content: `Editor is a tool that allows you to format and download content directly within AltaChat'dashboard.`,
    },
    {
      selector: '.tour-step-6',
      // selector: '.fourth-step',
      content: 'The projects tab stores all your chats and content generated. Each project can be renamed.',
    },
    {
      selector: '.tour-step-7',
      // selector: '.fifth-step',
      content: 'The brand voice feature gives your content a personal touch and matches your unique style effortlessly.',
    },
    {
      selector: '.tour-step-8',
      // selector: '.sixth-step',
      content: 'AltaCopy helps you generate all types of content with its handy selection of templates.',
    },
    {
      selector: '.tour-step-9',
      // selector: '.nineth-step',
      content: 'AltaDetect is an AI feature that identifies human-authored content and detects plagiarism.',
    },
  ];

  const UpdateUserTour = (currentStep) => {
    axios.post(`${process.env.REACT_APP_API_URL}/admin/update/user/tour`, { tour: +currentStep, isCompleted: true }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('auth')}`,
      }
    }).then((res) => {
      if (res.data?.success === true) {
        dispatch(SetUserTour(res?.data?.tour));
      }
    })
  }

  useEffect(() => {
    if (isLogin === true) {
      if (Object.keys(UserTour).length > 0) {
        // setCurrentStep(UserTour.tour)
      }
    }

    // eslint-disable-next-line
  }, [navigate])

  return (
    <div className="App">
      <SimpleBar style={{ maxHeight: '100vh' }}>
        <Suspense fallback={<Loader />}>
          <TourProvider steps={steps}
            currentStep={Object.keys(UserTour).length > 0 ? UserTour.tour : 0}
            badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}
            onClickClose={({ currentStep, setIsOpen }) => { UpdateUserTour(currentStep); setIsOpen(false) }}
            onClickMask={({ currentStep, setIsOpen }) => { UpdateUserTour(currentStep); setIsOpen(false) }}
            styles={{
              popover: (base) => ({
                ...base,
                '--reactour-accent': '#bceaf9',
                borderRadius: radius,
                left: 30
              }),
              maskArea: (base) => ({ ...base, rx: radius }),
              maskWrapper: (base) => ({ ...base, color: '#bceaf9' }),
              badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
              controls: (base) => ({ ...base, marginTop: 100 }),
              close: (base) => ({ ...base, right: 'auto', left: 8, top: 8, content: 'skip' }),
            }}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/" element={<Layout />}>
                {
                  FrontendRoute && FrontendRoute.map((item, index) => {
                    return (
                      <Route key={index} path={item.path} element={item.component} />
                    )
                  })
                }
              </Route>
              <Route path="/admin" element={<AdminLayout />}>
                {
                  AdminRoute && AdminRoute.map((item, index) => {
                    return (
                      <Route key={index} path={item.path} element={<ProtectedRoute>{item.component}</ProtectedRoute>} />
                    )
                  })
                }
              </Route>
              <Route path="/" element={<UserLayout />}>
                {
                  UserRoute && UserRoute.map((item, index) => {
                    return (
                      <Route key={index} path={item.path} element={<ProtectedRoute>{item.component}</ProtectedRoute>} />
                    )
                  })
                }
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
          </TourProvider>
        </Suspense>

      </SimpleBar>
      <DialogContainer />
    </div>
  );
}

export default App;
