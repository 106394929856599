import { headers, checktoken, hideLadingLoader } from "../../../helper"
import Loader from "../../../components/frontend/TextLoader"
import Toaster from "../../../components/frontend/Toaster"
import { useState, Fragment, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Row, Col, Form } from "react-bootstrap"
import Spinner from "react-bootstrap/Spinner"
import { toast } from "react-toastify"
import { Button } from "@mui/material"
import axios from "axios"

const CreateTemplate = () => {
	const [name, setName] = useState("");
	const [spinner, setSpinner] = useState(true);
	const [category, setCategory] = useState(null);
	// const [categories, setCategories] = useState([]);
	const [description, setDescription] = useState("");
	const [roundspinner, setRoundSpinner] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [text, setText] = useState({ Label: "What are you looking to create?", Field: "text", Placeholder: "", Name: "title" });
	const [textarea, setTextarea] = useState({ Label: "What are the main points you want to cover?", Field: "textarea", Placeholder: "", Name: "description" });

	const categoryList = () => {
		setSpinner(true)
		axios.get(`${process.env.REACT_APP_API_URL}/template/category/list`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				// setCategories(res.data.data);
			} else {
				toast.error(res.data.error);
			}
			setSpinner(false)
		}).catch((err) => {
			setSpinner(false);
			checktoken(err);
			if (err.response !== undefined) {
				if (err.response.data.error !== undefined) {
					toast.error(err.response.data.error[0]);
				}
			}
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setRoundSpinner(true)
		setSearchParams({ template: "true" })
		const formData = {
			name: name.toLowerCase(),
			categoryId: category,
			description: description.toLowerCase(),
			fields: JSON.stringify([text, textarea]),
			projectId: null
		};
		axios.post(`${process.env.REACT_APP_API_URL}/template/create`, formData, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setName("");
				setCategory("");
				setCategory(null);
				setDescription("");
				setText({ Label: "", Field: "text", Placeholder: "", Name: "" })
				setTextarea({ Label: "", Field: "textarea", Placeholder: "", Name: "" });
				toast.success(res.data.message);
				window.location.href = "/user/create-template?template=" + searchParams
			} else {
				toast.error(res.data.error);
			}
			setRoundSpinner(false)
		}).catch((err) => {
			checktoken(err);
			setRoundSpinner(false)
			if (err.response !== undefined) {
				if (err.response?.data?.error !== undefined) {
					toast.error(err.response.data.error);
				}
			}
		})
	}

	useEffect(() => {
		hideLadingLoader();
		setSpinner(true);
		categoryList();
	}, [])

	return (
		<Fragment>
			{spinner === true ? <Loader /> : ""}
			<Toaster />
			<div className="user-dashboard-header d-flex align-items-center justify-content-between border-bottom">
				<h5 className="m-0 page-topbar-header d-flex">create template</h5>
			</div>
			<div className="page-projects">
				<Form onSubmit={handleSubmit}>
					<Col sm={12} md={8} className="mx-auto">
						<Row>
							<Col sm={12}>
								<Form.Group className="mb-3">
									<Form.Label>Name *</Form.Label>
									<Form.Control type="text" placeholder="Enter the template name" value={name} onChange={(e) => { setName(e.target.value) }} />
								</Form.Group>
							</Col>
							<Col sm={12}>
								<Form.Group className="mb-3">
									<Form.Label>Description *</Form.Label>
									<Form.Control as="textarea" type="text" placeholder="Enter the template description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
								</Form.Group>
							</Col>
							<Col sm={12}>
								<Form.Group className="mb-3">
									<Form.Control type="text" name="Label" placeholder="Enter Label" value={text.Label} onChange={(e) => { setText({ ...text, Label: e.target.value }); }} readOnly />
								</Form.Group>

								<Form.Group className="mb-3">
									<Form.Control as="textarea" className="placeholder-input" type="text" placeholder="Enter title or content" name="Placeholder" value={text.Placeholder} onChange={(e) => { setText({ ...text, Placeholder: e.target.value }) }} />
								</Form.Group>
							</Col>
							<Col sm={12}>
								<Form.Group className="mb-3">
									<Form.Control type="text" name="Label" placeholder="Enter Label" value={textarea.Label} onChange={(e) => { setTextarea({ ...textarea, Label: e.target.value }); }} readOnly />
								</Form.Group>
								<Form.Group>
									<Form.Control as="textarea" className="placeholder-input" type="text" placeholder="Enter title or content" name="Placeholder" value={textarea.Placeholder} onChange={(e) => { setTextarea({ ...textarea, Placeholder: e.target.value }) }} />
								</Form.Group>
							</Col>
							<Col sm={12}>
								<Button
									type="submit"
									variant="contained"
									className="fill-btn my-3 clr-primary !font-ff-lato !capitalize me-2"
								>Submit {roundspinner === true && <Spinner className="ms-1" size="sm" />}
								</Button>
							</Col>
						</Row>
					</Col>

					{/* <div className="border p-2 rounded bg-light d-grid grid-element mb-4 position-relative" style={{ gridTemplateColumns: "1fr 1fr" }}>
						<Form.Group>
							<Form.Control type="text" name="Label" placeholder="Enter Label" value={text.Label} onChange={(e) => { setText({ ...text, Label: e.target.value }); }} readOnly />
						</Form.Group>
						<Form.Group>
							<Form.Control as="textarea" className="placeholder-input" type="text" placeholder="Enter title or content" name="Placeholder" value={text.Placeholder} onChange={(e) => { setText({ ...text, Placeholder: e.target.value }) }} />
						</Form.Group>
					</div> */}

					{/* <div className="border p-2 rounded bg-light d-grid grid-element mb-4 position-relative" style={{ gridTemplateColumns: "1fr 1fr" }}>
						<Form.Group>
							<Form.Control type="text" name="Label" placeholder="Enter Label" value={textarea.Label} onChange={(e) => { setTextarea({ ...textarea, Label: e.target.value }); }} readOnly />
						</Form.Group>
						<Form.Group>
							<Form.Control as="textarea" className="placeholder-input" type="text" placeholder="Enter title or content" name="Placeholder" value={textarea.Placeholder} onChange={(e) => { setTextarea({ ...textarea, Placeholder: e.target.value }) }} />
						</Form.Group>
					</div> */}

				</Form>
			</div>
		</Fragment>
	)
}

export default CreateTemplate;