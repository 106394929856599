import { readFileTextReq } from "../../../redux/altadetect.slice"
import { FileContext } from "../../frontend/ContextProvider"
import { hideDialog } from "../../../redux/dialog.slice"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useRef, useState } from "react"
import Images from "../../../assets/images"
import Icons from "../../../assets/icons"
import {
  Box, Button, CircularProgress,
  DialogActions, DialogContent,
  Divider, IconButton, Paper, styled
} from "@mui/material"
import { toast } from "react-toastify"
import Toaster from "../../frontend/Toaster"

const VisuallyHiddenInput = styled("input")({
  left: 0,
  width: 1,
  height: 1,
  bottom: 0,
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "absolute",
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
})

function SelectFile() {
  const dispatch = useDispatch()
  const file = useRef()
  const [name, setName] = useState("")
  const { setText } = useContext(FileContext)
  const { loading } = useSelector(state => state.altadetectSlice)

  const handleClose = () => {
    dispatch(hideDialog())
  }

  const handleUpload = (event) => {
    const file = event.target.files[0]
    const ext = file?.name?.split(".").pop()
    if (!(ext === "docx" || ext === "doc" || ext === "txt")) {
      event.target.value = ""
      toast.error(`Invalid file type .${ext}`)
      setName("")
      return
    }
    setName(file?.name)
  }

  const handleClick = () => {
    const newFile = file.current.files[0]
    if (!newFile) { return }

    dispatch(readFileTextReq(newFile)
    ).unwrap().then((res) => {
      if (Array.isArray(res)) {
        let text = ""
        res.forEach((t) => {
          text += t + "\n"
        })
        setText(text)
      } else {
        setText(res)
      }
    })
    // handleClose()
  }

  return (
    <Paper id="SelectFile">
      <DialogContent className="relative">
        <Box className=" inline-block absolute top-[10px] right-[10px]">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Box className="text-center">
          <img className="h-[50px] w-[150px] object-contain mb-4 dialog-img" src={Images.logo} alt="logo" />
        </Box>
        <Button
          component="label"
          variant="outlined"
          startIcon={<Icons.MuiCloudUpload className="text-blue-violet-500" />}
          className="border-btn clr-primary !capitalize !border-dashed w-full !py-6"
        >{name || "Upload file"}
          <VisuallyHiddenInput
            ref={file}
            type="file"
            onChange={handleUpload}
          />
        </Button>
      </DialogContent>
      <Divider className="!border-gray-500" />
      <DialogActions sx={{ px: 3 }}>
        <Button
          size="small"
          variant="contained"
          className="fill-btn clr-primary"
          onClick={handleClick}
        >Scan file
          {loading &&
            <CircularProgress
              size={20}
              thickness={5}
              sx={{ color: "white", ml: 1, display: "flex" }}
            />}
        </Button>
      </DialogActions>
      <Toaster />
    </Paper>
  )
}

export default SelectFile