import { headers, checktoken, hideLadingLoader } from "../../../helper";
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { FiSunrise, FiArrowLeft, FiMenu } from "react-icons/fi";
import { Form, Card, Col, Button } from 'react-bootstrap';
import Loader from '../../../components/frontend/TextLoader';
import Toaster from '../../../components/frontend/Toaster';
import { useState, Fragment, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import axios from "axios";
import { showDialog } from "../../../redux/dialog.slice";

const BrandEdit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [handleSidebar] = useOutletContext();
	const [spinner, setSpinner] = useState(true);
	const [isChecked, setIsChecked] = useState(true);
	const { user } = useSelector(state => state.authSlice);
	const chatWords = useSelector(state => state.getChatWords);
	const [brand, setBrand] = useState({ name: '', content: '' });
	const workspace = useSelector(state => state.getWorkspace)

	const handleChange = (e) => {
		setIsChecked(e.target.checked);
		let value = e.target.value;
		let name = e.target.name;
		setBrand({ ...brand, [name]: value })
	}

	// edit  barnd voice ------------------------------------------
	useEffect(() => {
		checkPlan();
		hideLadingLoader();
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/user/brand/edit/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setBrand(res.data.data);
				if (res.data.data.is_check === 'default') {
					setIsChecked(true);
				} else {
					setIsChecked(false);
				}
			}
			setSpinner(false);
		}).catch((err) => { setSpinner(false); checktoken(err) })

		// eslint-disable-next-line
	}, []);

	// update barnd voice ------------------------------------------
	const handleSubmit = (e) => {
		e.preventDefault();
		axios.put(`${process.env.REACT_APP_API_URL}/user/brand/update/${id}`, brand, {
			headers
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				navigate('/user/brand');
			} else {
				toast.error(res.data.error);
			}
		}).catch((err) => {
			if (err.response.data.success === false) {
				let errors = err.response.data.error;
				for (let x in errors) {
					toast.error(errors[x]);
				}
			}
		})
	}

	const checkPlan = () => {
		if (user?.appsumocode === null) {
			if (chatWords >= 50000 && user?.currentplan?.packagename === 'SOLO') {
				navigate('/pricing');
				return false
			} else if (workspace?.userId?.status === "free" || workspace?.userId?.status === "expired" || workspace?.userId?.status === "canceled") {
				dispatch(showDialog("ReachLimit"))
			}
		} else {
			if (user?.plan === "Solo" && chatWords >= 30000) {
				navigate('/pricing');
				return false
			}
		}
	}

	return (
		<Fragment>
			<Helmet>
				<title>Brand Voice</title>
				<meta property="og:title" content={'Brand Voice'} />
			</Helmet>
			{spinner === true ? <Loader /> : ''}
			<Toaster />
			<div className="user-dashboard-header d-flex border-bottom flex-column">
				<div className="d-flex align-content-center justify-content-between">
					<button className="d-inline-block text-center text-decoration-none page-header-btn-dark btn menu-btn me-2" onClick={handleSidebar}><FiMenu /></button>
					<Link to="/user/brand">
						<Button variant="light" size="sm" className="btn btn-light page-header-btn w-auto" ><FiArrowLeft className="mx-2" /> Go Back </Button>
					</Link>
				</div>
				<h5 className='m-0 mb-2 mt-2 page-header'>Edit Brand Voice</h5>
				<p className="m-0">Write or paste content that reflects your brand voice. For best results, we recommend between 50-500 words.</p>
			</div>
			<div className="d-block brand-form">
				<Col xxl={6} xl={6} lg={6} md={6} sm={12}>
					<Form onSubmit={handleSubmit}>
						<Form.Group>
							<Form.Label>NAME OF THE BRAND VOICE (REQUIRED)</Form.Label>
							<Form.Control type="text" placeholder="ex: Alta Marketing" name="name" value={brand?.name} onChange={handleChange} />
						</Form.Group>
						<Form.Label>BRAND VOICE</Form.Label>
						<Form.Control name="content" value={brand.content} onChange={handleChange}
							as="textarea" rows={10} />
						<Card className="my-4 bg-light">
							<Card.Body>
								<Card.Title> <FiSunrise /> TIPS FOR EDITING A BRAND VOICE</Card.Title>
								<Card.Text>
									1. When editing, make sure not to use negative commands. (Ex: Use "Be courteous.", instead of "Don't be rude.") <br />
									2. For best results, you can redo the analysis instead of editing.
								</Card.Text>

							</Card.Body>
						</Card>
						<Form.Group>
							<div className="py-2">
								<Form.Check
									type='checkbox'
									id={`default-checkbox`}
									label={`SAVE AS THE DEFAULT VOICE ACROSS YOUR WORKSPACE`}
									name="isCheck"
									value={`default`}
									checked={isChecked}
									onChange={handleChange}
								/>
							</div>
						</Form.Group>
						<Form.Group className='mt-3'>
							<Button type="submit" variant="info text-white">Save Brand Voice</Button>
						</Form.Group>
					</Form>
				</Col>
			</div>
		</Fragment>
	)

}

export default BrandEdit;