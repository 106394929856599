const intialState = [];
const getRememberChat = (state = intialState, action) => {
    switch(action.type){
        case "SETCHAT":
            return state.push(action.data);
        default:
            return state;
    }
}

export default getRememberChat;