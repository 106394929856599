import { createContext, useState } from "react"

export const FileContext = createContext()

function ContextProvider({ children }) {
  const [text, setText] = useState("")

  return (
    <FileContext.Provider value={{ text, setText }}>
      {children}
    </FileContext.Provider>
  )
}

export default ContextProvider