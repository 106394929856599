import { Card, Form, Col, Button, InputGroup } from 'react-bootstrap';
import Loader from "../../components/frontend/TextLoader";
import Toaster from "../../components/frontend/Toaster";
import { headers, checktoken } from '../../helper';
import { useEffect, useState } from 'react';
import { FiSend } from "react-icons/fi";
import { toast } from 'react-toastify';
import axios from 'axios'

const Bard = ()=>{
    const [text, setText] = useState('')
    const [spinner, setSpinner] = useState(true);

    const handleSubmit = (e)=>{
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/admin/search/bard`, {text:text},{
            headers:headers()
        }).then((res)=>{
            if(res.data.success === true){
                
            }
        }).catch((err)=>{
            checktoken();
            if(err.response?.data?.success===false){
                if(err.response?.data?.error !== undefined){
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    useEffect(()=>{
        setSpinner(true);
        setTimeout(()=>{setSpinner(false)}, 200)
    }, [])

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader/>}
            <Toaster/>
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Google bard</Card.Title>
            </Card.Header>
            <Card.Body>
                <Col sm={6}  className="m-auto">
                    <Form onSubmit={handleSubmit}>
                        <Form.Label htmlFor="text">Type here...</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control id="text" type="text" placeholder="Enter here for search anything!" name="text" value={text} onChange={(e)=>{setText(e.target.value)}} />
                            <Button type="submit" variant={`primary ${text === '' && 'disabled'}`}><FiSend/></Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Card.Body>
        </Card>
    )
}

export default Bard;