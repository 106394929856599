import { headers, checktoken, hideLadingLoader } from '../../../helper';
import { Form, Card, Row, Col, Spinner } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import Toaster from '../../../components/frontend/Toaster';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import axios from 'axios';

const EditTemplates = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [name, setName] = useState('')
    const [spinner, setSpinner] = useState(true)
    const [category, setCategory] = useState(null)
    const [categories, setCategories] = useState([])
    const [description, setDescription] = useState('')
    const [roundspinner, setRoundSpinner] = useState(false);
    const [text, setText] = useState({ Label: 'What are you looking to create?', Field: 'text', Placeholder: '', Name: 'title' });
    const [textarea, setTextarea] = useState({ Label: 'What are the main points you want to cover?', Field: 'textarea', Placeholder: '', Name: 'description' });

    const getSingleTemplate = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/template/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setName(res.data.data.name);
                setCategory(res.data.data.categoryId)
                setDescription(res.data.data.description)
                const fields = JSON.parse(res.data.data.fields);
                const title = fields.find((x) => x.Name === 'title')
                const description = fields.find((x) => x.Name === 'description')
                if (title !== undefined) {
                    setText({ ...text, Label: title.Label })
                    setText({ ...text, Placeholder: title.Placeholder })
                }
                if (description !== undefined) {
                    setTextarea({ ...textarea, Label: description.Label })
                    setTextarea({ ...textarea, Placeholder: description.Placeholder })
                }
                setSpinner(false)
            }
        }).catch((err) => { setSpinner(false); checktoken(err) })
    }

    const categoryList = () => {
        setSpinner(true)
        axios.get(`${process.env.REACT_APP_API_URL}/template/category/list`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
            } else {
                toast.error(res.data.error);
            }
            getSingleTemplate(id)
        }).catch((err) => {
            setSpinner(false);
            checktoken(err);
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error[0]);
                }
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setRoundSpinner(true)
        const formData = {
            name: name.toLowerCase(),
            categoryId: category,
            description: description.toLowerCase(),
            fields: JSON.stringify([text, textarea]),
            projectId: null
        };
        axios.put(`${process.env.REACT_APP_API_URL}/template/udpate/${id}`, formData, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                getSingleTemplate(id)
                toast.success(res.data.message);
            } else {
                toast.error(res.data.error);
            }
            setRoundSpinner(false)
        }).catch((err) => {
            checktoken(err);
            setRoundSpinner(false)
            if (err.response !== undefined) {
                if (err.response?.data?.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
        categoryList()
    }, [])

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Edit template</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Name *</Form.Label>
                                <Form.Control type="text" placeholder="Enter the template name" value={name} onChange={(e) => { setName(e.target.value) }} />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Category *</Form.Label>
                                <Form.Select name="category" onChange={(e) => { setCategory(e.target.value) }}>
                                    <option value="">Select</option>
                                    {
                                        categories && categories.map((item, index) => {
                                            return (
                                                <option key={index} value={item._id} defaultValue={category}>{item.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-4">
                        <Form.Label>Description *</Form.Label>
                        <Form.Control as="textarea" type="text" placeholder="Enter the template description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                    </Form.Group>

                    <div className='border p-2 rounded bg-light d-grid grid-element mb-4 position-relative' style={{ gridTemplateColumns: '1fr 1fr' }}>
                        <Form.Group>
                            <Form.Control type="text" name="Label" placeholder="Enter Label" value={text.Label} onChange={(e) => { setText({ ...text, Label: e.target.value }); }} readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control as="textarea" className='placeholder-input' type="text" placeholder='Enter title or content' name="Placeholder" value={text.Placeholder} onChange={(e) => { setText({ ...text, Placeholder: e.target.value }) }} />
                        </Form.Group>

                    </div>

                    <div className='border p-2 rounded bg-light d-grid grid-element mb-4 position-relative' style={{ gridTemplateColumns: '1fr 1fr' }}>
                        <Form.Group>
                            <Form.Control type="text" name="Label" placeholder="Enter Label" value={textarea.Label} onChange={(e) => { setTextarea({ ...textarea, Label: e.target.value }); }} readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control as="textarea" className='placeholder-input' type="text" placeholder='Enter title or content' name="Placeholder" value={textarea.Placeholder} onChange={(e) => { setTextarea({ ...textarea, Placeholder: e.target.value }) }} />
                        </Form.Group>
                    </div>
                    <Form.Group>
                        <button className='btn btn-info text-white'>Submit {roundspinner === true && <Spinner className="ms-1" size='sm' />}</button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default EditTemplates;