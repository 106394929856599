import { Fragment, forwardRef } from "react"
import {
  OutlinedInput, MenuItem, Select,
  FormControl, InputLabel,
} from "@mui/material"

const CustomSelect = forwardRef(({ label, name, value, select, options, id, labelClass, disabled = false, onChngFun }, ref) => {

  const handleChange = (e) => {
    if (onChngFun) onChngFun(e.target.value?._id)
  }

  return (
    <Fragment>
      {label &&
        <InputLabel htmlFor={id} className={`mb-1 capitalize !text-gray-800 ${labelClass && labelClass}`}>{label}</InputLabel>
      }
      <FormControl size="small" fullWidth>
        <Select
          name={name}
          labelId={id}
          inputRef={ref}
          disabled={disabled}
          onChange={handleChange}
          input={<OutlinedInput
            id={id}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #8b6dba",
              }
            }}
          />}
          defaultValue={value || "[object Object]"}
          renderValue={(option) => option.text || option.name || select || "Select"}
        >
          <MenuItem value="[object Object]" disabled>{select || "Select"}</MenuItem>
          {options.map((item, i) => (
            <MenuItem key={i} value={item}>{item.text || item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  )
})

export default CustomSelect