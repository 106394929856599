import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headers } from "../helper";
import { toast } from "react-toastify";

export const getPromptCategoryReq = createAsyncThunk("getPromptCategoryReq", (_, { rejectWithValue }) => {
  try {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/list-category`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      } else {
        toast.error(res?.data?.error)
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error)
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const getCategoryPromptReq = createAsyncThunk("getCategoryPromptReq", (id, { rejectWithValue }) => {
  try {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/category/prompt/${id}`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      } else {
        toast.error(res?.data?.error)
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error)
      }
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  prompts: {},
  categories: {},
}
const prompt = createSlice({
  name: "prompt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Categories
    builder.addCase(getPromptCategoryReq.pending, (state) => {
      state.categories.loading = true
    })
    builder.addCase(getPromptCategoryReq.fulfilled, (state, { payload }) => {
      delete state.categories.loading
      state.categories.data = payload
    })
    builder.addCase(getPromptCategoryReq.rejected, (state) => {
      delete state.categories.loading
    })
    // Get Categories Prompts
    builder.addCase(getCategoryPromptReq.pending, (state) => {
      state.prompts.loading = true
    })
    builder.addCase(getCategoryPromptReq.fulfilled, (state, { payload }) => {
      delete state.prompts.loading
      state.prompts.data = payload
    })
    builder.addCase(getCategoryPromptReq.rejected, (state) => {
      delete state.prompts.loading
    })

    // Logout
    builder.addCase("LOGOUT", () => initialState)
  }
})

export default prompt.reducer