import { checktoken, hideLadingLoader } from '../../../helper';
import Loader from "../../../components/frontend/TextLoader";
import Toaster from '../../../components/frontend/Toaster';
import { useState, Fragment, useEffect } from "react";
import { Table, Card, Badge } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { Cookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const UserPaymentDetails = () => {
    const cookies = new Cookies();
    const [handleSidebar] = useOutletContext();
    const [payments, setPayments] = useState([]);
    const [spinner, setSpinner] = useState(true);

    const paymentList = () => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/user/payment-list`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('auth')}`,
            }
        }).then((res) => {
            if (res.data.success === true) {
                setPayments(res.data.data);
            }
            setSpinner(false);
            hideLadingLoader();
        }).catch((err) => { setSpinner(false); hideLadingLoader(); checktoken(err) })
    }

    useEffect(() => {
        setSpinner(true);
        paymentList();

        // eslint-disable-next-line
    }, []);

    return (

        <Fragment>
            <Helmet>
                <title>Transaction</title>
                <meta property="og:title" content={'Transaction'} />
            </Helmet>
            {spinner === true ? <Loader /> : ''}
            <Toaster />
            <div className="user-dashboard-header d-block border-bottom justify-content-between align-items-center align-items-sm-start">
                <button className="d-block x-xl-none text-center text-decoration-none page-header-btn-dark btn menu-btn me-2 mb-2" onClick={handleSidebar}><FiMenu /></button>
                <h5 className='m-0 mb-sm-2 page-header'>Transactions Details</h5>
            </div>
            <div className="d-block brand-form">
                <Card className="shadow-lg border-0">
                    <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                        <Card.Title className="m-0">List of Transactions</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Transactions</th>
                                        <th>Amount</th>
                                        <th>Currency</th>
                                        <th>Package Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        payments && payments.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.subscriptionId}</td>
                                                    <td className="fw-bold">${item.amount}</td>
                                                    <td className="text-uppercase fw-bold">{item.currency}</td>
                                                    <td><Badge bg={`${item.packageName === 'PRO' ? 'success' : 'primary'}`}>{item.packageName}</Badge></td>
                                                    <td><Badge>{item.status}</Badge></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    )
}

export default UserPaymentDetails;