const intialState = {};
const GetToastMessage = (state = intialState, action) => {
    switch (action.type) {
        case "TOASTMESSAGE":
            state = action.data;
            return state;
        default:
            return state;
    }
}

export default GetToastMessage;