import { Card, Table, Form, Pagination } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { FiEdit2, FiPlus, FiTrash2 } from "react-icons/fi";
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const Tones = () => {
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [tones, setTone] = useState([]);
    const [error, setError] = useState('');
    const [spinner, setSpinner] = useState(true);
    const [pagination, setPagination] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const toneList = (page) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/tone/list?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setTone(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            } else {
                setError(`<Alert variant='info'>${res.data.error}</Alert>`);
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    const deleteTone = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete/tone/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.error(res.data.message);
                toneList();
            } else {
                toast.error(res.data.error);
            }
        }).catch((err) => {
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    const handleSearch = (searchQuery) => {
        axios.post(`${process.env.REACT_APP_API_URL}/tone/search`, { name: searchQuery }, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setTone(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        hideLadingLoader();
        toneList(page);
        if (location.state !== null) {
            if (location.state === 'Updated') {
                toast.success('Tone updated successfully');
                location.state = null;
            }
            if (location.state === 'Added') {
                toast.success('Tone added successfully');
                location.state = null;
            }
        }
    }, [])

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">tone list <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                <div className="d-flex">
                    <Form.Control type="search" placeholder="Search" className="me-2 search-form" aria-label="Search" name="searchQuery" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); handleSearch(searchQuery) }} />
                    <Link to="/admin/add/tone" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiPlus /> add tone</Link>
                </div>
            </Card.Header>
            <Card.Body>
                {(tones.length > 0) &&
                    <Fragment>
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Img</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tones && tones.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{page > 1 ?
                                                        (10 * page) + (index - 9) : index + 1
                                                    }</td>
                                                    <td>{item.imoji}</td>
                                                    <td className="text-capitalize">{item.name}</td>
                                                    <td>
                                                        <Link className="btn btn-primary btn-sm me-2" to={`/admin/edit-tone/${item._id}`}>
                                                            <FiEdit2 />
                                                        </Link>
                                                        <Link to='/' className="btn btn-danger btn-sm" onClick={(e) => { e.preventDefault(); deleteTone(item._id) }}>
                                                            <FiTrash2 />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        {
                            pagination.length > 1 &&
                            <Pagination className='mt-3 justify-content-center'>
                                {
                                    pagination.map((item, index) => {
                                        return (
                                            <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); toneList(item) }}>{item}</Pagination.Item>)
                                    })
                                }
                            </Pagination>
                        }
                    </Fragment>
                }
            </Card.Body>
        </Card>
    )
}

export default Tones;