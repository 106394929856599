import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { showShareWorkspaceDialog } from '../../../redux/dialog.slice'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { checktoken, hideLadingLoader } from '../../../helper'
import Loader from '../../../components/frontend/TextLoader'
import Toaster from '../../../components/frontend/Toaster'
import { Fragment, useEffect, useState } from 'react'
import { Button, IconButton } from '@mui/material'
import { Table, Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Icons from '../../../assets/icons'
import { toast } from 'react-toastify'
import { Cookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const SharedUser = (props) => {
	const cookies = new Cookies()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [handleSidebar] = useOutletContext()
	const [spinner, setSpinner] = useState(true)
	const [token, setToken] = useState("")
	const { user } = useSelector(state => state.authSlice)
	const { activeWorkspace } = useSelector(state => state.workspaceSlice)
	const [subscriptionList, setSubscriptionList] = useState([])

	const subscriptionUserList = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/users/workspace/list`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			}
		}).then((res) => {
			if (res.data.success === true) {
				setSubscriptionList(res.data.data);
			}
		}).catch((err) => { setSpinner(false); checktoken(err); })
	}

	const shareTokenHandle = () => {
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/users/workspace/share-token`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			}
		}).then((res) => {
			setSpinner(false)
			if (res.data.success === true) {
				setToken(res.data.data.shareToken)
			}
		}).catch((err) => { setSpinner(false); checktoken(err); })
	}

	const handleDelete = (id) => {
		setSpinner(true);
		axios.delete(`${process.env.REACT_APP_API_URL}/users/workspace/delete/${id}`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			}
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				subscriptionUserList();
			} else {
				toast.error(res.data.error);
			}
			setSpinner(false);
		}).catch((err) => {
			setSpinner(false); checktoken(err);
			if (err.response.data.success === false) {
				let errors = err.response.data.error;
				toast.error(errors);
			}
		})
	}

	const checkPlan = () => {
		if (user?.appsumocode === null) {
			if (user?.currentplan?.packagename !== "PRO") {
				navigate("/pricing")
				return false
			}
		} else {
			if (Object.keys(activeWorkspace).length === 0) {
				if (user?.appsumouser?.type !== "Pro") {
					navigate("/pricing")
					return false
				}
			}
		}
	}

	const handleShow = () => {
		dispatch(showShareWorkspaceDialog(token))
	}

	useEffect(() => {
		hideLadingLoader();
		checkPlan();
		setSpinner(true);
		setTimeout(() => {
			setSpinner(false);
			subscriptionUserList();
			shareTokenHandle();
		}, 100);

		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Helmet>
				<title>Shared Workspace</title>
				<meta property="og:title" content={'Shared Workspace'} />
			</Helmet>
			{spinner === true ? <Loader /> : ''}
			<Toaster />
			<div className="user-dashboard-header flex border-bottom justify-between items-center">
				<IconButton
					size="small"
					onClick={handleSidebar}
					className="sidebar-menu-btn"
					sx={{ display: { laptop: "none" } }}
				><Icons.FiMenu />
				</IconButton>
				<h5 className='m-0 mb-2 page-header'>Shared Workspace</h5>
			</div>
			<div className="d-block brand-form">
				<Card className="shadow-lg border-0">
					<Card.Header className="p-3 d-flex align-items-center justify-content-between">
						<Card.Title className="m-0 text-capitalize">Workspace List</Card.Title>
						<Card.Title className="m-0 text-capitalize">
							<Button
								variant="contained"
								onClick={handleShow}
								endIcon={<Icons.FiShare2 />}
								className="fill-btn clr-primary !font-ff-lato"
							>Share Workspace
							</Button>
						</Card.Title>
					</Card.Header>
					<Card.Body>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>N°</th>
									<th>Email</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{subscriptionList && subscriptionList.map((item, index) => {
									return (
										<tr key={item?._id}>
											<td>{index + 1}</td>
											<td>{item?.email}</td>
											<td>
												<Link className="me-2" type="button" onClick={() => handleDelete(item?._id)}>
													<Icons.FiTrash2 />
												</Link>
											</td>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</div>
		</Fragment>
	)
}

export default SharedUser;