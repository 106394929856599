import { Button, Divider, IconButton, Stack, styled, Typography } from "@mui/material"
import MarkdownViewer from "../../../components/frontend/MarkdownViewer"
import { headers, checktoken, hideLadingLoader } from "../../../helper"
import Loader from "../../../components/frontend/TextLoader"
import { Fragment, memo, useEffect, useState } from "react"
import Toaster from "../../../components/frontend/Toaster"
import { useOutletContext, Link } from "react-router-dom"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useDispatch, useSelector } from "react-redux"
import { Spinner, Alert } from "react-bootstrap"
import Icons from "../../../assets/icons"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import axios from "axios"
import {
	deleteSavedTempReq,
	getSavedTemplatesReq,
	toggleFavoriteTempReq
} from "../../../redux/template.slice"


const Div = styled("div")(() => ({}))

const SavedTemplates = () => {
	const dispatch = useDispatch()
	const [index, setIndex] = useState(0)
	const [handleSidebar] = useOutletContext()
	const [templates, setTemplates] = useState([])
	const [projectname, setProjectname] = useState("")
	const temp = useSelector(state => state.templateSlice)

	const handleCopy = (content) => {
		(content) ? toast.success("Copy Saved!") : toast.error("Content not found !");
	};

	const handleFavorite = (id, ind) => {
		setIndex(ind)
		dispatch(toggleFavoriteTempReq(id)
		).unwrap().then(() => {
			userSavedContents()
		})
	}

	const handleRemove = (id, ind) => {
		setIndex(ind)
		dispatch(deleteSavedTempReq(id)
		).unwrap().then(() => {
			userSavedContents()
		})
	}

	const userSavedContents = () => {
		dispatch(getSavedTemplatesReq()
		).unwrap().then((data) => {
			setTemplates(data)
		})
	}

	const getProjectName = (id) => {
		axios.get(`${process.env.REACT_APP_API_URL}/project/single/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setProjectname(res.data.data.name);
			}
			hideLadingLoader()
		}).catch((err) => {
			checktoken(err)
		})
	}

	useEffect(() => {
		const PID = localStorage.getItem("newProjId");
		getProjectName(PID)
		setTimeout(() => {
			hideLadingLoader()
		}, 500)
		userSavedContents()

		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Helmet>
				<title>AltaCopy</title>
				<meta property="og:title" content={"AltaCopy"} />
			</Helmet>
			{temp?.savedLoading ?
				<Loader /> :
				<Fragment>
					<Div
						sx={{ p: { xs: "6px", md: "8px 16px" } }}
						className="flex border-b justify-between items-center"
					>
						<IconButton
							size="small"
							onClick={handleSidebar}
							className="sidebar-menu-btn"
							sx={{ display: { laptop: "none" } }}
						><Icons.FiMenu />
						</IconButton>
						<Typography
							sx={{ fontSize: { xs: "14px", md: "16px" } }}
							className="font-ff-poppins font-medium capitalize"
						>{projectname}
						</Typography>
					</Div>
					<Div sx={{ p: { xs: "16px 32px", md: "24px 40px" } }}>
						<Div className="flex items-center justify-between">
							<Div className="flex items-center flex-nowrap gap-[10px]">
								<Icons.FiHeart />
								<Typography
									sx={{ fontSize: { xs: "16px", md: "20px" } }}
									className="font-ff-poppins font-medium capitalize"
								>Saved Templates
								</Typography>
							</Div>
							<Div>
								<Button
									size="small"
									sx={{ mr: 1 }}
									variant="contained"
									LinkComponent={Link}
									to="/user/create-template"
									className="fill-btn clr-primary"
								>Create
								</Button>
								<Button
									size="small"
									variant="contained"
									LinkComponent={Link}
									to="/user/favorite/template"
									className="fill-btn clr-primary"
								>Favorite
								</Button>
							</Div>
						</Div>
						<Div
							sx={{
								mt: "36px",
								mx: "auto",
								maxWidth: { xs: "100%", md: "66.66%" }
							}}
						>
							<Stack
								spacing={4}
								divider={<Divider className="opacity-[1]" flexItem />}
							>
								{(templates?.length > 0) &&
									templates?.map((item, i) => (
										<Div
											key={i}
										>
											<MarkdownViewer content={item?.content} />
											<Div
												sx={{
													mt: 2,
													alignItems: { sm: "center" },
													display: { xs: "block", sm: "flex" },
													justifyContent: { sm: "space-between" },
												}}
											>
												<Div sx={{ order: { sm: "2" } }}>
													<Typography
														mb={1}
														textAlign="end"
														sx={{ fontSize: "12px" }}
														className="!font-ff-poppins text-gray-400"
													>
														<span className="me-1">{item?.content?.split(" ")?.length} words /</span>
														<span>{item?.content?.length} chars</span>
													</Typography>
												</Div>

												<Stack
													spacing={1.5}
													direction="row"
													sx={{ order: { sm: "1" } }}
												>
													<CopyToClipboard text={item?.content}>
														<Button
															sx={{
																p: "12px 20px",
																fontSize: "14px",
																color: "#160647",
																textTransform: "capitalize",
																transitionDuration: "350ms",
																":hover": { bgcolor: "#f7f6fc" },
																border: "1px solid #e7e7e7 !important",
															}}
															startIcon={<Icons.Clipboard className="!text-[14px]" />}
															variant="outlined"
															onClick={() => handleCopy(item?.content)}
															className="!font-ff-poppins !font-normal"
														>copy
														</Button>
													</CopyToClipboard>
													<ActionBtn
														text="remove"
														loading={index === i && temp?.delLoading}
														callBack={() => { handleRemove(item?._id, i) }}
														icon={<Icons.FiMinusCircle className="!text-[14px]" />}
													/>
													<ActionBtn
														text="favorite"
														loading={index === i && temp?.toggleFavLoading}
														callBack={() => { handleFavorite(item?._id, i) }}
														icon={item?.isFavorite ?
															<Icons.BsBookmarkFill className="!text-[14px]" /> :
															<Icons.Bookmark className="!text-[14px]" />
														}
													/>
												</Stack>
											</Div>
										</Div>
									))
								}
							</Stack>
						</Div>
						{templates?.length === 0 && <Alert variant={`warning`}>There is no saved content yet!</Alert>}
					</Div>
				</Fragment>
			}

			<Toaster />
		</Fragment>
	)
}

const ActionBtn = ({ text, icon, callBack, loading }) => {

	return (
		<Button
			sx={{
				p: "12px 20px",
				fontSize: "14px",
				color: "#160647",
				textTransform: "capitalize",
				transitionDuration: "350ms",
				":hover": { bgcolor: "#f7f6fc" },
				border: "1px solid #e7e7e7 !important",
			}}
			startIcon={icon}
			disabled={loading}
			variant="outlined"
			onClick={callBack}
			className="!font-ff-poppins !font-normal"
		>{text}
			{loading &&
				<Spinner className="ms-2" animation="border" size="sm" />
			}
		</Button>
	)
}

export default memo(SavedTemplates);