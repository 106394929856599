import { checktoken, hideLadingLoader } from "../../../helper"
import { Card, Form, Row, Col, Button } from "react-bootstrap"
import Loader from "../../../components/frontend/TextLoader"
import Toaster from "../../../components/frontend/Toaster"
import { useState, Fragment, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { IconButton } from "@mui/material"
import Icons from "../../../assets/icons"
import { toast } from "react-toastify"
import { Cookies } from "react-cookie"
import { Helmet } from "react-helmet"
import axios from "axios"

const Profile = () => {
	const cookies = new Cookies();
	const [handleSidebar] = useOutletContext();
	const [spinner, setSpinner] = useState(true);
	const [data, setData] = useState({ name: "", email: "", phone: "" });

	const handleChange = (e) => {
		let value = e.target.value;
		let name = e.target.name;
		setData({
			...data,
			[name]: value
		})
	}

	const getUserDetails = (e) => {
		setSpinner(true)
		axios.get(`${process.env.REACT_APP_API_URL}/profile-edit/`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			}
		}).then((res) => {
			if (res.data.success === true) {
				setData(res.data.data);
			}
			setSpinner(false)
		}).catch((err) => { setSpinner(false); checktoken(err); })
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true)
		axios.put(`${process.env.REACT_APP_API_URL}/profile-update`, data, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`
			}
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				setData({ name: "", email: "", phone: "" });
				getUserDetails();
			} else {
				toast.error(res.data.error);
			}
		}).catch((err) => {
			setSpinner(false);
			checktoken(err);
			if (err.response.data.success === false) {
				let errors = err.response.data.error;
				toast.error(errors);
			}
		})
	}

	useEffect(() => {
		hideLadingLoader();
		getUserDetails();

		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title>Profile</title>
				<meta property="og:title" content={"Profile"} />
			</Helmet>
			{spinner === true && <Loader />}
			<Toaster />
			<div className="user-dashboard-header flex border-bottom justify-between items-center">
				<h5 className="m-0 mb-2 page-header">User Profile</h5>
				<IconButton
					size="small"
					onClick={handleSidebar}
					className="sidebar-menu-btn"
					sx={{ display: { laptop: "none" } }}
				><Icons.FiMenu />
				</IconButton>
			</div>
			<div className="d-block brand-form">
				<Card className="shadow-lg border-0">
					<Card.Header className="p-3 d-flex align-items-center justify-content-between">
						<Card.Title className="m-0 text-capitalize">Update information</Card.Title>
					</Card.Header>
					<Card.Body>
						<Form onSubmit={handleSubmit}>
							<Row>
								<Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="m-auto">
									<Form.Group className="mb-3">
										<Form.Label>Name</Form.Label>
										<Form.Control type="text" placeholder="Enter Name" name="name" value={data.name} onChange={handleChange} />
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label>Email</Form.Label>
										<Form.Control type="email" placeholder="Enter Email" name="email" value={data.email} onChange={handleChange} />
									</Form.Group>
									<div className="text-center">
										<Button
											type="submit"
											variant="contained"
											className="fill-btn clr-primary min-w-[180px] rounded-full"
										>Update
										</Button>
									</div>
								</Col>
							</Row>

						</Form>
					</Card.Body>
				</Card>
			</div>
		</Fragment>
	)
}

export default Profile;