import { hideDialog } from "../../../redux/dialog.slice"
import { useNavigate } from "react-router-dom"
import Images from "../../../assets/images"
import Icons from "../../../assets/icons"
import { useDispatch } from "react-redux"
import {
  Box, Button, DialogActions, DialogContent,
  Divider, IconButton, Paper, Typography
} from "@mui/material"

function ReachLimit() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleNavigate = () => {
    navigate("/pricing")
  }
  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="ReachLimit">
      <DialogContent className="relative">
        <Box className=" inline-block absolute top-[10px] right-[10px]">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Box className="text-center">
          <img className="h-[50px] w-[150px] object-contain mb-4 dialog-img" src={Images.logo} alt="logo" />
        </Box>
        <Typography className="content">You have reached your monthly word limit.</Typography>
        <Typography className="title">Enjoy a limitless writing experience with our Pro+ plan!</Typography>
      </DialogContent>
      <Divider className="!border-gray-500" />
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          size="small"
          variant="contained"
          className="fill-btn clr-primary"
          endIcon={<Icons.Badge className="!text-sm" />}
          onClick={handleNavigate}
        >Upgrade to Pro+ plan
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default ReachLimit