import { Cookies } from 'react-cookie';
const cookies = new Cookies();

export const BASEURL = process.env.REACT_APP_API_URL;
export const UPLOAD_CKEDITOR_URL = `${process.env.REACT_APP_API_URL}/editor/file/uploader`;

export function headers() {
    if (cookies.get('workspaceauth') !== undefined && cookies.get('workspaceauth') !== null) {
        return {
            'withCredentials': true,
            'credentials': 'include',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookies.get('workspaceauth')}`,
            'User-Authorization': `Bearer ${cookies.get('auth')}`,
        }
    } else {
        return {
            'withCredentials': true,
            'credentials': 'include',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${cookies.get('auth')}`,
            'User-Authorization': `Bearer ${cookies.get('auth')}`,
        }
    }
}

export function checktoken(err) {
    if (!err?.success && err?.redirect) {
        cookies.remove('workspaceauth', { path: '/' })
        window.location.reload(true)
    }
    if (err?.response !== undefined) {
        if (err.response?.data?.redirect === true) {
            localStorage.clear();
            cookies.remove('auth');
            window.location.assign(err.response.data.location);
        }
    }
}

export function hideLadingLoader() {
    const loader = document.querySelector(".landing-loader");
    if (loader !== null && loader !== undefined) {
        loader.remove();
    }
}