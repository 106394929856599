import { Nav, NavDropdown, Container, Dropdown } from "react-bootstrap"
import { IconButton, styled, Link, Button } from "@mui/material"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { Link as RLink, useNavigate } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import { removeUser } from "../../redux/auth.slice"
import LogoImg from "../../assets/images/logo.png"
import { useDispatch } from "react-redux"
import { FiUser } from "react-icons/fi"
import { Cookies } from "react-cookie"
import Icons from "../../assets/icons"

const Div = styled("div")(() => ({}))
const Image = styled("img")(() => ({}))

const menus = [
  { name: "features", path: "/features", external: false },
  { name: "pricing", path: "/pricing", external: false },
  { name: "affiliate", path: "/affiliate", external: false },
  { name: "community", path: "https://discord.gg/nJqmyz4dK7", external: true },
  { name: "blog", path: "/blog", external: false },
  { name: "roadmap", path: "https://alta.featurebase.app", external: true },
]

export default function Header() {
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cookie = cookies.get("auth")
  const [active, setActive] = useState(false)
  const user = JSON.parse(localStorage.getItem("authuser"))
  const { user: auth } = useSelector(state => state.authSlice)
  const workspace = useSelector(state => state.getWorkspace)

  const handleLogout = () => {
    cookies.remove("workspaceauth", { path: "/" })
    cookies.remove("auth", { path: "/" })
    localStorage.clear()
    dispatch(removeUser())
    navigate("/login")
    handleSidebar()
  }

  const handleHeader = () => {
    document.querySelector("#collapsibleNavbar").classList.toggle("show")
    document.querySelector(".navbar-toggler").classList.toggle("collapsed")
  }

  const handleSidebar = () => {
    setActive(!active)
  }

  useEffect(() => {
    document.querySelector(".navbar-toggler").classList.remove("collapsed")
    document.querySelector("#collapsibleNavbar").classList.remove("show")
  }, [navigate])
  return (
    <Fragment>
      <Div as="header" sx={{ display: { xs: "none", laptop: "block" } }}>
        <nav className="navbar navbar-expand-lg py-3">
          <Container>
            <a className="navbar-brand" href="/"><img src={LogoImg} alt="Logo" /></a>
            <IconButton
              size="small"
              onClick={handleHeader}
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              sx={{ display: { laptop: "none" } }}
              className="header-menu-btn navbar-toggler"
            ><Icons.FiMenu />
            </IconButton>
            <div className="collapse navbar-collapse justify-content-between" id="collapsibleNavbar">
              <Nav as="ul" className="navbar-nav">
                <Nav.Item as="li">
                  <Nav.Link className="hover-color" href="/features">features</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="hover-color" href="/pricing">pricing</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="hover-color" href="/affiliate">affiliate</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="hover-color" href="https://discord.gg/nJqmyz4dK7" target="_blank">community</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="hover-color" href="/blog">blog</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link className="hover-color" href="https://alta.featurebase.app">Roadmap</Nav.Link>
                </Nav.Item>
              </Nav>

              <Nav as="ul" className="navbar-nav">
                {(!cookie || user === undefined || user === null) ?
                  <Nav.Item as="li">
                    <Nav.Link href="/login" className="hover-color">Login</Nav.Link>
                  </Nav.Item> :
                  <NavDropdown as="li" title={<> <FiUser className="hover-color" /> <i className="bi bi-chevron-down hover-color" /> </>} id="navbarScrollingDropdown">
                    {(user?.login_type === "appsumo" || auth?.currentplan || Object.keys(workspace).length > 0) ?
                      <NavDropdown.Item href="/user/chat">Dashboard</NavDropdown.Item> :
                      <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                    }
                    <NavDropdown.Item href="/" onClick={(e) => { e.preventDefault(); handleLogout() }}>Logout</NavDropdown.Item>
                  </NavDropdown>
                }
                <Nav.Item as="li" className="ms-lg-4 ms-0">
                  <Nav.Link href="/signup" className="hover-color">Free Trial</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Container>
        </nav>
      </Div>

      <Div as="header" sx={{ py: 2, display: { xs: "block", laptop: "none" } }}>
        <Container>
          <Div sx={{ display: { xs: "block", laptop: "flex" }, alignItems: "center" }}>
            <Div sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Link
                to="/"
                component={RLink}
                sx={{ py: "5px", mr: "50px" }}
              >
                <Image
                  alt="logo"
                  src={LogoImg}
                  sx={{ width: "150px", objectFit: "contain" }}
                />
              </Link>
              <IconButton
                size="small"
                onClick={handleSidebar}
                className="header-menu-btn navbar-toggler"
                sx={{ display: { laptop: "none" }, ml: "auto" }}
              ><Icons.FiMenu />
              </IconButton>
            </Div>
          </Div>
        </Container>
      </Div>

      {active &&
        <Div
          sx={{
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "90",
            opacity: "0.2",
            bgcolor: "black",
            position: "fixed",
          }}
          onClick={handleSidebar}
        />
      }
      <Div
        as="aside"
        sx={{
          pb: 1,
          px: 2,
          top: "0",
          bottom: "0",
          zIndex: "100",
          width: "250px",
          height: "100vh",
          position: "fixed",
          flexDirection: "column",
          backgroundColor: "#f7f6fc",
          transitionDuration: "350ms",
          left: active ? "0" : "-100%",
          overflowY: "auto",
          display: { xs: "flex", laptop: "none" },
        }}
        className="custom-scroll"
      >
        <Div
          sx={{
            pt: 1,
            mb: 2,
            top: "0",
            display: "flex",
            bgcolor: "#f7f6fc",
            position: "sticky",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link
            to="/"
            component={RLink}
            sx={{ py: "5px", mr: "50px" }}
          >
            <Image
              alt="logo"
              src={LogoImg}
              sx={{ width: "120px", objectFit: "contain" }}
            />
          </Link>
          <IconButton
            size="small"
            onClick={handleSidebar}
            sx={{
              color: "white",
              bgcolor: "#7959a6 !important",
              display: { xs: "flex", laptop: "none" },
            }}
          ><Icons.Cross />
          </IconButton>
        </Div>

        <Div
          as="nav"
          sx={{
            gap: "12px",
            flexGrow: "1",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {menus?.map((menu, i) => (
            <RLink
              key={i}
              to={menu?.path}
              onClick={handleSidebar}
              className="nav-menu-link !pt-0"
              target={menu?.external ? "_blank" : ""}
            >{menu?.name}
            </RLink>
          ))
          }
        </Div>

        <Div
          sx={{
            gap: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(!cookie || user === undefined || user === null) ?
            <RLink
              to="/login"
              onClick={handleSidebar}
              className="nav-menu-link !pt-0"
            >login
            </RLink> :
            <Dropdown>
              <Dropdown.Toggle className="p-0 !bg-transparent !border-0 w-full">
                <Button
                  sx={{
                    width: "100%",
                    color: "#7959a6",
                    justifyContent: "space-between",
                    bgcolor: "transparent !important"
                  }}
                  endIcon={<i className="bi bi-chevron-down hover-color !text-[14px]" />}
                ><FiUser className="hover-color !text-[18px]" />
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100 !p-[4px] !rounded-[16px]">
                <Dropdown.Item as="div" className="p-0 !bg-transparent">
                  <Button
                    sx={{
                      py: "10px",
                      width: "100%",
                      fontSize: "16px",
                      color: "#374151",
                      bgcolor: "transparent",
                      borderRadius: "12px",
                      justifyContent: "start",
                      transitionDuration: "350ms",
                      textTransform: "capitalize",
                      boxShadow: "none !important",
                      ":hover": {
                        color: "#030712",
                        bgcolor: "#d1c9e9",
                      }
                    }}
                    variant="contained"
                    LinkComponent={RLink}
                    className="text-gray-700"
                    to={(user?.login_type === "appsumo" || auth?.currentplan || Object.keys(workspace).length > 0) ? "/user/chat" : "/dashboard"}
                  >dashboard
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item as="div" className="p-0 !bg-transparent">
                  <Button
                    sx={{
                      py: "10px",
                      width: "100%",
                      fontSize: "16px",
                      color: "#374151",
                      bgcolor: "transparent",
                      borderRadius: "12px",
                      justifyContent: "start",
                      transitionDuration: "350ms",
                      textTransform: "capitalize",
                      boxShadow: "none !important",
                      ":hover": {
                        color: "#030712",
                        bgcolor: "#d1c9e9",
                      }
                    }}
                    variant="contained"
                    onClick={handleLogout}
                    className="text-gray-700"
                  >logout
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
          <RLink
            to="/signup"
            onClick={handleSidebar}
            className="nav-menu-link !pt-0"
          >free trial
          </RLink>
        </Div>
      </Div>
    </Fragment >
  )
}
