const intialState = {};
const getAuthUser = (state = intialState, action) => {
    switch(action.type){
        case "AUTHUSER":
            return action.data;
        default:
            return state;
    }
}

export default getAuthUser;