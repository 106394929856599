// import LanguageDetector from 'i18next-browser-languagedetector';
import { PersistGate } from 'redux-persist/integration/react'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import { persistStore } from 'redux-persist';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import React from 'react';
import "./assets/css/global.css"
import 'simplebar-react/dist/simplebar.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

// import i18n from "i18next";
// import HttpApi from 'i18next-http-backend';
// import { initReactI18next } from "react-i18next";
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material';
import theme from './utils/muiTheme';
import ContextProvider from './components/frontend/ContextProvider';

// i18n.use(initReactI18next).use(LanguageDetector).use(HttpApi).init({
// 	suportedLngs: ['en', 'fr'],
// 	// lng: document.querySelector('html').lang,
// 	fallbackLng: "en",
// 	detection: {
// 		order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
// 		caches: ['cookie'],
// 	},
// 	backend: {
// 		loadPath: '/assets/locales/{{lng}}/translation.json',
// 	},
// 	interpolation: {
// 		escapeValue: false
// 	},
// 	react: { useSuspense: false }
// })

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store)
root.render(
	<ThemeProvider theme={theme}>
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID_LIVE}>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<HelmetProvider>
						<ContextProvider>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</ContextProvider>
					</HelmetProvider>
				</PersistGate>
			</Provider>
		</GoogleOAuthProvider>
	</ThemeProvider>
);
reportWebVitals();
