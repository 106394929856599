const intialState = {};
const getWorkspace = (state = intialState, action) => {
    switch(action.type){
        case "WORKSPACE":
            return action.data;
        default:
            return state;
    }
}

export default getWorkspace;