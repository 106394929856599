import { headers, checktoken, hideLadingLoader } from "../../helper"
import { Table, Card, Alert, Tab, Nav } from "react-bootstrap"
import Loader from "../../components/frontend/TextLoader"
import Toaster from "../../components/frontend/Toaster"
import { useState, Fragment, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { FiMenu } from "react-icons/fi"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import axios from "axios"

const History = () => {
	const [prompt, setPrompt] = useState([]);
	const [handleSidebar] = useOutletContext();
	const [spinner, setSpinner] = useState(true);
	const [templates, setTemplates] = useState([]);

	const getPromptHistory = () => {
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/prompt/history`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setPrompt(res.data.data)
			}

			if (res.data.success === false) {
				setPrompt(res.data.data)
				toast.error(res.data.error)
			}
			setSpinner(false);
		}).catch((err) => { setSpinner(false); checktoken(err); })
	}

	const getTemplateHistory = () => {
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/template/content/history`, {
			headers: headers()
		}).then((res) => {
			hideLadingLoader();
			if (res.data.success === true) {
				setTemplates(res.data.data)
			}

			if (res.data.success === false) {
				setTemplates(res.data.data)
				toast.error(res.data.error)
			}
			setSpinner(false);
		}).catch((err) => { setSpinner(false); hideLadingLoader(); checktoken(err); })
	}

	useEffect(() => {
		setTimeout(() => {
			hideLadingLoader()
		}, 500)

		getPromptHistory();
	}, [])

	return (
		<Fragment>
			<Helmet>
				<title>History</title>
				<meta property="og:title" content={"History"} />
			</Helmet>
			{spinner === true && <Loader />}
			<Toaster />
			<div className="user-dashboard-header d-flex align-items-center justify-content-between border-bottom">
				<h5 className="m-0 page-header">Generation History</h5>
				<div className="d-flex justify-content-between">
					<button className="d-inline-block text-center text-decoration-none page-header-btn-dark btn menu-btn me-2" onClick={handleSidebar}><FiMenu /></button>
				</div>
			</div>
			<div className="d-block brand-form history-div">
				<Card className="shadow-lg border-0">
					<Card.Header className="p-3 d-flex align-items-center justify-content-between">
						<Card.Title className="m-0 text-capitalize">Generation History list</Card.Title>
					</Card.Header>
					<Card.Body>
						<Tab.Container id="center-tabs-example" defaultActiveKey="prompt">
							<Nav variant="pills" className="flex-row mb-3">
								<Nav.Item>
									<Nav.Link eventKey="prompt" onClick={(e) => { e.preventDefault(); getPromptHistory() }} className="fill-btn clr-primary">Prompts</Nav.Link>
								</Nav.Item>

								<Nav.Item>
									<Nav.Link eventKey="templates" onClick={(e) => { e.preventDefault(); getTemplateHistory() }} className="fill-btn clr-dark">Templates</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey="prompt">
									<div className="table-responsive">
										{prompt?.length > 0 ?
											<Table striped bordered hover>
												<thead>
													<tr className="text-capitalize">
														<th>Sr. No</th>
														<th>prompt</th>
														<th>prompt response</th>
													</tr>
												</thead>
												<tbody>
													{
														prompt.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{item.prompt?.length > 50 ? item.prompt?.substr(0, 50) + "..." : item.prompt}</td>
																	<td>{item.promptresponse?.length > 50 ? item.promptresponse?.substr(0, 50) + "..." : item.promptresponse}</td>
																</tr>
															)
														})
													}
												</tbody>
											</Table> :
											<Alert variant="warning">No record found!</Alert>
										}
									</div>
								</Tab.Pane>
								<Tab.Pane eventKey="templates">
									<div className="table-responsive">
										{
											templates.length > 0 ?
												<Table striped bordered hover>
													<thead>
														<tr className="text-capitalize">
															<th>Sr. No</th>
															<th>template name</th>
															<th>content</th>
														</tr>
													</thead>
													<tbody>
														{
															templates.map((item, index) => {
																return (
																	<tr key={index}>
																		<td>{index + 1}</td>
																		<td className="fw-bold">{item.templatedetails[0]?.name}</td>
																		<td>{item.content?.length > 50 ? item.content?.substr(0, 50) + "..." : item.content}</td>
																	</tr>
																)
															})
														}
													</tbody>
												</Table> :
												<Alert variant="warning">No record found!</Alert>
										}
									</div>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Card.Body>
				</Card>
			</div>
		</Fragment>
	)
}

export default History;