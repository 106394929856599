import { Box, Button, DialogActions, DialogContent, Divider, IconButton, Paper } from "@mui/material"
import { hideDialog } from "../../../redux/dialog.slice"
import Images from "../../../assets/images"
import { useDispatch } from "react-redux"
import Icons from "../../../assets/icons"
import CustomInput from "../CustomInput"


function ImportUrl() {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="ImportUrl">
      <DialogContent className="relative">
        <Box className=" inline-block absolute top-[10px] right-[10px]">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Box className="text-center">
          <img className="h-[50px] w-[150px] object-contain mb-4 dialog-img" src={Images.logo} alt="logo" />
        </Box>
        <CustomInput
          id="url"
          label="URL"
          type="text"
          placeholder="https://"
        />
      </DialogContent>
      <Divider className="!border-gray-500" />
      <DialogActions sx={{ px: 3 }}>
        <Button
          size="small"
          variant="outlined"
          className="border-btn clr-dark"
          onClick={handleClose}
        >Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          className="fill-btn clr-primary"
        // onClick={handleNavigate}
        >Import URL
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default ImportUrl