const intialState = {};
const getAppSumoInfo = (state = intialState, action) => {
    switch(action.type){
        case "APPSUMO":
            return action.data;
        default:
            return state;
    }
}

export default getAppSumoInfo;