import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASEURL, headers } from "../helper";
import { Cookies } from "react-cookie";
import axios from "axios";
import { handleSaveChatReq } from "./chat.slice";

const cookies = new Cookies()

export const handleFileUpload = createAsyncThunk("handleFileUpload", async (files, { rejectWithValue }) => {
	const formData = new FormData();
	const projectId = localStorage.getItem("newProjId")
	formData.append("projectId", projectId);
	for (let i = 0; i < files.length; i++) {
		formData.append(`file`, files[i]);
	}
	try {
		const response = await axios.post(`${BASEURL}/prompt/file-upload`, formData, {
			headers: {
				'Content-Type': "multipart/form-data",
				'Authorization': `Bearer ${cookies.get('auth')}`,
			}
		})
		return response.data?.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err.response?.data || err.message);
	}
})

export const handleFileDelete = createAsyncThunk("handleFileDelete", (id) => {
	const response = axios.delete(`${BASEURL}/prompt/delete-file/${id}`, {
		headers: headers()
	}).then((res) => {
		if (res.data.success) {
			return id
		}
	}).catch((err) => {
		console.log(err);
		return err
	})

	return response;
})

const initialState = {
	files: []
}
const FileSlice = createSlice({
	name: "FileSlice",
	initialState,
	reducers: {
		emptyFiles: (state) => ({
			...state,
			files: [],
		})
	},
	extraReducers: (builder) => {
		// Upload File
		builder.addCase(handleFileUpload.pending, (state, { meta }) => {
			const files = []
			Object.keys(meta.arg).forEach((_, i) => {
				files.push({
					id: meta?.requestId,
					name: meta.arg[i].name,
					status: meta.requestStatus,
					type: meta.arg[i].type.split("/")[1]
				})
			})
			return {
				...state,
				files: [...files, ...state.files],
			}
		})
		builder.addCase(handleFileUpload.fulfilled, (state, { meta, payload }) => {
			if (!Array.isArray(payload)) return state
			handleSaveChatReq(JSON.stringify(payload))
			const files = state?.files?.filter(({ id }) => meta?.requestId !== id)
			return {
				...state,
				files: [...payload, ...files],
			}
		})
		builder.addCase(handleFileUpload.rejected, (state, { meta }) => {
			const files = state?.files?.filter(({ id }) => meta?.requestId !== id)
			return {
				...state,
				files: [...files],
			}
		})

		// Delete File
		builder.addCase(handleFileDelete.fulfilled, (state, { payload }) => {
			const files = state?.files?.filter(({ _id }) => _id !== payload)
			return {
				...state,
				files: [...files],
			}
		})

	}
})

export const { emptyFiles } = FileSlice.actions;
export default FileSlice.reducer;