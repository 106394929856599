import Loader from '../../../components/frontend/TextLoader'
import { Table, Row, Col, Button } from 'react-bootstrap'
import { useState, Fragment, useEffect } from "react"
import { ToastContainer, toast } from 'react-toastify'
import { FiShare2 } from "react-icons/fi"
import { Cookies } from 'react-cookie'
import axios from 'axios'

const ReferralPage = () => {
    const cookies = new Cookies();
    const [userToken, setUserToken] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [referralData, setReferralData] = useState([]);

    const getUserDetails = (e) => {
        axios.get(`${process.env.REACT_APP_API_URL}/profile-edit/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('auth')}`
            }
        }).then((res) => {
            if (res.data.success === true) {
                setUserToken(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const referralList = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/user/referral-list`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('auth')}`
            }
        }).then((res) => {
            console.log(res.data.data);
            if (res.data.success === true) {
                setReferralData(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleCopy = (content) => {
        (content) ? toast.success('Affiliate URL copied to clipboard !') : toast.error('Please try again !');
    };

    useEffect(() => {
        referralList();
        getUserDetails();
        setTimeout(() => {
            setSpinner(false);
        }, 100);

        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            {spinner === true ? <Loader /> : ''}
            <ToastContainer autoClose={2000} theme="dark" closeOnClick newestOnTop={true} />
            <Row >
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="user-dashboard-header d-flex align-items-center justify-content-between border-bottom">
                        <h5 className='m-0 page-header'>Subscription</h5>
                    </div>
                    <div className="container my-5 mx-5">
                        <div className="row">
                            <div className="col-sm-12 m-auto">
                                <div className="row">
                                    <div className="col-sm-8 m-auto">
                                        <h6>Referral Code : <span className="text-info">{userToken.referral_id}</span></h6>
                                        <p>Use my referral code and get 20% Every time user pays to alta.</p>
                                    </div>

                                    <div className="col-sm-4 m-auto">
                                        <Button variant="info text-white mb-2" onClick={() => handleCopy(userToken.referral_id)}>Share Users <FiShare2 /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="my-3">Referral List</h3>
                        <div className="row">
                            <div className="col-sm-12 m-auto my-5">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referralData && referralData.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.phone}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ReferralPage;