import UserPaymentDetails from '../pages/User/UserDetails/UserPaymentDetails';
import CreateTemplate from '../pages/User/templates/CreateTemplate';
import Withdrawfunds from '../pages/User/UserDetails/Withdrawfunds';
import SingleTemplate from '../pages/User/templates/SingleTemplate';
import SavedTemplates from '../pages/User/templates/SavedTemplates';
import Subscription from '../pages/User/UserDetails/Subscription';
import ReferralPage from '../pages/User/UserDetails/ReferralPage';
import SharedUser from '../pages/User/UserDetails/SharedUser';
import Profile from '../pages/User/UserDetails/Profile';
import Favorite from '../pages/User/templates/Favorite';
import BrandEdit from '../pages/User/Brand/BrandEdit';
import BrandNew from '../pages/User/Brand/BrandNew';
import Brand from '../pages/User/Brand/Brand';
import Bookmark from '../pages/User/Bookmark';
import Project from '../pages/User/Project';
import History from '../pages/User/History';
import Chat from '../pages/User/Chat';
import AltaDetect from '../pages/User/AltaDetect';

const UserRoute = [
    { path: '/user/chat', name: 'Chat', component: <Chat /> },
    { path: '/user/brand', name: 'Brand', component: <Brand /> },
    { path: '/user/history', name: 'History', component: <History /> },
    { path: '/user/profile', name: 'Profile', component: <Profile /> },
    { path: '/user/projects', name: 'Project', component: <Project /> },
    { path: '/user/chat/:useprompt', name: 'Chat', component: <Chat /> },
    { path: '/user/bookmark', name: 'Bookmark', component: <Bookmark /> },
    { path: '/user/brand-new', name: 'BrandNew', component: <BrandNew /> },
    { path: '/user/alta-detect', name: 'AltaDetect', component: <AltaDetect /> },
    { path: '/user/referral', name: 'ReferralPage', component: <ReferralPage /> },
    { path: '/user/brand-update/:id', name: 'BrandEdit', component: <BrandEdit /> },
    { path: '/user/shared/user', name: 'SharedUser', component: <SharedUser /> },
    { path: '/user/subscription', name: 'Subscription', component: <Subscription /> },
    { path: '/user/create-template', name: 'CreateTemplate', component: <CreateTemplate /> },
    { path: '/user/payment-list', name: 'UserPaymentDetails', component: <UserPaymentDetails /> },
    { path: '/user/favorite/template', name: 'Favorite', component: <Favorite /> },
    { path: '/user/project/:projectid/template/:templateid', name: 'SingleTemplate', component: <SingleTemplate /> },
    { path: '/user/withdraw-funds', name: 'Withdrawfunds', component: <Withdrawfunds /> },
    { path: '/user/saved/template', name: 'SavedTemplates', component: <SavedTemplates /> },
];

export default UserRoute;

