import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie';

const ProtectedRoute = (props) => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const cookie = cookies.get('auth');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const authUser = localStorage.getItem('authuser');
        if (authUser === undefined || authUser === null || authUser === 'undefined' || cookie === undefined || cookie === null) {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);
    return (
        <Fragment>
            { isLoggedIn ? props.children : null }
        </Fragment>
    );
}
export default ProtectedRoute;