import { FiCpu, FiUsers, FiList, FiMessageSquare, FiLayout, FiDollarSign, FiTool } from "react-icons/fi";
import Logo from '../../assets/images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { Fragment } from 'react';

const Sidebar = () => {
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    const isPath = splitLocation[1] + "/" + splitLocation[2];

    return (
        <Fragment>
            <Nav.Item className="logo border-bottom">
                <Link to="/admin">
                    <img src={Logo} alt="img" className="log-image" width="100px" />
                </Link>
            </Nav.Item>
            <Nav className="side-menu flex-column py-2">
                <Nav.Item>
                    <Link to="/admin" className={`${splitLocation[1] === 'admin' && splitLocation[2] === undefined && 'active'}`}>dashboard <FiCpu /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/users" className={`${isPath === 'admin/users' && 'active'}`}>users <FiUsers /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/prompts-categories" className={`${isPath === 'admin/prompts-categories' && 'active'}`}>prompt categories <FiList /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/prompt/list" className={`${isPath === 'admin/prompt/list' && 'active'}`}>prompt <FiMessageSquare /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/templates-categories" className={`${isPath === 'admin/templates-categories' && 'active'}`}>templates categories <FiList /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/templates" className={`${isPath === 'admin/templates' && 'active'}`}>templates <FiLayout /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/tones" className={`${isPath === 'admin/tones' && 'active'}`}>Tone <FiLayout /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/list-blog-categories" className={`${isPath === 'admin/list-blog-categories' && 'active'}`}>Blog categories <FiLayout /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/list-blog" className={`${isPath === 'admin/list-blog' && 'active'}`}>Blog<FiLayout /></Link>
                </Nav.Item>
                <Nav.Item>
                    <Link to="/admin/list-transactions" className={`${isPath === 'admin/list-transactions' && 'active'}`}>Transactions<FiDollarSign /></Link>
                </Nav.Item>
                {/* <Nav.Item>
                    <Link to="#">Withdraw Request<FiGift/></Link>
                </Nav.Item> */}
                <Nav.Item>
                    <Link to="/admin/setting" className={`${isPath === 'admin/setting' && 'active'}`}>Settings<FiTool /></Link>
                </Nav.Item>
            </Nav>
        </Fragment>
    )
}

export default Sidebar;