import { Outlet, useNavigate } from 'react-router-dom'
import { Fragment, useEffect } from 'react'
import { headers } from "../../helper"
import { Cookies } from 'react-cookie'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'
import axios from "axios"

const AdminLayout = () => {
    const navigate = useNavigate()
    const cookies = new Cookies();
    const cookie = cookies.get('auth');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/admin/verification`, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                if (res.data?.data === 'user' && res.data?.data !== 'admin' && cookie !== null && cookie !== undefined) {
                    return navigate('/user/chat');
                }
                if (res.data?.data === 'admin' && res.data?.data !== 'user' && cookie !== null && cookie !== undefined) {
                    return true;
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [cookie, navigate]);

    return (
        <Fragment>
            <div className="page-wrapper">
                <aside className='sidebar-div'>
                    <Sidebar />
                </aside>
                <main className='admin-layout'>
                    <Header />
                    <div className="page-content">
                        <Outlet />
                    </div>
                    <Footer />
                </main>
            </div>
        </Fragment>
    )
}

export default AdminLayout;