import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { FiPlus, FiTrash2, FiEdit2 } from "react-icons/fi";
import { Card, Table, Pagination } from 'react-bootstrap';
import { useEffect, useState, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const AllPrompt = () => {
    const location = useLocation()
    const [page, setPage] = useState(1)
    const [prompts, setPrompts] = useState([])
    const [spinner, setSpinner] = useState(true)
    const [pagination, setPagination] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)

    const promptListAdmin = (page) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/prompt/list?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setPrompts(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            }
            setSpinner(false);
            hideLadingLoader();
        }).catch((err) => {
            setSpinner(false);
            hideLadingLoader();
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        promptListAdmin(page)
        if (location.state !== null) {
            if (location.state === 'Updated') {
                toast.success('Prompt updated successfully');
                location.state = null;
            }
        }
    }, [])

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Prompts <em style={{ fontSize: '12px' }}>total records: {totalRecords}</em></Card.Title>
                <Link to="/admin/add-prompt" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiPlus /> add prompt</Link>
            </Card.Header>
            <Card.Body>
                {
                    prompts &&
                    <Fragment>
                        <div className="table-responsive">
                            <Table striped hover bordered>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Custom Name</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        prompts && prompts.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {page > 1 ?
                                                            (10 * page) + (index - 9) : index + 1
                                                        }
                                                    </td>
                                                    <td>{item.customname?.length > 20 ? item.customname?.slice(0, 30) + '...' : item.customname}</td>
                                                    <td>{item?.prompt?.length > 50 ? item?.prompt?.slice(0, 50) + '...' : item.prompt}</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <Link to={`/admin/edit/prompt/${item._id}`} className="btn btn-primary btn-sm me-2"><FiEdit2 /></Link>
                                                            <button type='button' className='btn btn-sm btn-danger'><FiTrash2 /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-block">
                            {
                                pagination.length > 1 &&
                                <Pagination className='mt-3 justify-content-center'>
                                    {
                                        pagination.map((item, index) => {
                                            return (
                                                <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); promptListAdmin(item) }}>{item}</Pagination.Item>)
                                        })
                                    }
                                </Pagination>
                            }
                        </div>
                    </Fragment>
                }
            </Card.Body>
        </Card>
    )
}

export default AllPrompt;