import Images from '../../assets/images'

const TextLoader = () => {
    return (
        <div className="position-fixed top-0 bottom-0 start-0 end-0 d-grid align-items-center justify-content-center loadar-div" style={{ zIndex: 9999 }}>
            <img
                alt="favicone"
                src={Images.favicone}
                className="zoom-in-zoom-out"
                style={{ width: '100px' }}
            />
        </div>
    )
}

export default TextLoader;