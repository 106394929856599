import CanvasJSReact from '@canvasjs/react-charts';
import {React} from 'react';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function UsersChart(props) {
  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    title: {
      text: "Monthly Users"
    },
    axisY: {
      includeZero: true
    },
    data: [{
      type: "column",
      indexLabelFontColor: "#5A5757",
      indexLabelPlacement: "outside",
      dataPoints:props.usersData
    }]
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
}

export default UsersChart;
