import { Link, useParams, useLocation } from 'react-router-dom';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { FiList, FiTrash2, FiEdit2 } from "react-icons/fi";
import { Card, Table, Pagination } from 'react-bootstrap';
import { useEffect, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const Prompts = () => {
    const { id } = useParams()
    const location = useLocation()
    const [page, setPage] = useState(1)
    const [catid, setCatid] = useState('')
    const [prompts, setPrompts] = useState([])
    const [spinner, setSpinner] = useState(true)
    const [pagination, setPagination] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)

    const categoryPrompt = (id, page) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/category/${id}/prompt?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setPrompts(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    const deleteCategoryPrompt = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/delete/category/prompt/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.error(res.data.message);
                categoryPrompt(catid, 1);
            }
        }).catch((err) => {
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error[0]);
                }
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
        setSpinner(true);
        if (location.state !== null) {
            if (location.state === 'Updated') {
                toast.success('Prompt updated successfully');
                location.state = null;
            }
        }
        if (id !== null && id !== undefined && id !== '') {
            setCatid(id);
        }
        categoryPrompt(id, 1);
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Prompts <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                <Link to="/admin/prompts-categories" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> category</Link>
            </Card.Header>
            <Card.Body>
                {
                    prompts &&
                    <Fragment>
                        <div className='table-responsive'>
                            <Table striped hover bordered>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Name</th>
                                        <th>Prompt</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        prompts && prompts.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {page > 1 ?
                                                            (10 * page) + (index - 9) : index + 1
                                                        }
                                                    </td>
                                                    <td>{item.customname?.length > 20 ? item.customname?.slice(0, 30) + '...' : item.customname}</td>
                                                    <td>{item?.prompt?.length > 50 ? item?.prompt?.slice(0, 50) + '...' : item.prompt}</td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <Link className='btn btn-primary btn-sm me-1' to={`/admin/edit/prompt/${item._id}`}><FiEdit2 /></Link>
                                                            <button onClick={() => { deleteCategoryPrompt(item._id) }} type='button' className='btn btn-sm btn-danger'><FiTrash2 /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-block">
                            {
                                pagination.length > 1 &&
                                <Pagination className='mt-3 justify-content-center'>
                                    {
                                        pagination.map((item, index) => {
                                            return (
                                                <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); categoryPrompt(id, item) }}>{item}</Pagination.Item>)
                                        })
                                    }
                                </Pagination>
                            }
                        </div>
                    </Fragment>
                }
            </Card.Body>
        </Card>
    )
}

export default Prompts;