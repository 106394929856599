import { Collapse, ListItemIcon, ListItemText, MenuItem, styled } from "@mui/material"
import styles from "../../assets/css/components/CommonComponents.module.css"
import { useDispatch, useSelector } from "react-redux"
import { showDialog } from "../../redux/dialog.slice"
import Icons, { MuiIcons } from "../../assets/icons"
import { useState } from "react"

const Div = styled("div")(() => ({}))

function AltacopyDropdown() {
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const { activeWorkspace } = useSelector(state => state?.workspaceSlice)
  const { user } = useSelector(state => state?.authSlice)
  const { totalWords } = useSelector(state => state?.chatSlice)

  const toggleActive = () => {
    setActive(!active)
  }

  const handleTeplates = () => {
    if (Object?.keys(activeWorkspace)?.length > 0) {
      return dispatch(showDialog("AllTemplates"))
    }
    if (user?.appsumocode === null && user?.login_type !== "appsumo") {
      if (user?.currentplan?.packagename === "SOLO" &&
        totalWords >= 50000) {
        return dispatch(showDialog("ReachLimit"))
      } else {
        return dispatch(showDialog("AllTemplates"))
      }
    } else {
      if (user?.appsumouser?.type === "Solo" && totalWords >= 30000) {
        return dispatch(showDialog("ReachLimit"))
      } else {
        return dispatch(showDialog("AllTemplates"))
      }
    }
  }

  return (
    <Div
      sx={{ px: 2 }}
      className={`relative tour-step-8 ${styles["side-menu"]}`}
    >
      <MenuItem
        sx={{
          p: 1,
          height: "36px",
          ":hover": {
            "div.MuiChip-root": { bgcolor: "white !important" },
            "span.MuiChip-label": { color: "#7959a6 !important" }
          }
        }}
        onClick={toggleActive}
        className={`${styles["sidebar-menu-btn"]}`}
      >
        <ListItemIcon
          sx={{
            mr: 2,
            color: "#030712",
            minWidth: "auto !important",
          }}
        ><Icons.File />
        </ListItemIcon>
        <ListItemText
          sx={{
            "span": { fontFamily: "Lato" },
            ".alta-text": { color: "#7959a6" },
            "& span:not(span.alta-text)": { fontSize: "14px" },
          }}
        ><span className="alta-text">Alta</span>Copy
        </ListItemText>
        <ListItemIcon
          sx={{
            minWidth: "auto !important",
            "svg": {
              color: "black",
              transition: "all ease 300ms",
              transform: active ? "rotate(-180deg)" : "",
            }
          }}
        >
          <MuiIcons.DownArrow />
        </ListItemIcon>
      </MenuItem>

      <Collapse in={active}>
        <MenuItem
          sx={{
            p: 1,
            height: "36px",
            ":hover": {
              "div.MuiChip-root": { bgcolor: "white !important" },
              "span.MuiChip-label": { color: "#7959a6 !important" }
            }
          }}
          onClick={handleTeplates}
          className={`${styles["sidebar-menu-btn"]}`}
        >
          <ListItemIcon
            sx={{
              mr: 2,
              color: "#030712",
              minWidth: "auto !important",
            }}
          ><Icons.FiFile />
          </ListItemIcon>
          <ListItemText
            sx={{
              "span": { fontFamily: "Lato" },
              ".alta-text": { color: "#7959a6" },
              "& span:not(span.alta-text)": { fontSize: "14px" },
            }}
          >Templates
          </ListItemText>
        </MenuItem>
      </Collapse>
    </Div>
  )
}

export default AltacopyDropdown