import { Chip, ListItemIcon, ListItemText, MenuItem, styled } from "@mui/material"
import styles from "../../assets/css/components/CommonComponents.module.css"

const Div = styled("div")(() => ({}))

function SideMenu({ menu, active, proBadge, handleNavigate, addClass }) {

  return (
    <Div
      sx={{ px: 2 }}
      className={`relative ${addClass || ""} ${styles["side-menu"]} ${active ? styles["active"] : ""}`}
    >
      <MenuItem
        sx={{
          p: 1,
          height: "36px",
          ":hover": {
            "div.MuiChip-root": { bgcolor: "white !important" },
            "span.MuiChip-label": { color: "#7959a6 !important" }
          }
        }}
        onClick={handleNavigate}
        className={`${styles["sidebar-menu-btn"]}`}
      >
        <ListItemIcon
          sx={{
            mr: 2,
            color: "#030712",
            minWidth: "auto !important",
          }}
        >{menu?.startIcon}
        </ListItemIcon>
        <ListItemText
          sx={{
            "span": { fontFamily: "Lato" },
            ".alta-text": { color: "#7959a6" },
            "& span:not(span.alta-text)": { fontSize: "14px" },
          }}
        >{menu?.name}
        </ListItemText>
        {proBadge &&
          <ListItemIcon>
            <Chip
              label="Pro"
              sx={{ "span": { px: "6px", py: "2px" } }}
              className={`${styles["pro-badge"]} ms-auto`}
            />
          </ListItemIcon>
        }
      </MenuItem>
    </Div>
  )
}

export default SideMenu