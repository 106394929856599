import { Outlet } from "react-router-dom"
import ScrollToTop from "./ScrollToTop";
import { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
    return (
        <Fragment>
            <div>
                <ScrollToTop />
                <Header />
                <main>
                    <Outlet />
                </main>
                <Footer />
            </div>
        </Fragment>
    )
}

export default Layout;