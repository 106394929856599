import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { hideDialog } from "./dialog.slice"
import { checktoken, headers } from "../helper"
import axios from "axios"
import { toast } from "react-toastify"
import { Cookies } from "react-cookie"

const cookies = new Cookies()

// Get all projects request
export const getAllProjectsReq = createAsyncThunk("createProjectReq", ({ navigate }, { rejectWithValue }) => {
  try {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/project/list`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      checktoken(err)
      if (err?.response?.data?.error?.name === "TokenExpiredError") {
        toast.error(err?.response?.data?.error?.message)
        cookies.remove("auth", { path: "/" })
        localStorage.clear()
        navigate("/login")
      } else {
        toast.error(err.response?.data?.error)
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Create project request
export const createProjectReq = createAsyncThunk("createProjectReq", ({ name, templateId, navigate }, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${process.env.REACT_APP_API_URL}/project/create`, { name }, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(hideDialog())
        localStorage.removeItem('newProjId');
        localStorage.setItem('newProjId', res?.data?.data?._id);
        navigate(`user/project/${res?.data?.data?._id}/template/${templateId}`)
        return res?.data?.data?._id
      }
    }).catch((err) => {
      checktoken(err)
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Delete single project request
export const deleteSingleProjectReq = createAsyncThunk("createProjectReq", (id, { rejectWithValue }) => {
  try {
    const response = axios.delete(`${process.env.REACT_APP_API_URL}/project/delete/${id}`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        toast.success(res?.data?.message)
        return res?.data
      } else {
        toast.error(res?.data?.error)
      }
    }).catch((err) => {
      checktoken(err)
      if (!err?.response?.data?.success) {
        toast.error(err.response.data.error)
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Delete multiple projects request
export const deleteMultipleProjectReq = createAsyncThunk("createProjectReq", (ids, { rejectWithValue }) => {
  try {
    const response = axios.delete(`${process.env.REACT_APP_API_URL}/seleced-project-delete/${ids}`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        toast.success(res?.data?.message)
        return res.data
      } else if (!res?.data?.success) {
        toast.error(res?.data?.error)
      }
    }).catch((err) => {
      checktoken(err)
      if (!err?.response?.data?.success) {
        toast.error(err?.response?.data?.error)
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Rename project request
export const renameProjectReq = createAsyncThunk("createProjectReq", ({ id, name }, { rejectWithValue }) => {
  try {
    const response = axios.put(`${process.env.REACT_APP_API_URL}/project/update/${id}`, { name: name.toLowerCase() }, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        toast.success(res?.data?.message)
      }
      return res.data
    }).catch((err) => {
      checktoken(err)
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Filter project list request
export const filterProjectReq = createAsyncThunk("createProjectReq", (text, { rejectWithValue }) => {
  try {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/project/list?filter=${text}`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      checktoken(err)
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Search projects request
export const searchProjects = createAsyncThunk("createProjectReq", (value, { rejectWithValue }) => {
  try {
    const response = axios.get(`${process.env.REACT_APP_API_URL}/search/project?text=${value}`, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      checktoken(err)
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  loading: false,
  activeProjectId: null,
}
const project = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create project request
    builder.addCase(createProjectReq.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createProjectReq.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(createProjectReq.rejected, (state) => {
      state.loading = false
    })

    // Logout
    builder.addCase("LOGOUT", () => initialState)
  }
})

export default project.reducer