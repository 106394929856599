import { Form, Card, Table, Pagination, Button } from 'react-bootstrap';
import { headers, checktoken, hideLadingLoader } from '../../../helper';
import Loader from "../../../components/frontend/TextLoader";
import Toaster from '../../../components/frontend/Toaster';
import { FiPlus, FiEdit, FiTrash } from "react-icons/fi";
import { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const TemplatesCategory = () => {
    const [page, setPage] = useState(1);
    const [spinner, setSpinner] = useState(true);
    const [pagination, setPagination] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const categoryList = () => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/template/category/list`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            checktoken(err);
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    const handleSearch = (searchQuery) => {
        axios.post(`${process.env.REACT_APP_API_URL}/category/template/search`, { name: searchQuery }, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
            } else {
                setCategories([]);
                toast.error(res.data.error);
            }
        }).catch((err) => {
            checktoken(err);
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    const deleteTemplateCategory = (id) => {
        setSpinner(true);
        axios.delete(`${process.env.REACT_APP_API_URL}/admin/template/category/delete/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.error(res.data.message);
                categoryList();
            }
        }).catch((err) => {
            setSpinner(false);
            checktoken(err);
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
        categoryList();
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">category <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                <div className="d-flex">
                    <Form.Control style={{ maxWidth: '200px' }} type="search" placeholder="Search" className="me-2 search-form" aria-label="Search" name="searchQuery" value={searchQuery} onKeyUp={() => { handleSearch(searchQuery) }} onChange={(e) => { setSearchQuery(e.target.value); }} />
                    <Link to="/admin/add-template-categories" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiPlus /> category</Link>
                </div>
            </Card.Header>
            <Card.Body>
                {(categories.length > 0) &&
                    <Fragment>
                        <div className="table-responsive">
                            <Table striped hover bordered>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categories && categories.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{page > 1 ?
                                                        (10 * page) + (index - 9) : index + 1
                                                    }</td>
                                                    <td className='text-capitalize'>{item.name}</td>
                                                    <td className='text-capitalize'>
                                                        <Link className='btn btn-sm btn-primary me-1' to={`/admin/template/category/edit/${item._id}`}><FiEdit /></Link>
                                                        <Button onClick={() => { deleteTemplateCategory(item._id) }} variant='danger btn-sm'><FiTrash /></Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-block">
                            {
                                pagination.length > 1 &&
                                <Pagination className='mt-3 justify-content-center'>
                                    {
                                        pagination.map((item, index) => {
                                            return (
                                                <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); categoryList(item) }}>{item}</Pagination.Item>)
                                        })
                                    }
                                </Pagination>
                            }
                        </div>

                    </Fragment>
                }
            </Card.Body>
        </Card>
    )
}

export default TemplatesCategory;