import { getParagraphs } from "../utils/helperFun"
import { BASEURL, headers } from "../helper"
import { hideDialog } from "./dialog.slice"
import axios from "axios"

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const detectContentReq = createAsyncThunk("readFileTextReq", async (text, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASEURL}/alta-detect`, { text }, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.result
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const readFileTextReq = createAsyncThunk("readFileTextReq", (file, { _, dispatch }) => {
  try {
    const ext = file?.name.split('.').pop()
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        const content = e.target.result
        if (ext === "doc" || ext === "docx") {
          const paragraphs = getParagraphs(content)
          setTimeout(() => {
            resolve(paragraphs)
          }, 1000)
          setTimeout(() => {
            dispatch(hideDialog())
          }, 1100)
        } else {
          setTimeout(() => {
            resolve(content)
          }, 1000)
          setTimeout(() => {
            dispatch(hideDialog())
          }, 1100)
        }
      }

      reader.onerror = (err) => reject(err)

      if (ext === "doc" || ext === "docx") {
        reader.readAsBinaryString(file)
      } else {
        reader.readAsText(file)
      }
    })
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  loading: false,
}
const altaDetect = createSlice({
  name: "altaDetect",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    // Loading
    builder.addCase(readFileTextReq.pending, (state) => {
      state.loading = true
    })
    builder.addCase(readFileTextReq.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(readFileTextReq.rejected, (state) => {
      state.loading = false
    })

    // Logout
    builder.addCase("LOGOUT", () => initialState)
  }
})

export default altaDetect.reducer