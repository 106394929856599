import { Card, Form, Row, Col, Button} from 'react-bootstrap';
import Loader from '../../../components/frontend/TextLoader';
import Toaster from '../../../components/frontend/Toaster';
import { Link,useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {useEffect, useState } from 'react';
import { FiList } from "react-icons/fi";
import { toast } from 'react-toastify';
import axios from 'axios';

const UserEdit = ()=>{
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [spinner, setSpinner] = useState(true);

    const getUser = ()=>{
        setSpinner(true)
        axios.get(`${process.env.REACT_APP_API_URL}/user-edit/${id}`)
        .then((res)=>{
            if(res.data.success===true){
                setName(res.data.data.name);
                setEmail(res.data.data.email);
            }
            setSpinner(false)
        }).catch((err)=>{setSpinner(false)})
    }  

    const handleSubmit = (e)=>{
        e.preventDefault();
        const formData = { name:name, email:email }
        axios.put(`${process.env.REACT_APP_API_URL}/user-update/${id}`, formData)
        .then((res)=>{
            if(res.data.success === true){
                toast.success(res.data.message);
                navigate('/admin/users');
            }else{
                toast.error(res.data.error);
            }
        }).catch((err)=>{
            if(err.response.data.success === false){
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(()=>{
        getUser();
    },[]);

    return (
        <Card className="shadow-lg border-0">
            { spinner === true &&  <Loader/> }
            <Toaster/>
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">add user</Card.Title>
                <Link to="/admin/users" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList/> users</Link>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Label>Name</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Enter Name" name="name" value={name} onChange={(e)=>{setName(e.target.value)}}/>
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Form.Label>Email</Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Control type="email" placeholder="Enter Email" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                            </Form.Group>
                        </Col>
                       
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group>
                                <Button type="submit" className="text-capitalize">update user</Button>
                            </Form.Group>
                        </Col>
                    </Row>

                </Form>
            </Card.Body>
        </Card>
    )
}

export default UserEdit;