import logo from "./images/logo.png"
import sublogo from "./images/sublogo.png"
import favicone from "./images/favicone.png"

const Images = {
  logo,
  sublogo,
  favicone
}

export default Images