import { workspaceEmailReq } from "../../../redux/workspace.slice"
import { hideDialog } from "../../../redux/dialog.slice"
import { useDispatch, useSelector } from "react-redux"
import Icons from "../../../assets/icons"
import CustomInput from "../CustomInput"
import { toast } from "react-toastify"
import {
  Box, Button, CircularProgress, DialogActions,
  DialogContent, DialogTitle, Divider,
  IconButton, Paper, Typography
} from "@mui/material"

function ShareWorksapce({ shareToken }) {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.workspaceSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.shareToken = shareToken || null;
    formData.urldata = `${window.location.origin}/dashboard?token_name=`
    if (!formData?.email) {
      toast.error("Email is required!")
      return
    }
    dispatch(workspaceEmailReq(formData))
  }
  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="ShareWorksapce" component="form" onSubmit={handleSubmit}>
      <DialogTitle component="div" className="p-2 relative">
        <Box className=" inline-block absolute top-[50%] right-[10px] translate-y-[-50%]">
          <IconButton
            size="small"
            onClick={handleClose}
            className="mui-close-btn"
          ><Icons.MuiCrossBtnSqr
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Typography component="h6" className="title !text-xl">Share your workspace!</Typography>
      </DialogTitle>
      <Divider className="!border-gray-500" />
      <DialogContent>
        <Typography className="content !text-sm mb-3">Share the benefits of premium features and unlimited projects with your team. Experience a limitless creative journey together with Alta’s advanced features!</Typography>
        <CustomInput
          id="email"
          name="email"
          type="email"
          label="Email address"
          placeholeder="Enter Email"
        />
      </DialogContent>
      <Divider className="!border-gray-500" />
      <DialogActions sx={{ justifyContent: "end", px: 3 }}>
        <Button
          size="small"
          type="submit"
          variant="contained"
          className="fill-btn clr-primary"
        >Send Mail
          {loading &&
            <CircularProgress
              size={20}
              thickness={5.5}
              sx={{ display: "flex", ml: "4px", color: "white" }}
            />
          }
        </Button>
      </DialogActions>
    </Paper>
  )
}

export default ShareWorksapce