import { hideLadingLoader } from "../../helper";
import { useEffect } from "react";

const NotFound = () => {
    useEffect(() => {
        hideLadingLoader()
    }, [])

    return (
        <div className="d-grid" style={{ placeItems: "center", minHeight: "100vh" }}>
            <h1>Page not found!</h1>
        </div>
    )
}

export default NotFound;