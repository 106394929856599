import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { headers } from "../helper";
import { toast } from "react-toastify";

// Get all blog categories
export const getBlogCategories = createAsyncThunk("getBlogCategories", (_, { rejectWithValue }) => {
	try {
		const response = axios.get(`${process.env.REACT_APP_API_URL}/blog/category-list`, {
			headers: headers()
		}).then((res) => {
			return res.data?.data
		}).catch((err) => {
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get all blogs
export const getAllBlogs = createAsyncThunk("getAllBlogs", (page, { rejectWithValue }) => {
	try {
		const response = axios.get(`${process.env.REACT_APP_API_URL}/blog/list?page=${page}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				return res.data
			}
		}).catch((err) => {
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get all blogs by categories
export const getCategroyBlogs = createAsyncThunk("getCategroyBlogs", ({ slug, page }, { rejectWithValue }) => {
	try {
		const response = axios.get(`${process.env.REACT_APP_API_URL}/blog/category/slug/${slug}?page=${page}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				return res.data
			}
		}).catch((err) => {
			if (err.response.data.success === false) {
				toast.error(err.response.data.error);
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get single blog
export const getSingleBlog = createAsyncThunk("getSingleBlog", (slug, { rejectWithValue }) => {
	try {
		const response = axios.get(`${process.env.REACT_APP_API_URL}/blog/list/${slug}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				return res.data.data
			}
		}).catch((err) => {
			if (err.response.data.success === false) {
				toast.error(err.response.data.error);
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get latest blogs
export const getLatestBlogs = createAsyncThunk("getLatestBlogs", (_, { rejectWithValue }) => {
	try {
		const response = axios.get(`${process.env.REACT_APP_API_URL}/latest/blog`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				return res.data.data
			}
		}).catch((err) => {
			if (err.response.data.success === false) {
				toast.error(err.response.data.error);
			}
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

const initialState = {
	blogCategories: {},
	singleBlog: {},
	allBlogs: {},
	latestBlogs: {},
	totalPages: 1,
	currentPage: 1,
	totalRecords: 0,
}
const blog = createSlice({
	name: "blog",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// Get all blog categories
		builder.addCase(getBlogCategories.pending, (state) => {
			state.blogCategories.loading = true
		})
		builder.addCase(getBlogCategories.fulfilled, (state, { payload }) => {
			delete state.blogCategories.loading
			state.blogCategories.data = payload
		})
		builder.addCase(getBlogCategories.rejected, (state) => {
			delete state.blogCategories.loading
		})

		// Get all blogs
		builder.addCase(getAllBlogs.pending, (state) => {
			state.allBlogs.loading = true
		})
		builder.addCase(getAllBlogs.fulfilled, (state, { payload }) => {
			delete state.allBlogs.loading
			state.allBlogs.data = payload?.data
			state.totalPages = payload?.pages
			state.totalRecords = payload?.total
			state.currentPage = +payload?.current
		})
		builder.addCase(getAllBlogs.rejected, (state) => {
			delete state.allBlogs.loading
		})

		// Get all blogs by categories
		builder.addCase(getCategroyBlogs.pending, (state) => {
			state.allBlogs.loading = true
		})
		builder.addCase(getCategroyBlogs.fulfilled, (state, { payload }) => {
			delete state.allBlogs.loading
			state.allBlogs.data = payload?.data
			state.totalPages = payload?.pages
			state.totalRecords = payload?.total
			state.currentPage = +payload?.current
		})
		builder.addCase(getCategroyBlogs.rejected, (state) => {
			delete state.allBlogs.loading
		})

		// Get single blog
		builder.addCase(getSingleBlog.pending, (state) => {
			state.singleBlog.loading = true
		})
		builder.addCase(getSingleBlog.fulfilled, (state, { payload }) => {
			delete state.singleBlog.loading
			state.singleBlog.data = payload
		})
		builder.addCase(getSingleBlog.rejected, (state) => {
			delete state.singleBlog.loading
		})

		// Get latest blogs
		builder.addCase(getLatestBlogs.pending, (state) => {
			state.latestBlogs.loading = true
		})
		builder.addCase(getLatestBlogs.fulfilled, (state, { payload }) => {
			delete state.latestBlogs.loading
			state.latestBlogs.data = payload
		})
		builder.addCase(getLatestBlogs.rejected, (state) => {
			delete state.latestBlogs.loading
		})

		// Logout
		builder.addCase("LOGOUT", () => initialState)
	}
})

export default blog.reducer;