import AdminProfile from '../../../components/admin/AdminProfile';
import { Link} from 'react-router-dom';
import { FiLock} from "react-icons/fi";
import { Card } from 'react-bootstrap';

const Setting = ()=>{  
    return (
        <Card className="shadow-lg border-0">
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Admin Setting</Card.Title>
                <Link to="/admin/change-password" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiLock/> Change Password</Link>
            </Card.Header>
            <AdminProfile/>
        </Card>
    )
}

export default Setting;