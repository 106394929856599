// 
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { headers, BASEURL, checktoken } from "../helper"
import axios from "axios"
import { toast } from "react-toastify"

// Get all template categories
export const getTemplateCateReq = createAsyncThunk("getTemplateCateReq", (_, { rejectWithValue }) => {
	try {
		const response = axios.get(`${BASEURL}/template/category/list`, {
			headers: headers()
		}).then((res) => {
			if (res?.data?.success) {
				return res?.data?.data
			}
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get all templates
export const getTemplateListReq = createAsyncThunk("getTemplatesByCateReq", ({ text }, { rejectWithValue }) => {
	try {
		const response = axios.get(`${BASEURL}/template/list?search=${text}`, {
			headers: headers()
		}).then((res) => {
			if (res?.data?.success) {
				return res.data.data
			}
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get templates by category
export const getTemplatesByCateReq = createAsyncThunk("getTemplatesByCateReq", ({ id, text }, { rejectWithValue }) => {
	try {
		const response = axios.get(`${BASEURL}/category/template/list/${id}?search=${text}`, {
			headers: headers()
		}).then((res) => {
			if (res?.data?.success) {
				return res?.data?.data
			}
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get custom templates
export const getCustomTemplatesReq = createAsyncThunk("getTemplatesByCateReq", ({ text }, { rejectWithValue }) => {
	try {
		const response = axios.get(`${BASEURL}/custom-template/list?search=${text}`, {
			headers: headers()
		}).then((res) => {
			if (res?.data?.success) {
				return res?.data?.data
			}
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Get saved templates
export const getSavedTemplatesReq = createAsyncThunk("getSavedTemplatesReq", (_, { rejectWithValue }) => {
	try {
		const response = axios.get(`${BASEURL}/saved/content`, {
			headers: headers()
		}).then((res) => {
			return res.data.data
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Toggle favorite templates
export const toggleFavoriteTempReq = createAsyncThunk("toggleFavoriteTempReq", (id, { rejectWithValue }) => {
	try {
		const response = axios.put(`${BASEURL}/add/favorite/content/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res?.data?.success) {
				toast.success(res.data.message)
				return res.data
			}
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

// Delete saved templates
export const deleteSavedTempReq = createAsyncThunk("deleteSavedTempReq", (id, { rejectWithValue }) => {
	try {
		const response = axios.delete(`${process.env.REACT_APP_API_URL}/delete/content/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res?.data?.success) {
				toast.success(res.data.message)
				return res.data
			}
		}).catch((err) => {
			checktoken(err)
			return rejectWithValue(err)
		})

		return response
	} catch (error) {
		console.log(error)
	}
})

const initialState = {
	templates: {},
	categories: {},
}
const template = createSlice({
	name: "template",
	initialState,
	reducers: {},
	extraReducers(builder) {
		// Get all template categories
		builder.addCase(getTemplateCateReq.pending, (state) => {
			state.categories.loading = true
		})
		builder.addCase(getTemplateCateReq.fulfilled, (state, { payload }) => {
			delete state.categories.loading
			state.categories.data = payload
		})
		builder.addCase(getTemplateCateReq.rejected, (state) => {
			delete state.categories.loading
		})

		// Get templates by category
		builder.addCase(getTemplatesByCateReq.pending, (state) => {
			state.templates.loading = true
		})
		builder.addCase(getTemplatesByCateReq.fulfilled, (state, { payload }) => {
			delete state.templates.loading
			state.templates.data = payload
		})
		builder.addCase(getTemplatesByCateReq.rejected, (state) => {
			delete state.templates.loading
		})

		// Get saved templates
		builder.addCase(getSavedTemplatesReq.pending, (state) => {
			state.savedLoading = true
		})
		builder.addCase(getSavedTemplatesReq.fulfilled, (state) => {
			delete state.savedLoading
		})
		builder.addCase(getSavedTemplatesReq.rejected, (state) => {
			delete state.savedLoading
		})

		// Toggle favorite templates
		builder.addCase(toggleFavoriteTempReq.pending, (state) => {
			state.toggleFavLoading = true
		})
		builder.addCase(toggleFavoriteTempReq.fulfilled, (state) => {
			delete state.toggleFavLoading
		})
		builder.addCase(toggleFavoriteTempReq.rejected, (state) => {
			delete state.toggleFavLoading
		})

		// Delete saved templates
		builder.addCase(deleteSavedTempReq.pending, (state) => {
			state.delLoading = true
		})
		builder.addCase(deleteSavedTempReq.fulfilled, (state) => {
			delete state.delLoading
		})
		builder.addCase(deleteSavedTempReq.rejected, (state) => {
			delete state.delLoading
		})

		// Logout
		builder.addCase("LOGOUT", () => initialState)
	}
})

export default template.reducer