import ContentEditable from 'react-contenteditable';
import * as DOMPurify from 'dompurify';
import { memo } from 'react';

const Description = ({ text, handleChange }) => {
    const regex = /\[(.*?)\]/g;
    return (
        <div>
            <ContentEditable id="editable" style={{ minHeight: '100px' }}
                disabled={false}
                tagName="div"
                className="use-prompt-text"
                html={DOMPurify.sanitize(text.replace(regex, (match, keyword) => {
                    return `[<span>${keyword}</span>]`;
                }).replaceAll('\\n', '<br>').replaceAll('/n', ''))}
                editable={`${true}`}
                onBeforeInput={(e) => { text.length < 1500 ? console.log() : e.preventDefault(); }}
                onChange={(e) => { handleChange(e.target.value) }}
            />
        </div>
    )
}

export default memo(Description);