import { Table, Card, Form, Pagination } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { Fragment, useEffect, useState } from 'react';
import { FiPlus, FiEye } from "react-icons/fi";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BlogCategoryList = () => {
    const [page, setPage] = useState(1);
    const [spinner, setSpinner] = useState(false);
    const [categories, setCategories] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const categoryList = (page) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/blog/category/list?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error[0]);
                }
            }
        })
    }

    const handleSearch = (searchQuery) => {
        axios.post(`${process.env.REACT_APP_API_URL}/blog/category/search`, { name: searchQuery }, {
            headers: headers()
        }).then((res) => {
            setSpinner(false);
            if (res.data.success === true) {
                setCategories(res.data.data);
            } else {
                toast.error(res.data.error)
                setCategories([]);
            }
        }).catch((err) => { })
    }

    useEffect(() => {
        hideLadingLoader();
        categoryList(page);
    }, [])

    return (
        <Fragment>
            {spinner === true && <Loader />}
            <Toaster />
            <Card className="shadow-lg border-0">
                <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                    <Card.Title className="m-0 text-capitalize">Blog Categories list</Card.Title>
                    <div className="d-flex">
                        <Form.Control type="search" placeholder="Search" className="me-2 search-form" aria-label="Search" name="searchQuery" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); handleSearch(searchQuery) }} />
                        <Link to="/admin/add-blog-categories" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiPlus /> category</Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    {
                        categories.length > 0 &&
                        <Fragment>
                            <div className="table-responsive">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            categories && categories.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {page > 1 ?
                                                                (10 * page) + (index - 9) : index + 1
                                                            }
                                                        </td>
                                                        <td className='text-capitalize'>{item.name}</td>
                                                        <td>
                                                            <Link to={`/admin/list/prompt/${item._id}`}><FiEye /></Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {
                                pagination.length > 1 &&
                                <Pagination className='mt-3 justify-content-center'>
                                    {
                                        pagination.map((item, index) => {
                                            return (
                                                <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); categoryList(item) }}>{item}</Pagination.Item>)
                                        })
                                    }
                                </Pagination>
                            }
                        </Fragment>
                    }
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default BlogCategoryList;