import { Card, Table, Form, Badge, Pagination } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import { FiPlus, FiTrash2, FiEdit2 } from "react-icons/fi";
import Toaster from "../../../components/frontend/Toaster";
import { useEffect, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Blogs = () => {
    const [page, setPage] = useState(1);
    const [blogs, setBlogs] = useState([])
    const [spinner, setSpinner] = useState(true);
    const [pagination, setPagination] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const blogsList = (page) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/list/blog?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setBlogs(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    const deleteBlogs = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/admin/delete/blog/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.error(res.data.message);
                blogsList();
            }
        }).catch((err) => {
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    const handleSearch = (searchQuery) => {
        setSpinner(true);
        axios.post(`${process.env.REACT_APP_API_URL}/blog/search`, { name: searchQuery }, {
            headers: headers()
        }).then((res) => {
            setSpinner(false);
            if (res.data.success === true) {
                setBlogs(res.data.data);
            }
            if (res.data.success === false) {
                setBlogs([])
            }

        }).catch((err) => {
            setSpinner(false);
        })
    }

    useEffect(() => {
        hideLadingLoader();
        blogsList(page);
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Blogs List <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                <div className="d-flex">
                    <Form.Control type="search" placeholder="Search" className="me-2 search-form" aria-label="Search" name="searchQuery" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); handleSearch(searchQuery) }} />
                    <Link to="/admin/add-blog" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiPlus /> blog</Link>
                </div>
            </Card.Header>
            <Card.Body>
                {
                    blogs &&
                    <Fragment>
                        <div className="table-responsive">
                            <Table striped hover bordered>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        blogs && blogs.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {page > 1 ?
                                                            (10 * page) + (index - 9) : index + 1
                                                        }
                                                    </td>
                                                    <td>
                                                        <img className="blog-image" src={process.env.REACT_APP_FILE_URL + '/' + item.image} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td><Badge className="text-uppercase">{item.blogCategory.name}</Badge></td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <Link className='btn btn-primary btn-sm me-1' to={`/admin/edit-blog/${item._id}`}><FiEdit2 /></Link>
                                                            <button onClick={() => { deleteBlogs(item._id) }} type='button' className='btn btn-sm btn-danger'><FiTrash2 /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        {
                            pagination.length > 1 &&
                            <Pagination className='mt-3 justify-content-center'>
                                {
                                    pagination.map((item, index) => {
                                        return (
                                            <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); blogsList(item) }}>{item}</Pagination.Item>)
                                    })
                                }
                            </Pagination>
                        }
                    </Fragment>
                }
            </Card.Body>
        </Card>
    )
}

export default Blogs;