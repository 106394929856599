import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { getAllWorksapace, loginWorkspaceReq } from "../../redux/workspace.slice"
import { Button, IconButton, Stack, styled } from "@mui/material"
import { setChatWords, SetAppSumo } from "../../actions/index"
import layoutStyles from "../../assets/css/layout.module.css"
import { addUser, removeUser } from "../../redux/auth.slice"
import { onAppsumoNavigation } from "../../utils/helperFun"
import AltacopyDropdown from "../common/AltacopyDropdown"
import { useDispatch, useSelector } from "react-redux"
import { showDialog } from "../../redux/dialog.slice"
import { googleLogout } from "@react-oauth/google"
import LogoImg from "../../assets/images/logo.png"
import { Dropdown, Badge } from "react-bootstrap"
import { useEffect, Fragment } from "react"
import SideMenu from "../common/SideMenu"
import { checktoken } from "../../helper"
import Icons from "../../assets/icons"
import { Cookies } from "react-cookie"
import Loader from "./TextLoader"
import Toaster from "./Toaster"
import axios from "axios"

const menus = [
	{
		path: "/user/chat",
		startIcon: <Icons.FiMessageSquare />,
		name: <><span className="alta-text">Alta</span>Chat</>,
	},
	{
		name: "Projects",
		path: "/user/projects",
		startIcon: <Icons.FiLayers />,
	},
	{
		path: "/user/brand",
		name: "Brand Voice",
		startIcon: <Icons.BarChart />,
	},
	{
		path: "/",
		name: "Templates",
		startIcon: <Icons.FiFile />,
	},
	{
		path: "/user/alta-detect",
		startIcon: <Icons.FiMessageSquare />,
		name: <><span className="alta-text">Alta</span>Detect</>,
	},
]

const Div = styled("div")(() => ({}))
const Span = styled("span")(() => ({}))

const AppSumoSidebar = ({ handleSidebar }) => {
	const cookies = new Cookies()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [searchParams] = useSearchParams()
	const template = searchParams.get("template")
	const message = useSelector((state) => state.GetToastMessage)
	const { user } = useSelector((state) => state.authSlice)
	const { loading, activeWorkspace, allSharedWorkSapce,
	} = useSelector(state => state.workspaceSlice)

	const logOut = () => {
		googleLogout()
		dispatch(removeUser())
		dispatch(setChatWords(0))
		localStorage.clear()
		cookies.remove("auth", { path: "/" })
		cookies.remove("workspaceauth", { path: "/" })
		navigate("/login")
	}

	const getUserData = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/profile-edit/`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${cookies.get("auth")}`,
			}
		}).then((res) => {
			if (res.data.success === true) {
				dispatch(SetAppSumo(res.data.data?.appsumouser))
				dispatch(addUser(res.data.data))
				localStorage.removeItem("authuser");
				localStorage.setItem("authuser", JSON.stringify(res.data.data));
			}
		}).catch((err) => { checktoken(err) })
	}

	const handleSwitchUser = (data) => {
		dispatch(loginWorkspaceReq({ data, navigate }))
	}

	const handleGetWorkspace = () => {
		dispatch(getAllWorksapace(navigate))
	}

	const handleNavigate = (path, validPath) => {
		handleSidebar()
		onAppsumoNavigation(navigate, path, validPath)
	}

	useEffect(() => {
		getUserData()
		handleGetWorkspace()
		if (template !== undefined && template !== null) {
			dispatch(showDialog("AllTemplates"))
		}

		// eslint-disable-next-line
	}, [navigate, dispatch, message]);

	return (
		<Fragment>
			{loading ?
				<Loader /> :
				<Div className={`${layoutStyles["sidebar-container"]} custom-scrollbar`}>
					<Div
						sx={{
							py: 2,
							mx: "8px",
							gap: "8px",
							display: "flex",
							position: "relative",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Link to="/" className="mx-auto">
							<img className="mx-auto" src={LogoImg} style={{ width: "100px" }} alt="logo" />
						</Link>
						<IconButton
							size="small"
							onClick={handleSidebar}
							sx={{
								color: "white",
								bgcolor: "#7959a6 !important",
								display: { xs: "flex", laptop: "none" },
							}}
						>
							<Icons.Cross />
						</IconButton>
					</Div>

					<Stack spacing={0.5}>
						<SideMenu
							menu={menus[0]}
							addClass="tour-step-1"
							active={pathname === "/user/chat"}
							handleNavigate={() => { handleNavigate("/user/chat") }}
						/>
						<SideMenu
							menu={menus[1]}
							addClass="tour-step-6"
							active={pathname === "/user/projects"}
							handleNavigate={() => { handleNavigate("/user/projects") }}
						/>
						<SideMenu
							menu={menus[2]}
							addClass="tour-step-7"
							active={pathname.includes("brand")}
							handleNavigate={() => { handleNavigate("/user/brand") }}
						/>
						<AltacopyDropdown />
						<SideMenu
							menu={menus[4]}
							proBadge={true}
							addClass="tour-step-9"
							active={pathname === "/user/alta-detect"}
							handleNavigate={() => { handleNavigate("/user/alta-detect", true) }}
						/>
					</Stack>

					<Div sx={{ mt: "auto", px: 2 }}>
						<Button
							to="/pricing"
							variant="contained"
							LinkComponent={Link}
							className="fill-btn clr-primary w-full !mb-2"
							endIcon={<Icons.Badge />}
						>Upgrade
						</Button>

						<Dropdown>
							<Dropdown.Toggle id="dropdown-basic">
								<SpaceMenu user={user} />
							</Dropdown.Toggle>
							<Dropdown.Menu className="w-100 pt-0">
								<Div
									sx={{
										display: "flex",
										flexDirection: "column"
									}}
								>
									<Div className="order-[4]">
										<Div
											sx={{
												px: 1,
												py: 0.5,
												fontSize: "12px",
												cursor: "pointer",
												fontWeight: "500",
											}}
										>{user?.email}
										</Div>

										<Div
											sx={{
												p: 1,
												gap: "8px",
												display: "flex",
												cursor: "pointer",
												flexWrap: "nowrap",
												alignItems: "center",
												transitionDuration: "350ms"
											}}
											className={`font-ff-poppins hover:bg-deluge-100 ${Object.keys(activeWorkspace)?.length === 0 ? "!bg-deluge-700 text-white" : ""}`}
											onClick={() => {
												if (Object.keys(activeWorkspace)?.length > 0) {
													handleSwitchUser({
														status: "remove",
														id: activeWorkspace?._id,
														email: activeWorkspace?.userId?.email,
													})
												}
											}}
										>
											<Span
												sx={{
													height: "35px",
													width: "35px",
													display: "grid",
													fontSize: "15px",
													borderRadius: "30px",
													placeContent: "center",
													textTransform: "uppercase",
													border: `2px solid ${Object.keys(activeWorkspace)?.length === 0 ? "white" : "#7959a6"}`,
												}}
												className="font-normal"
											>{user?.name?.split(" ")[0]?.split("")[0]}{user?.name?.split(" ")[1]?.split("")[0]}</Span>
											<Div
												sx={{
													"span": {
														display: "block",
														textTransform: "capitalize",
														fontWeight: Object.keys(activeWorkspace)?.length === 0 ? "300" : "400",
													}
												}}
												className="flex-grow text-left"
											>
												<Span className="text-sm">personal workspace </Span>
												<Span className="!text-xs">Paid plan</Span>
											</Div>
										</Div>
									</Div>

									{allSharedWorkSapce &&
										allSharedWorkSapce?.map((item, index) => (
											<Div key={index} className={`${item._id === activeWorkspace?._id ? "order-[1]" : "order-[4]"}`}>
												<Div
													sx={{
														px: 1,
														py: 0.5,
														fontSize: "12px",
														fontWeight: "500",
													}}
												>{item?.userId?.email}</Div>

												<Div
													sx={{
														p: 1,
														gap: "8px",
														display: "flex",
														cursor: "pointer",
														flexWrap: "nowrap",
														alignItems: "center",
														transitionDuration: "350ms"
													}}
													className={`font-ff-poppins hover:bg-deluge-100 ${item._id === activeWorkspace?._id ? "!bg-deluge-700 text-white" : ""}`}
													onClick={() => {
														if (item._id !== activeWorkspace?._id) {
															handleSwitchUser({
																id: item._id,
																email: item.userId.email
															})
														}
													}}
												>
													<Span
														sx={{
															height: "35px",
															width: "35px",
															display: "grid",
															fontSize: "15px",
															borderRadius: "30px",
															placeContent: "center",
															textTransform: "uppercase",
															border: `2px solid ${item._id === activeWorkspace?._id ? "white" : "#7959a6"}`,
														}}
														className="font-normal"
													>{user?.name?.split(" ")[0]?.split("")[0]}{user?.name?.split(" ")[1]?.split("")[0]}</Span>
													<Div
														sx={{
															"span": {
																display: "block",
																textTransform: "capitalize",
																fontWeight: item._id === activeWorkspace?._id ? "300" : "400",
															}
														}}
														className="flex-grow text-left"
													>
														<Span className="text-sm">personal workspace </Span>
														<Span className="!text-xs">Paid plan</Span>
													</Div>
												</Div>
											</Div>
										))
									}
								</Div>

								<Dropdown.Divider />
								<Div
									sx={{
										px: 1,
										".MuiButton-root": {
											width: "100%",
											fontSize: "14px",
											color: "#1f2937",
											fontWeight: "500",
											letterSpacing: "0",
											bgcolor: "transparent",
											justifyContent: "start",
											textTransform: "capitalize",
											transitionDuration: "350ms",
											boxShadow: "none !important",
											":hover": {
												color: "white",
												bgcolor: "#7959a6",
												".pro-badge": {
													color: "#7959a6 !important",
													bgcolor: "white !important",
												}
											},
											".pro-badge": { bgcolor: "#7959a6 !important" }
										}
									}}
								>
									<Dropdown.Item as="div" className="p-0">
										<Button
											target="_blank"
											variant="contained"
											LinkComponent={Link}
											sx={{ px: "10px" }}
											onClick={handleSidebar}
											className="!font-ff-lato"
											to="https://goalta.firstpromoter.com/"
											startIcon={<Icons.FiUsers className="!text-[14px]" />}
										>Affiliate
										</Button>
									</Dropdown.Item>
									<Dropdown.Item as="div" className="p-0">
										<Button
											variant="contained"
											LinkComponent={Link}
											sx={{ px: "10px" }}
											to="/user/subscription"
											onClick={handleSidebar}
											className="!font-ff-lato"
											startIcon={<Icons.FiCreditCard className="!text-[14px]" />}
										>Subscription
										</Button>
									</Dropdown.Item>
									<Dropdown.Item as="div" className="p-0">
										<Button
											variant="contained"
											onClick={handleSidebar}
											className="!font-ff-lato"
											sx={{ px: "10px", ".MuiButton-icon": { mr: "6px" } }}
											startIcon={<Icons.TbApiApp className="!text-[16px]" />}
										>Model Library
											<Badge className="ml-auto pro-badge text-white">Coming soon</Badge>
										</Button>
									</Dropdown.Item>
									<Dropdown.Item as="div" className="p-0">
										<Button
											variant="contained"
											LinkComponent={Link}
											sx={{ px: "10px" }}
											onClick={handleSidebar}
											className="!font-ff-lato"
											startIcon={<Icons.FiUsers className="!text-[14px]" />}
											to={user?.plan === "Pro" ? "/user/shared/user" : "/pricing"}
										>Shared Workspace
											{user?.plan !== "Pro" &&
												<Badge className="ml-auto pro-badge text-white">pro</Badge>
											}
										</Button>
									</Dropdown.Item>
									<Dropdown.Item as="div" className="p-0">
										<Button
											variant="contained"
											LinkComponent={Link}
											sx={{ px: "10px" }}
											to="/user/payment-list"
											onClick={handleSidebar}
											className="!font-ff-lato"
											startIcon={<Icons.FiDollarSign className="!text-[14px]" />}
										>Transactions
										</Button>
									</Dropdown.Item>
									<Dropdown.Item as="div" className="p-0">
										<Button
											to="/user/profile"
											variant="contained"
											LinkComponent={Link}
											sx={{ px: "10px" }}
											onClick={handleSidebar}
											className="!font-ff-lato"
											startIcon={<Icons.FiUser className="!text-[14px]" />}
										>profile
										</Button>
									</Dropdown.Item>
								</Div>
								<Dropdown.Divider />
								<Div
									sx={{
										px: 1,
										".MuiButton-root": {
											width: "100%",
											color: "#1f2937",
											bgcolor: "transparent",
											fontWeight: "500",
											justifyContent: "start",
											textTransform: "capitalize",
											transitionDuration: "350ms",
											boxShadow: "none !important",
											fontSize: "14px",
											":hover": {
												color: "white",
												bgcolor: "#7959a6",
											},
										}
									}}
								>
									<Dropdown.Item as="div" className="p-0">
										<Button
											onClick={logOut}
											sx={{ px: "10px" }}
											variant="contained"
											className="!font-ff-lato"
											startIcon={<Icons.FiLogOut className="!text-[14px]" />}
										>logout
										</Button>
									</Dropdown.Item>
								</Div>
							</Dropdown.Menu>
						</Dropdown>
					</Div>

				</Div>
			}
			<Toaster />
		</Fragment>
	)
}

function SpaceMenu({ user }) {

	return (
		<Div
			sx={{
				gap: "8px",
				display: "flex",
				flexWrap: "nowrap",
				alignItems: "center",
			}}
			className="font-ff-poppins"
		>
			<Span
				sx={{
					height: "35px",
					width: "35px",
					display: "grid",
					borderRadius: "30px",
					placeContent: "center",
					textTransform: "uppercase",
					border: "2px solid #7959a6",
				}}
			>{user?.name?.split(" ")[0]?.split("")[0]}{user?.name?.split(" ")[1]?.split("")[0]}</Span>
			<Div className="flex-grow text-left">
				<Span className="capitalize block">personal workspace </Span>
				<Span className="capitalize block !text-xs">Paid plan</Span>
			</Div>
		</Div>
	)
}

export default AppSumoSidebar;