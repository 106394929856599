import { Button, CircularProgress, Input, LinearProgress, styled, Typography } from "@mui/material"
import { headers, BASEURL, checktoken } from "../../../helper"
import Toaster from "../../../components/frontend/Toaster"
import { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import axios from "axios"

const Div = styled("div")(() => ({}))

const SoloProgress = () => {
	const [spinner, setSpinner] = useState(false)
	const { user } = useSelector(state => state.authSlice)
	const { totalWords } = useSelector(state => state.chatSlice)

	const UpdateAppsumo = (e) => {
		e.preventDefault()
		const form = new FormData(e.target)
		const formData = Object.fromEntries(form)
		if (formData?.appsumocode?.trim() === "") {
			toast.error("AppSumo Code is required!")
			return
		}
		axios.post(`${BASEURL}/user/update/appsumo`, formData, {
			headers: headers()
		}).then(() => {
			setSpinner(false);
			toast.success("Upgrade successful!");
			// window.location.reload(true);
		}).catch((err) => {
			checktoken(err)
			if (err?.response?.data !== undefined) {
				toast.error(err.response.data.error);
			}
		})
	}

	return (
		<Fragment>
			{(user.appsumocode !== null && user.login_type === "appsumo") ?
				<Div sx={{ mt: 2 }}>
					<Typography
						component="h5"
						className="!font-medium !font-ff-lato"
					>Words Generated [ {totalWords || 0} / 30,000 ]
					</Typography>

					<Div>
						<LinearProgress
							variant="determinate"
							sx={{
								height: "16px",
								bgcolor: "#e4e1f3",
								borderRadius: "4px",
								"span": { bgcolor: "#7959a6" }
							}}
							value={(totalWords * 100) / 30000}
						/>
					</Div>
				</Div> :
				<Div sx={{ mt: 2 }}>
					<Typography
						component="h5"
						className="!font-medium !font-ff-lato"
					>Words Generated [ {totalWords || 0} / 50,000 ]
					</Typography>

					<Div>
						<LinearProgress
							variant="determinate"
							sx={{
								height: "16px",
								borderRadius: "4px",
								bgcolor: "#e4e1f3",
								"span": {
									bgcolor: "#7959a6"
								}
							}}
							value={(totalWords * 100) / 50000}
						/>
					</Div>
				</Div>
			}

			{user.appsumocode !== null &&
				<Div sx={{ my: 5, width: { xs: "100%", laptop: "50%" } }}>
					<Div
						as="form"
						onSubmit={UpdateAppsumo}
						className="flex gap-[8px] flex-nowrap"
					>
						<Input
							className="flex-grow"
							type="text"
							name="appsumocode"
							placeholder="AppSumo code"
							sx={{
								p: "6px 12px",
								"::before": { borderBottom: "3px solid #7959a6 !important" },
								"::after": { borderBottom: "3px solid #7959a6 !important" },
							}}
						/>
						<Button
							variant="outlined"
							sx={{
								ml: 1,
								px: 3,
								color: "#7959a6",
								borderRadius: "30px",
								border: "3px solid #7959a6 !important",
							}}
							type="submit"
						>Redeem
							{spinner &&
								<CircularProgress
									size={20}
									thickness={5}
									sx={{
										ml: 0.5,
										display: "flex",
										color: "#7959a6",
									}}
								/>
							}
						</Button>
					</Div>
				</Div>
			}

			<Toaster />
		</Fragment>
	)
}

export default SoloProgress