import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { FiList, FiPlus, FiX } from "react-icons/fi";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const AddTemplate = () => {
    const [name, setName] = useState('')
    const [spinner, setSpinner] = useState(true)
    const [category, setCategory] = useState(null)
    const [categories, setCategories] = useState([])
    const [description, setDescription] = useState('')
    const [fields, setFields] = useState([{ Label: '', Field: '', Placeholder: '', Name: '' }])
    const [elements, setElements] = useState([{ label: 'Enter Label', field: 'Enter Field', addbtn: 'add', removebtn: 'remove', placeholder: 'Enter Placeholder', name: 'Enter name for field' }]);

    const addElement = () => {
        setFields([...fields, { Label: '', Field: '', Placeholder: '', Name: '' }]);
        setElements([...elements, { label: 'Enter Label', field: 'Enter Field', addbtn: 'add', removebtn: 'remove', placeholder: 'Enter Placeholder', name: 'Enter name for field' },]);
    }

    const removeElement = (index) => {
        let newArr = [...elements];
        let newFields = [...fields];
        newArr = newArr.splice(index, 1);
        newFields = fields.splice(index, 1);
        setFields(newFields);
        setElements(newArr);
    }

    const handleChange = (e, index) => {
        let newFields = fields;
        const { name, value } = e.target;
        newFields[index][name] = value;
        setFields([...newFields]);
    }

    const categoryList = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/template/category/list`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
            } else {
                toast.error(res.data.error);
            }
        }).catch((err) => {
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error[0]);
                }
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            name: name.toLowerCase(),
            categoryId: category,
            description: description.toLowerCase(),
            fields: JSON.stringify(fields),
            projectId: null
        };

        axios.post(`${process.env.REACT_APP_API_URL}/template/create`, formData, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategory('');
                setCategory(null);
                setDescription('');
                setFields([{ Label: '', Field: '', Placeholder: '' }])
                setElements([{ label: 'Enter Label', field: 'Enter Field', addbtn: 'add', removebtn: 'remove', placeholder: 'Enter Placeholder' }]);
                toast.success(res.data.message);
            } else {
                toast.error(res.data.error);
            }
        }).catch((err) => {
            if (err.response !== undefined) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
        categoryList();
        setTimeout(() => {
            setSpinner(false);
        }, 500);
    }, [])

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Add template</Card.Title>
                <Link to="/admin/templates" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> templates</Link>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12} className='m-auto'>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" placeholder="Enter the template name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                    </Form.Group>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Category</Form.Label>
                                        <Form.Select name="category" onChange={(e) => { setCategory(e.target.value) }}>
                                            <option value="">Select</option>
                                            {
                                                categories && categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-4">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" type="text" placeholder="Enter the template description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                            </Form.Group>
                            {
                                elements && elements.map((item, index) => {
                                    return (
                                        <div className='border p-2 rounded bg-light d-grid grid-element mb-4 position-relative' key={index}>
                                            <Form.Control type="text" placeholder={item.label} name={Object.keys(fields[index])[0]} value={fields[index].Label} onChange={(e) => { handleChange(e, index) }} />
                                            <Form.Select name={Object.keys(fields[index])[1]} onChange={(e) => { handleChange(e, index) }}>
                                                <option value="">Select fields</option>
                                                <option value="text">input text</option>
                                                <option value="textarea">textarea</option>
                                                {/* <option value="select">dropdown</option> */}
                                            </Form.Select>
                                            <button type="button" className={`btn ${item.addbtn}`} onClick={(e) => { addElement() }}><FiPlus /></button>
                                            <button type="button" className={`btn ${item.removebtn}`} onClick={(e) => { removeElement(index) }}><FiX /></button>
                                            <Form.Control as="textarea" className='placeholder-input' type="text" placeholder={item.placeholder} name={Object.keys(fields[index])[2]} value={fields[index].Placeholder} onChange={(e) => { handleChange(e, index) }} />
                                            <Form.Control className='name-input' type="text" placeholder={item.name} name={Object.keys(fields[index])[3]} value={fields[index].Name} onChange={(e) => { handleChange(e, index) }} />
                                        </div>
                                    )
                                })
                            }
                            <Form.Group className="mb-3">
                                <Button type="submit" variant='success text-capitalize'>submit</Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default AddTemplate;