import Toaster from '../../../components/frontend/Toaster';
import MarkdownViewer from '../../../components/frontend/MarkdownViewer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiClipboard, FiHeart } from "react-icons/fi";
import { headers, checktoken } from '../../../helper';
import { useState, Fragment, useMemo } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { FaHeart } from "react-icons/fa";
import { toast } from 'react-toastify';
import axios from 'axios'

const TemplateOutput = ({ chat, handleCopy, handleRemove, contentId }) => {
	const [spinnerround, setSpinnerRound] = useState(false);
	const [templateContent, setTemplateContent] = useState([]);

	const saveContent = (id) => {
		setSpinnerRound(true);
		axios.put(`${process.env.REACT_APP_API_URL}/update/generated/content/${id}`, { id: id }, {
			headers: headers()
		}).then((res) => {
			setSpinnerRound(false);
			if (res.data.success === true) {
				setTemplateContent([res.data.data])
				toast.success(res.data.message);
			}
		}).catch((err) => {
			checktoken(err);
			setSpinnerRound(false);
			if (err.response !== undefined) {
				if (err.response.data.error !== undefined) {
					toast.error(err.response.data.error);
				}
			}
		});
	}

	useMemo(() => {
		setTemplateContent(chat)
		return templateContent;

		// eslint-disable-next-line
	}, [chat])

	return (
		<Fragment>
			<Toaster />
			{
				(templateContent?.length > 0) && templateContent.map((item, index) => {
					return (
						<div key={index} className='d-block chat-response mx-auto p-3 mb-2 d-flex align-items-start mt-5 flex-column'>
							<div className="d-block">
								<MarkdownViewer content={item?.content} />
							</div>
							{item?._id &&
								<div className="content-btns d-flex align-items-center mt-3 justify-content-between w-100">
									<div className="d-block">
										<CopyToClipboard text={item.content}>
											<button type="button" onClick={() => handleCopy(item.content)} className="btn border-btn d-inline-flex align-items-center me-2"><FiClipboard className="me-1" /> copy </button>
										</CopyToClipboard>
										{
											item.status === 0 ?
												<button onClick={() => { item._id !== "" && saveContent(item._id); }} type="button" className={`btn border-btn d-inline-flex align-items-center me-2 ${spinnerround === true ? 'disabled' : ''}`}>
													<FiHeart /> save {spinnerround === true && <Spinner animation="border" size="sm" />}
												</button> :
												<button type="button" className="btn border-btn d-inline-flex align-items-center me-2"> <FaHeart className="me-1" /> save</button>
										}
									</div>
									<div className="text-counter">
										<span className="me-1">{item.content.split(' ').length} words /</span>
										<span>{item.content.length} chars</span>
									</div>
								</div>
							}
						</div>
					)
				})
			}
		</Fragment>
	)
}

export default TemplateOutput;