import { Form, Modal, InputGroup } from 'react-bootstrap';
import { headers, checktoken } from '../../helper';
import { FiSearch } from "react-icons/fi";
import ChatSidebar from './ChatSidebar';
import { toast } from 'react-toastify';
import { Fragment, useState } from 'react';
import Toaster from './Toaster';
import axios from 'axios';

const Modals = (props) => {
  const [search, setSearch] = useState('');
  const [isCategory, setIsCategory] = useState('');
  const [searchPrompt, setSearchPrompt] = useState([]);

  const closeModel = () => { props.handleclose() }

  const searchPrompts = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/search/prompt?search=${search}&category=${isCategory}`, {
      headers: headers()
    }).then((res) => {
      if (res.data.success === true) {
        setSearchPrompt(res.data.data);
      }
    }).catch((err) => {
      checktoken(err)
      if (err?.response?.data?.error !== undefined) {
        if (err?.response?.data?.data !== undefined) {
          setSearchPrompt([]);
        }
        toast.error(err.response.data.error);
      }
    })
  }

  return (
    <Fragment>
      <Toaster />
      <Modal show={props.show} onHide={() => props.handleclose()} dialogClassName="prompt-modal"
        aria-labelledby="example-custom-modal-styling-title" >
        <Modal.Header closeButton>
          <h5 className='text-capitalize text-center'>Prompts</h5>
          <Modal.Title id="example-custom-modal-styling-title">
            <InputGroup className='ps-2 ps-sm-5 pe-1 pe-sm-0'>
              <InputGroup.Text id="basic-addon1">
                <FiSearch />
              </InputGroup.Text>
              <Form.Control type="search" placeholder='Search prompts' value={search} onKeyUp={() => { searchPrompts(search) }} onChange={(e) => { setSearch(e.target.value); }} />
            </InputGroup>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChatSidebar searchPrompt={searchPrompt} prompt={props.prompt} closemodel={closeModel} setUsePrompt={props.setUsePrompt} setIsCategory={setIsCategory} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default Modals;