import { headers, checktoken, hideLadingLoader } from '../../helper';
import RevenueChart from '../../components/admin/RevenueChart';
import UsersChart from '../../components/admin/UsersChart';
import Loader from "../../components/frontend/TextLoader";
import Toaster from '../../components/frontend/Toaster';
import { Fragment, useEffect, useState } from 'react';
import { FiUsers, FiDollarSign } from "react-icons/fi";
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';

const Dashboard = () => {
    const [spinner, setSpinner] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const [weeklyUsers, setWeeklyUsers] = useState(0);
    const [dailyUsers, setDailyUsers] = useState(0);
    const [everyMonthUsers, setEveryMonthUsers] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [weeklyPayment, setWeeklyPayment] = useState(0);
    const [dailyPayment, setDailyPayment] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [everyMonthPayment, setEveryMonthPayment] = useState(0);

    const countUserInfo = () => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/user/counter`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setTotalUsers(res.data.total_users);
                setWeeklyUsers(res.data.weekly_users);
                setDailyUsers(res.data.daily_users);
                setEveryMonthUsers(res.data.every_month_users);
            }
            paymentInfo();
        }).catch((err) => { checktoken(err); setSpinner(false); })
    }

    const paymentInfo = () => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/payment/details`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setTotalPayment(res.data.totalAmountValue);
                setWeeklyPayment(res.data.totalAmountForWeek);
                setDailyPayment(res.data.totalAmountForTodayValue);
                setEveryMonthPayment(res.data.totalEveryMonthValue);
                setMonthlyPayment(res.data.totalAmountForMonthValue);
            }
            setSpinner(false);
            hideLadingLoader();
        }).catch((err) => { checktoken(err); setSpinner(false); })
    }

    useEffect(() => {
        countUserInfo();
    }, [])

    return (
        <Fragment>
            <Row>
                {spinner === true && <Loader />}
                <Toaster />
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12} className='mb-3'>
                    <div className='widget-box shadow rounded p-3 position-relative'>
                        <div className='icon-box box-orange shadow position-absolute'><FiUsers /></div>
                        <div className='widget-content'>
                            <div className='widget-small-content text-end'>total users</div>
                            <div className='widget-heading text-end'>{totalUsers}</div>
                        </div>
                    </div>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12} className='mb-4'>
                    <div className='widget-box shadow rounded p-3 position-relative'>
                        <div className='icon-box box-green shadow position-absolute'><FiUsers /></div>
                        <div className='widget-content'>
                            <div className='widget-small-content text-end'>weekly users</div>
                            <div className='widget-heading text-end'>{weeklyUsers}</div>
                        </div>
                    </div>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12} className='mb-4'>
                    <div className='widget-box shadow rounded p-3 position-relative'>
                        <div className='icon-box box-skyblue shadow position-absolute'><FiUsers /></div>
                        <div className='widget-content'>
                            <div className='widget-small-content text-end'>daily users</div>
                            <div className='widget-heading text-end'>{dailyUsers}</div>
                        </div>
                    </div>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12} className='mb-4'>
                    <div className='widget-box shadow rounded p-3 position-relative'>
                        <div className='icon-box box-pink shadow position-absolute'><FiDollarSign /></div>
                        <div className='widget-content'>
                            <div className='widget-small-content text-end'>total revenue</div>
                            <div className='widget-heading text-end'>{totalPayment}</div>
                        </div>
                    </div>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12} className='mb-4'>
                    <div className='widget-box shadow rounded p-3 position-relative'>
                        <div className='icon-box box-purple shadow position-absolute'><FiDollarSign /></div>
                        <div className='widget-content'>
                            <div className='widget-small-content text-end'>monthly revenue</div>
                            <div className='widget-heading text-end'>{monthlyPayment}</div>
                        </div>
                    </div>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12} className='mb-4'>
                    <div className='widget-box shadow rounded p-3 position-relative'>
                        <div className='icon-box box-yellow shadow position-absolute'><FiDollarSign /></div>
                        <div className='widget-content'>
                            <div className='widget-small-content text-end'>weekly revenue</div>
                            <div className='widget-heading text-end'>{weeklyPayment}</div>
                        </div>
                    </div>
                </Col>
                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={12} className='mb-4'>
                    <div className='widget-box shadow rounded p-3 position-relative'>
                        <div className='icon-box box-dgreen shadow position-absolute'><FiDollarSign /></div>
                        <div className='widget-content'>
                            <div className='widget-small-content text-end'>daily revenue</div>
                            <div className='widget-heading text-end'>{dailyPayment}</div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xxl={6} xl={6} lg={6} mb={3} sm={6} xs={6} className='my-4'>
                    <div className='widget-box shadow rounded p-3'>
                        <UsersChart usersData={everyMonthUsers} />
                    </div>
                </Col>
                <Col xxl={6} xl={6} lg={6} mb={3} sm={6} xs={6} className='my-4'>
                    <div className='widget-box shadow rounded p-3'>
                        <RevenueChart paymentData={everyMonthPayment} />
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Dashboard;