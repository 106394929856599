import { Row, Col, Form, Button, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap"
import { emptyFiles, handleFileDelete, handleFileUpload } from "../../redux/FileSlice"
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { loginWorkspaceReq, setActiveWorkspace } from "../../redux/workspace.slice"
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"
import BrandVoicePop from "../../components/user/Pops/BrandVoicePop"
import ChatSpinner from "../../components/user/Spinners/ChatSpinner"
import { headers, checktoken, hideLadingLoader } from "../../helper"
import Loader from "../../components/frontend/TextLoader"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { getTotalWordsReq } from "../../redux/chat.slice"
import Toaster from "../../components/frontend/Toaster"
import { useDispatch, useSelector } from "react-redux"
import { setEditorText } from "../../redux/other.slice"
import Modals from "../../components/frontend/Modals"
import Drawer from "../../components/frontend/Drawer"
import { showDialog } from "../../redux/dialog.slice"
import ContentEditable from "react-contenteditable"
import { setChatWords } from "../../actions/index"
import "react-loading-skeleton/dist/skeleton.css"
import Skeleton from "react-loading-skeleton"
import { IconButton } from "@mui/material"
import Images from "../../assets/images"
import { toast } from "react-toastify"
import * as DOMPurify from "dompurify"
import Icons from "../../assets/icons"
import { Helmet } from "react-helmet"
import { Suspense } from "react"
import { lazy } from "react"
import axios from "axios"

const MarkdownViewer = lazy(() => import("../../components/frontend/MarkdownViewer"))

export default function Chat() {
	const chatBlock = useRef();
	const chatActions = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const textareaRef = useRef(null);
	const [chat, setChat] = useState([]);
	const [input, setInput] = useState("");
	const [show, setShow] = useState(false);
	const [stop, setStop] = useState(false);
	const [start, setStart] = useState(false);
	const [handleSidebar] = useOutletContext();
	const [fileUrls, setFileUrls] = useState([]);
	const [prompt, setPrompt] = useState(false);
	const [drawer, setDrawer] = useState(false);
	const [oldInput, setOldInput] = useState("");
	const [username, setUsername] = useState([]);
	const [spinner, setSpinner] = useState(true);
	const [usePrompt, setUsePrompt] = useState("");
	const [brandData, setBrandData] = useState("");
	const [FSpinner, setFSpinner] = useState(false);
	const [promptText, setPromptText] = useState("");
	const [projectname, setProjectname] = useState("");
	const [improveText, setImproveText] = useState("");
	const [chatLoader, setChatLoader] = useState(false);
	const [chatSpinner, setChatSpinner] = useState(true);
	const [rememberChat, setRememberChat] = useState([]);
	const { user } = useSelector(state => state.authSlice);
	const [stopChatInput, setStopChatInput] = useState("");
	const [clearSpinner, setClearSpinner] = useState(false);
	const [startChatInput, setStartChatInput] = useState("");
	const [improveloader, setImproveloader] = useState(false);
	const searchParams = new URLSearchParams(location.search);
	const create_project = searchParams.get("create_project");
	const PID = searchParams.get("project_id");
	const { activeWorkspace } = useSelector(state => state.workspaceSlice);
	const { totalWords: chatWords } = useSelector(state => state.chatSlice);
	const authUser = JSON.parse(localStorage.getItem("authuser"));
	const [controller, setController] = useState(new AbortController());
	const { files: uploadedFiles } = useSelector(state => state.FileSlice);

	const checkPlan = () => {
		if (user?.appsumocode === null) {
			if (chatWords >= 50000 && user?.currentplan?.packagename === "SOLO" && Object.keys(activeWorkspace).length === 0) {
				dispatch(showDialog("ReachLimit"))
				return false
			} else if (activeWorkspace?.userId?.status === "free" || activeWorkspace?.userId?.status === "expired" || activeWorkspace?.userId?.status === "canceled") {
				dispatch(showDialog("ReachLimit"))
				return false
			}
		} else {
			if (Object.keys(activeWorkspace).length === 0) {
				if (user?.appsumouser?.type === "Solo" && chatWords >= 30000) {
					dispatch(showDialog("ReachLimit"))
					return false
				}
			}
		}
		return true
	}

	const handleCopy = (content) => {
		content ?
			toast.success("Response copied to clipboard!") :
			toast.error("Response not found !");
	};

	const handleInputBlur = (e) => {
		e.preventDefault();
		if (create_project !== null) {
			createProject(projectname)
		} else {
			UpdateProject(projectname)
		}
	}

	const UpdateProject = (data) => {
		const id = localStorage.getItem("newProjId");
		axios.put(`${process.env.REACT_APP_API_URL}/project/update/${id}`, { name: data }, {
			headers: headers()
		}).then((res) => {
			setSpinner(false);
			if (res.data.success === true) {
				setProjectname(res.data.data.name);
				localStorage.removeItem("newProjId");
				localStorage.setItem("newProjId", res.data.data._id);
				getProjectPrompts(res.data.data._id);
				toast.success(res.data.message);
			}
		}).catch((err) => { setSpinner(false); checktoken(err) })
	}

	const newProjectClick = () => {
		if (checkPlan()) {
			createProject("Project")
		}
	};

	const handleClose = () => {
		setShow(false)
	}

	const handleShow = (argument) => {
		setPrompt(argument);
		setShow(true);
	}

	const handleStopStream = (e) => {
		setStop(false)
		setStart(true)
		controller.abort()
		setStartChatInput(stopChatInput)
		handleSend(e)
		setController(new AbortController())
	};

	const handleStartStream = (e) => {
		setStop(false);
		setStart(false);
		handleSend(e)
	};

	useMemo(() => {
		const files = uploadedFiles.map((item) => {
			if (item?.fileURL) {
				return item.fileURL;
			}
		})
		setFileUrls(files.filter((item) => item !== undefined))
		return fileUrls;

		// eslint-disable-next-line
	}, [uploadedFiles])

	const savePromptChat = (data) => {
		axios.post(`${process.env.REACT_APP_API_URL}/prompt/create`, data, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				getProjectPrompts((PID !== null) ? PID : localStorage.getItem("newProjId"))
			}
		}).catch((err) => { checktoken(err) })
	}

	const handleSend = async (e) => {
		if (uploadedFiles.length > 0) {
			savePromptChat({
				promptrole: "user",
				prompt: JSON.stringify(uploadedFiles),
				projectId: (PID !== null) ? PID : localStorage.getItem("newProjId"),
				promptresponserole: "assistant",
				promptresponse: null
			})
			savePromptChat({
				promptrole: "user",
				prompt: input,
				projectId: (PID !== null) ? PID : localStorage.getItem("newProjId"),
				promptresponserole: "assistant",
				promptresponse: null
			})
		}
		if (e.key === "Enter") {
			setStartChatInput("")
			setStopChatInput("")
			setStop(false)
			setStart(false)
		}
		if (!checkPlan()) {
			return
		}
		e.preventDefault();
		setChatLoader(true);
		let fileString;
		if (uploadedFiles.length > 0) {
			fileString = JSON.stringify(uploadedFiles)
			setChat([...chat, { promptrole: "user", prompt: JSON.stringify(uploadedFiles) }, { promptrole: "user", prompt: input }]);
		} else {
			setChat([...chat, { promptrole: "user", prompt: input }]);
		}
		dispatch(emptyFiles())
		if (startChatInput !== "") {
			setChat([...chat, { promptrole: "user", prompt: startChatInput }]);
		}
		scrollChat();
		let message;
		if (brandData === "") {
			if (startChatInput !== "") {
				message = JSON.stringify({ messages: [...rememberChat, { role: "user", content: startChatInput || "" }], projectId: PID ?? localStorage.getItem("newProjId"), files: fileUrls });
				setStopChatInput(startChatInput)
			} else {
				message = JSON.stringify({ messages: [...rememberChat, { role: "user", content: input || "" }], projectId: PID ?? localStorage.getItem("newProjId"), files: fileUrls });
				setStopChatInput(input)
			}
		} else {
			if (startChatInput !== "") {
				message = JSON.stringify({ messages: [...rememberChat, { role: "user", content: startChatInput || "" }], brandName: brandData, projectId: PID ?? localStorage.getItem("newProjId"), files: fileUrls })
				setStopChatInput(startChatInput)
			} else {
				message = JSON.stringify({ messages: [...rememberChat, { role: "user", content: input || "" }], brandName: brandData, projectId: PID ?? localStorage.getItem("newProjId"), files: fileUrls })
				setStopChatInput(input)
			}
		}

		// const newArr = rememberChat.map((item) => ({ ...item, content: "" }))

		if (promptText !== null && promptText !== "") {
			setChat([...chat, { promptrole: "user", prompt: e.target.textContent }]);
			message = { messages: [...rememberChat, { role: "user", content: e.target.textContent || "" }], brandName: brandData, projectId: PID ?? localStorage.getItem("newProjId"), files: fileUrls }
			setStopChatInput(textareaRef.current)
		}

		const saveprompt = {
			promptrole: "user",
			prompt: input,
			projectId: (PID !== null) ? PID : localStorage.getItem("newProjId"),
			promptresponserole: "assistant",
		};

		if (promptText !== null && promptText !== "") {
			saveprompt["prompt"] = textareaRef.current;
		}

		if (startChatInput !== "") {
			saveprompt["prompt"] = startChatInput;
			setStartChatInput("")
		}
		// setInput("");
		e.target.textContent = ""
		if (textareaRef.current) {
			textareaRef.current.textContent = ""
		}
		setPromptText("");
		setImproveText("");

		// return
		const newData = JSON.parse(message)
		const newArr = newData?.messages?.map((item) => ({ ...item, content: item?.content ? item?.content : "" }))
		const newMessage = {
			...newData,
			messages: newArr
		}

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/users-chats`, {
				method: "POST",
				responseType: "stream",
				signal: controller.signal,
				headers: headers(),
				body: JSON.stringify(newMessage),
			})
			if (!response.ok) {
				const errorText = await response.text();
				const err = JSON.parse(errorText)
				if (!err?.success && err?.redirect) {
					const data = { id: activeWorkspace._id, email: activeWorkspace.userId.email, status: "remove" }
					dispatch(loginWorkspaceReq({ data, navigate }))
					dispatch(setActiveWorkspace({}))
					window?.location?.reload()
					// navigate("/dashboard")
				}
				return checktoken(err)
			}
			setStop(true)

			//eslint-disable-next-line
			const readData = response.body.pipeThrough(new TextDecoderStream()).getReader();
			setChatLoader(false);
			let aiRes = "";
			const intervalId = scrolToBottom()
			while (true) {
				const { done, value } = await readData.read();
				if (done) {
					if (intervalId) {
						clearInterval(intervalId)
					}
					break
				}
				aiRes += value;

				if (promptText !== null && promptText !== '') {
					setChat([...chat, { promptrole: 'user', prompt: textareaRef.current }, { promptresponserole: 'assistant', promptresponse: aiRes + `<span class="blob-cursor"></span>` }]);
				} else if (startChatInput !== '') {
					setChat([...chat, { promptrole: 'user', prompt: startChatInput }, { promptresponserole: 'assistant', promptresponse: aiRes + `<span class="blob-cursor"></span>` }]);
				} else {
					if (fileString) {
						setChat([...chat, { promptrole: 'user', prompt: fileString }, { promptrole: 'user', prompt: input }, { promptresponserole: 'assistant', promptresponse: aiRes + `<span class="blob-cursor"></span>` }]);
					} else {
						setChat([...chat, { promptrole: 'user', prompt: input }, { promptresponserole: 'assistant', promptresponse: aiRes + `<span class="blob-cursor"></span>` }]);
					}
				}
			}

			if (aiRes.length > 10) {
				setTimeout(function () {
					saveprompt["promptresponse"] = aiRes;
					setStop(false)
					if (uploadedFiles.length > 0) {
						saveprompt["prompt"] = null
					}
					savePromptChat(saveprompt)
				});
			}
			scrollChat();
			const nuser = JSON.parse(localStorage.getItem("authuser"));
			dispatch(getTotalWordsReq(nuser, activeWorkspace))
		} catch (err) {
			checktoken(err);
			setStop(false)
			setChatLoader(false);
			if (!controller.signal.aborted) {
				setChat([...chat, { promptrole: "user", prompt: input }, { promptresponserole: "assistant", promptresponse: `I'm sorry, I didn't quite catch that. Could you please rephrase your question for clarity?` }]);
			}
		}
	}

	const scrolToBottom = () => {
		const intervalId = setInterval(() => {
			const objDiv = chatBlock.current;
			if (objDiv !== null && objDiv !== undefined) {
				objDiv.scrollTo({ top: objDiv.scrollHeight, left: 0, behavior: "smooth" });
			}
		}, 1500)
		return intervalId
	}

	const improveResponse = async () => {
		if (chatWords >= 20000 && user?.plan === "SOLO") {
			dispatch(showDialog("ReachLimit"))
			return false;
		} else if (chatWords >= 40000 && user?.plan === "PLUS") {
			dispatch(showDialog("ReachLimit"))
			return false;
		} else {
			setImproveloader(true);
			setOldInput(input);

			axios.post(`${process.env.REACT_APP_API_URL}/improve/prompt`, { content: input }, {
				headers: headers(),
			}).then((res) => {
				setImproveloader(false);
				if (res.data.success === true) {
					document.querySelector("[contenteditable='true']").innerHTML = res.data.content;
					// setPromptText(res.data.content);
					setImproveText(res.data.content);
					setInput(res.data.content);
					scrollChat();
				}
			}).catch((err) => {
				setImproveloader(false);
				checktoken(err)
			})
		}
	}

	const createProject = (data) => {
		setSpinner(true);
		axios.post(`${process.env.REACT_APP_API_URL}/project/create`, { name: data }, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setProjectname(res.data.data.name);
				localStorage.removeItem("newProjId");
				localStorage.setItem("newProjId", res.data.data._id);
				const searchParams = new URLSearchParams(location.search);
				const PID = searchParams.get("project_id");
				if (PID) {
					navigate("/user/chat")
				}
				getProjectPrompts(res.data.data._id);
				toast.success(res.data.message);
			}
			setSpinner(false);
		}).catch((err) => { setSpinner(false); checktoken(err) })
	}

	const getLatestUserProject = () => {
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/project/lastest`, {
			headers: headers()
		}).then((res) => {
			setSpinner(false);
			if (res.data.success === true) {
				localStorage.removeItem("newProjId");
				localStorage.setItem("newProjId", res.data.data._id);
				setProjectname(res.data.data.name);
				getProjectPrompts(res.data.data._id);
			}

			if (res.data.success === false) {
				createProject("Project")
			}
		}).catch((err) => { setSpinner(false); checktoken(err) })
	}

	const getNameProject = (id) => {
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/project/single/${id}`, {
			headers: headers()
		}).then((res) => {
			setSpinner(false);
			if (res.data.success === true) {
				setProjectname(res.data.data.name);
			}
		}).catch((err) => { setSpinner(false); checktoken(err); })
	}

	const scrollChat = (timer = 100) => {
		setTimeout(function () {
			const objDiv = chatBlock.current;
			if (objDiv !== null && objDiv !== undefined) {
				objDiv.scrollTo({ top: objDiv.scrollHeight, left: 0, behavior: "smooth" });
			}
		}, timer);
	}

	const getProjectPrompts = (id) => {
		if (!id) { return }
		axios.get(`${process.env.REACT_APP_API_URL}/project/${id}/prompt`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success) {
				const arr = [];
				res.data.data.forEach((x) => {
					if (x.type === "chatgpt" && x.prompt !== "") {
						arr.push({ role: x.promptrole, content: x.prompt }, { role: x.promptresponserole, content: x.promptresponse });
					}
				})
				setRememberChat([...arr.slice(Math.max(arr.length - 5, 1))]);
				setRememberChat([...arr]);
				setChat(res.data.data);
				setChatSpinner(false);
				scrollChat();
			} else {
				setChatSpinner(false);
				setRememberChat([]);
				scrollChat();
				setChat([]);
			}
		}).catch((err) => {
			checktoken(err);
			setChatSpinner(false);
			if (err?.response !== undefined) {
				if (err?.response?.data !== undefined) {
					if (err?.response?.data?.data?.length === 0) {
						setChat([]);
					}
				}
			}
		})
	}

	useMemo(() => {
		getProjectPrompts((PID !== null) ? PID : localStorage.getItem("newProjId"))
		return true

		// eslint-disable-next-line
	}, [])

	const clearChat = () => {
		setClearSpinner(true);
		let id = localStorage.getItem("newProjId");
		axios.delete(`${process.env.REACT_APP_API_URL}/prompt/delete/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				getProjectPrompts(id);
				toast.success("Chat Cleared!");
			}
			setClearSpinner(false);
		}).catch((err) => {
			checktoken(err)
			setClearSpinner(false);
			if (err.response !== undefined) {
				if (err.response.data !== undefined) {
					toast.error(err.response.data.error);
				}
			}
		})
	}

	const handleUsePrompt = (e) => {
		textareaRef.current = e.target.value;
	}

	useMemo(() => {
		if (usePrompt !== "") {
			const regex = /\[(.*?)\]/g;
			const modifiedContent = usePrompt.replace(/(<([^>]+)>)/ig, "").replace(regex, (_, keyword) => {
				return `[<span>${keyword}</span>]`;
			}).replaceAll("\\n", "<br>")
			setPromptText(modifiedContent);
			setUsePrompt("");
			textareaRef.current = modifiedContent;
			return textareaRef.current;
		}
	}, [usePrompt])


	useMemo(() => {
		return textareaRef.current;

		// eslint-disable-next-line
	}, [textareaRef.current])

	const handleFavorite = (id) => {
		axios.put(`${process.env.REACT_APP_API_URL}/add/favorite/prompt/${id}`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
				getProjectPrompts(localStorage.getItem("newProjId"));
			}
			setFSpinner(false);
		}).catch((err) => { checktoken(err); setFSpinner(false) })
	}

	useMemo(() => {
		chatWords && localStorage.setItem("totalWords", chatWords);
		chatWords && dispatch(setChatWords(chatWords))
		return true;

		// eslint-disable-next-line
	}, [chatWords])

	const uploadFile = (e) => {
		let flag = true
		const accept = ["jpg", "jpeg", "png", "pdf", "docx", "doc", "txt"]
		const files = e.target.files;
		const filesArray = Array.from(files)
		filesArray.forEach((file) => {
			const nameArr = file.name.split(".")
			const type = nameArr[nameArr.length - 1]
			if (!accept.includes(type)) {
				flag = false
				toast("Invalid file type selected!")
				e.target.value = ""
				return
			}
		});
		if (flag) {
			dispatch(handleFileUpload(e.target.files))
			e.target.value = ""
		}
	}

	const cleanHtml = (html) => DOMPurify.sanitize(html, { ADD_TAGS: ["span"], ADD_ATTR: ["class"] });

	useEffect(() => {
		hideLadingLoader();
		setTimeout(() => {
			setChatSpinner(false)
		}, 2000)
		const nuser = JSON.parse(localStorage.getItem("authuser"));
		dispatch(getTotalWordsReq(nuser))
		if (location.state !== null) {
			if (location.state === "Login") {
				toast.success("Login Successful!");
				location.state = null;
			}
			if (location.state === "Signup") {
				toast.success(`Success! Welcome aboard!!`);
				location.state = null;
			}
		}

		if (authUser) {
			setUsername(authUser.name);
		}

		if (PID !== null) {
			localStorage.removeItem("newProjId");
			localStorage.setItem("newProjId", PID);
			getNameProject(PID);
		}

		if (PID === null && create_project === null) {
			getLatestUserProject();
		}

		if (create_project === "new") {
			createProject("Project")
		}

		setTimeout(() => {
			setSpinner(false);
		}, 100);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(emptyFiles())

		// eslint-disable-next-line
	}, [])

	function isValidJSON(str) {
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	}

	return (
		<Fragment>
			<Helmet>
				<title>AltaChat</title>
				<meta property="og:title" content={"AltaChat"} />
			</Helmet>
			{spinner === true && <Loader />}
			<Toaster />
			<div className="d-flex flex-column position-relative" style={{ height: "100vh" }}>
				{chatSpinner &&
					<div className="new-chat-loader d-flex align-items-center justify-content-center position-absolute top-0 bottom-0 start-0 end-0" style={{ zIndex: "99999", backgroundColor: "#fffffff5" }}>
						<Spinner animation="border" />
					</div>
				}
				<div className="user-dashboard-header d-sm-flex align-items-center justify-content-between border-bottom sticky-sm-top bg-white">
					<div className="flex-grow-1">
						<Form.Group controlId="exampleForm.ControlInput1 project-name">
							<Form.Control value={projectname} onChange={(e) => setProjectname(e.target.value)} onBlur={handleInputBlur} />
						</Form.Group>
					</div>
					<div className="d-xl-block d-flex justify-between">
						<IconButton
							size="small"
							onClick={handleSidebar}
							className="sidebar-menu-btn me-2 !p-[8px] self-start"
							sx={{ display: { laptop: "none" } }}
						><Icons.FiMenu />
						</IconButton>
						<div className="d-sm-flex d-block flex-wrap altachat-btn">
							<button
								className={`btn page-header-btn-light page-header-btn me-2 ${chat.length === 0 && "disabled"}`} onClick={() => { clearChat() }}
							>Clear chat{clearSpinner === true && <Spinner className="ms-2" animation="border" size="sm" />}
							</button>
							<Link to="/user/bookmark" className="d-inline-flex text-center text-decoration-none page-header-btn-dark page-header-btn me-2 align-items-center !bg-gray-800 !border-gray-800">Bookmark</Link>
							<button className="d-inline-flex text-center text-decoration-none page-header-btn-dark page-header-btn align-items-center !bg-gray-800 !border-gray-800" onClick={newProjectClick}>new project</button>
							<Link to="https://buttered-mice-29a.notion.site/Prompting-Guide-ae15b911e415447e85aaffb5ddc7aa8b" target="_blank" className="d-inline-flex text-center text-decoration-none page-header-btn-dark page-header-btn ms-0 align-items-center mt-md-0 ms-md-2" style={{ background: "#7959a6", borderColor: "#7959a6" }}>Prompt Guide</Link>
						</div>
					</div>
				</div>
				<div className={`d-flex flex-column flex-grow-1 chat-block h-100`} ref={chatBlock}>
					{chat.length > 0 ?
						<div className={`hide-scroll-bar chat-section pt-sm-5 pt-2 ${chat.length > 0 ? "d-flex justify-content-end flex-column" : ""}`} >
							{chat.map((item, index) => (
								<Fragment key={index}>
									{(item.promptrole === "user" && item.prompt) &&
										<Fragment>
											{isValidJSON(item.prompt) ?
												<div className="d-flex p-2 upload-file-container gap-1 flex-wrap align-items-end">
													{JSON.parse(item.prompt)?.map((file, i) => (
														<div key={i} className="single-file d-inline-flex align-items-center border rounded p-1 position-relative">
															{file?.fileURL ?
																<img src={file?.fileURL} className="rounded" style={{ width: "100px", height: "100px", objectFit: "contain" }} alt="img" /> :
																<Fragment>
																	<div className="chat-f-icon d-flex align-items-center justify-content-center rounded me-1">
																		<Icons.File className="fs-3" />
																	</div>
																	<div>
																		<p className="p-0 m-0 fw-bold mb-1" style={{ color: "var(--purple)" }}>{file?.name}</p>
																		<p className="p-0 m-0 fw-medium text-uppercase" style={{ color: "var(--purple)" }}>{file?.type?.replace(".", "")}</p>
																	</div>
																</Fragment>
															}
														</div>
													))
													}
												</div> :
												<div className={`chat-response bg-deluge-50 mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}>
													<div className="d-flex ms-auto flex-grow-1 align-items-start">
														<div className="d-block">
															<span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2" style={{ fontSize: "13px" }}>{username.split(" ")[0]?.split("")[0]}{username.split(" ")[1]?.split("")[0]}</span>
														</div>
														<div className="leading-loose w-100" style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
															<div className="question font-semibold text-deluge-500" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.prompt) }} />
														</div>
													</div>
													<div className="d-grid gap-2 d-md-flex justify-content-md-end">
														<Button variant="btn prompt-button border rounded" onClick={() => handleShow(item.prompt)}><Icons.Bookmark /> Save Prompt</Button>
													</div>
												</div>
											}
										</Fragment>
									}

									{item?.promptresponserole === "assistant" && item?.promptresponse &&
										<div className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}>
											<div className="d-block">
												<span className="prompt-small-icon"><img src={Images.favicone} alt="img" style={{ width: "15px", filter: "brightness(0) invert(1)" }} /></span>
											</div>
											<div className="d-block flex-grow-1 answer-div">
												<div className="leading-loose" style={{ fontWeight: 500 }}>
													{
														(item?.promptresponse?.indexOf("textend", "") === -1) ?
															<Suspense fallback={<ChatSkell />}>
																<MarkdownViewer className="answer-content" content={cleanHtml(item?.promptresponse?.replaceAll("GPT-3", "GPT-4")?.replaceAll("gpt-3", "GPT-4")?.replaceAll(/【.*?】/g, ""))} />
															</Suspense> :
															<Suspense fallback={<ChatSkell />}>
																<MarkdownViewer content={DOMPurify.sanitize(item?.promptresponse?.slice(0, item.promptresponse?.indexOf("textend", ""))?.replaceAll("...", "")?.replaceAll("GPT-3", "GPT-4")?.replaceAll("gpt-3", "GPT-4")?.replaceAll(/【.*?】/g, ""))} />
															</Suspense>
													}

													{item?.promptresponse?.indexOf("textend", "") !== -1 && item?.promptresponse?.slice(item?.promptresponse?.indexOf("textend", "")).replace("textend", "", "").split(",").map((item, index) => {
														return (
															<div key={index} className="mt-1 d-flex">
																[{index + 1}] : <a href={item} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 400 }}>{item}</a>
															</div>
														)
													})
													}
												</div>
												{
													item?.promptresponse && item._id &&
													<div className="d-flex mt-3 align-items-center justify-content-end">
														<div className="d-flex">
															<CopyToClipboard text={item?.promptresponse}>
																<button className="btn prompt-button border rounded me-2 d-flex align-items-center justify-content-center" onClick={() => handleCopy(item?.promptresponse)}>
																	<Icons.Clipboard className="me-1" /> Copy
																</button>
															</CopyToClipboard>
															<Button variant="btn prompt-button border rounded d-flex align-items-center justify-content-center me-2" onClick={() => handleFavorite(item._id)}>
																{item.isFavorite === true ? <Icons.Bookmark className="me-1" style={{ fill: "#4BC8F1", stroke: "#4BC8F1" }} /> : <Icons.Bookmark className="me-1" />
																}Bookmark {FSpinner === true && <Spinner className="ms-2" animation="border" size="sm" />}
															</Button>
															{!user?.appsumocode &&
																<Button variant="btn prompt-button border rounded d-flex align-items-center justify-content-center"
																	onClick={(e) => {
																		const parent = e.target.closest(".chat-response")
																		const child = parent.querySelector(".markdown-body")
																		setDrawer(true)
																		dispatch(setEditorText(child.innerHTML))
																	}}
																><Icons.Edit1 className="me-1" />Add to Editor
																</Button>
															}
														</div>
													</div>
												}
											</div>
										</div>
									}
								</Fragment>
							))
							}
							{chatLoader === true && <ChatSpinner />}
						</div> :
						<Fragment>
							<div className="d-none d-sm-block text-center">
								<img className="h-[100px] w-[300px] mx-auto object-contain" src={Images.sublogo} alt="img" />
							</div>
							<div className="flex flex-col justify-center items-center mt-auto">
								<div className="welcome-chat-content mt-auto">
									<div className="d-block d-sm-none text-center">
										<img className="h-[100px] w-[300px] mx-auto object-contain" src={Images.sublogo} alt="img" />
									</div>
									<Row className="chat-square-box">
										<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3 order-md-0 order-2">
											<div className="prompt-box d-flex p-[5px] h-100 items-stretch">
												<div className="flex w-full gap-2 p-1 bg-deluge-50">
													<div>
														<Icons.Code className="m-1" />
													</div>
													<div className="d-block flex-grow">
														<h5 className="font-bold text-deluge-600">Code Generation</h5>
														<p className="font-ff-poppins text-[13px]">
															<span type="submit" onClick={() => { setInput("Generate Python code to scrape a website for data"); textareaRef.current.textContent = "Generate Python code to scrape a website for data" }}>"Generate Python code to scrape a website for data"</span><br></br>
															<span type="submit" onClick={() => { setInput("Create a JavaScript function to validate email addresses"); textareaRef.current.textContent = "Create a JavaScript function to validate email addresses" }}>"Create a JavaScript function to validate email addresses"</span>
														</p>
													</div>
												</div>
											</div>
										</Col>
										<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3 order-md-0 order-2">
											<div className="prompt-box d-flex p-[5px] h-100">
												<div className="flex w-full gap-2 p-1 bg-deluge-50">
													<div>
														<Icons.FileAnalysis className="m-1" />
													</div>
													<div className="d-block flex-grow">
														<h5 className="font-bold text-deluge-600">File Analysis</h5>
														<p className="font-ff-poppins text-[13px]">
															<span type="submit" onClick={() => { setInput("Analyze this legal contract and extract key terms"); textareaRef.current.textContent = "Analyze this legal contract and extract key terms" }}>"Analyze this legal contract and extract key terms"</span> <br></br>
															<span type="submit" onClick={() => { setInput("Describe and identify objects in this image"); textareaRef.current.textContent = "Describe and identify objects in this image" }}>"Describe and identify objects in this image"</span>
														</p>
													</div>
												</div>
											</div>
										</Col>
										<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3 d-none d-md-block order-md-0 order-1">
											<div className="prompt-box d-flex p-[5px] h-100">
												<div className="flex w-full gap-2 p-1 bg-deluge-50">
													<div>
														<Icons.Edit className="m-1" />
													</div>
													<div className="d-block flex-grow">
														<h5 className="font-bold text-deluge-600">Content Creation</h5>
														<p className="font-ff-poppins text-[13px]">
															<span type="submit" onClick={() => {
																setInput("Write a blog post about the benefits of remote work");
																textareaRef.current.textContent = "Write a blog post about the benefits of remote work"
															}}>"Write a blog post about the benefits of remote work"</span> <br></br>
															<span type="submit" onClick={() => { setInput("Create a LinkedIn post about the latest tech trends"); textareaRef.current.textContent = "Create a LinkedIn post about the latest tech trends" }}>"Create a LinkedIn post about the latest tech trends"</span>
														</p>
													</div>
												</div>
											</div>
										</Col>
										<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3 d-none d-md-block order-md-0 order-1">
											<div className="prompt-box d-flex p-[5px] h-100">
												<div className="flex w-full gap-2 p-1 bg-deluge-50">
													<div>
														<Icons.Mail className="m-1" />
													</div>
													<div className="d-block flex-grow">
														<h5 className="font-bold text-deluge-600">Email Templates</h5>
														<p className="font-ff-poppins text-[13px]">
															<span type="submit" onClick={() => { setInput("Generate a cold email template for sales outreach"); textareaRef.current.textContent = "Generate a cold email template for sales outreach" }}>"Generate a cold email template for sales outreach"</span> <br></br>
															<span type="submit" onClick={() => { setInput("Create a welcome email template for new subscribers"); textareaRef.current.textContent = "Create a welcome email template for new subscribers" }}>"Create a welcome email template for new subscribers"</span>
														</p>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</div>
								{chatLoader === true && <ChatSpinner />}
							</div>
						</Fragment>
					}
				</div>
				<div className="chat-actions sticky-bottom bg-white" style={{ zIndex: "2" }} ref={chatActions}>
					{(chatLoader === false) &&
						<div className="d-grid gap-2 d-md-flex justify-content-md-end">
							{(stop === true) &&
								<button className="btn btn-light chat-btn d-flex align-items-center mt-2" onClick={(e) => { handleStopStream(e, "") }}><Icons.Hash className="me-1" /> Stop generating</button>
							}
							{(start === true) &&
								<button className="btn btn-light chat-btn text-capitalize d-flex align-items-center mt-2" onClick={(e) => { handleStartStream(e, "") }}><Icons.Refresh className="me-1" /> Regenerate</button>
							}
						</div>}
					<div className="mt-2 chat-input-group">
						{(promptText === "") ?
							<Fragment>
								{uploadedFiles.length > 0 &&
									<div className="d-flex p-2 upload-file-container overflow-x-auto">
										{uploadedFiles?.map((file, i) => (
											<div key={i} className="single-file d-flex align-items-center border rounded p-1 me-2 position-relative">
												<div>
													{file?.status ?
														<Spinner className="me-1" style={{ height: "28px", width: "28px" }} /> :
														<Icons.File className="me-1 fs-3" />
													}
												</div>
												<div>
													<p className="p-0 m-0 fw-bold mb-1">{file?.name}</p>
													<p className="p-0 m-0 fw-medium text-uppercase">{file?.status ? file?.type : file?.type?.replace(".", "")}</p>
												</div>
												{!file.status &&
													<Button
														className="clo-btn"
														onClick={() => { dispatch(handleFileDelete(file?._id)) }}
													><Icons.Cross />
													</Button>
												}
											</div>
										))
										}
									</div>
								}

								{(window.innerWidth > 767) ?
									<Form className="desktop-input" onKeyDown={(e) => { (e.key === "Enter" && !e.shiftKey) && e.preventDefault(); setStartChatInput("") }}>
										<Form.Control
											ref={textareaRef}
											data-placeholder="Type your message here..."
											onPaste={(e) => {
												e.preventDefault();
												var text = (e.originalEvent || e).clipboardData.getData("text/plain");
												document.execCommand("insertHTML", false, text);
											}}
											onClick={(e) => {
												e.target.focus();
											}}
											onKeyUp={(e) => {
												if (e.key === "Enter" && !e.shiftKey && e.target.textContent.trim() !== "") {
													handleSend(e);
												} else {
													setInput(e.target.textContent)
												}
											}}
											tabIndex={0}
											as="div"
											style={{ overflowY: "scroll", resize: "none", }}
											contentEditable />
									</Form> :
									<Form className="mobile-input">
										<Form.Control placeholder="Type your message here..." onChange={(e) => { setInput(e.target.value); }} value={input} as="textarea" style={{ overflowY: "scroll", resize: "none", }} />
									</Form>
								}
							</Fragment>
							:
							<Fragment>
								{(window.innerWidth > 767) ?
									<ContentEditable
										tagName="div"
										className={`use-prompt-text ${improveText !== "" ? "break-spaces" : ""} `}
										html={DOMPurify.sanitize(textareaRef?.current?.trim())}
										editable={true}
										onChange={(e) => { handleUsePrompt(e); }}
										onKeyDown={(e) => {
											(e.key === "Enter" && !e.shiftKey) &&
												handleSend(e);
										}}
									/> :
									<ContentEditable
										tagName="div"
										className={`use-prompt-text ${improveText !== "" ? "break-spaces" : ""} `}
										html={DOMPurify.sanitize(textareaRef?.current?.trim())}
										editable={true}
										onChange={(e) => { handleUsePrompt(e); }}
									/>
								}
							</Fragment>
						}
						<div className="d-sm-flex d-block align-items-center justify-content-between p-3 pt-0">
							<div className="d-flex">
								<button type="button" onClick={() => { handleShow("") }} className="btn btn-light chat-btn text-capitalize d-flex align-items-center me-2 tour-step-2"><Icons.File className="me-1" /> browse prompts</button>
								{((JSON.parse(localStorage.getItem("authuser"))?.status === "free" || JSON.parse(localStorage.getItem("authuser"))?.status === "canceled") && JSON.parse(localStorage.getItem("authuser"))?.appsumocode === null) ?
									<Link className="btn btn-light chat-btn text-capitalize d-flex align-items-center" to="/pricing"><Icons.BarChart className="me-1" /> brand voice</Link> :
									<BrandVoicePop setBrandData={setBrandData} />
								}
								{!user?.appsumocode &&
									<button className="btn btn-light chat-btn chat-btn file-upload ms-2 position-relative tour-step-3">
										<input type="file" className="position-absolute top-0 bottom-0 start-0 end-0"
											onChange={uploadFile} multiple />
										<span className="d-flex align-items-center justify-center fs-6"><Icons.Pin />Upload</span>
									</button>
								}
							</div>
							<div className="d-flex align-items-center mt-2 mt-sm-0">
								{(input.length < 8 || input.trim() === "" || chatLoader === true) ?
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Type a little more to use this feature</Tooltip>}>
										<button disabled className="btn btn-light chat-btn text-capitalize d-flex align-items-center me-2 tour-step-4" style={{ pointerEvents: "none" }}>
											<Icons.Hash className="me-1" />  improve
										</button>
									</OverlayTrigger>
									:
									<button className="btn btn-light chat-btn text-capitalize d-flex align-items-center me-2 tour-step-4" onClick={improveResponse} ><Icons.Hash className="me-1" /> improve {(improveloader === true) && <Spinner className="ms-2" animation="border" size="sm" />}</button>
								}

								{
									improveText !== "" && <button className="btn btn-light chat-btn text-capitalize d-flex align-items-center me-2" onClick={() => { setInput(oldInput); setPromptText(""); }}> undo</button>
								}

								{
									((input.trim() === "" && promptText === "") || chatLoader === true) ?
										<Button className="disabled tt"><Icons.FiSend className="d-none" /><Icons.FiSend /></Button> :
										<Button className="" onClick={(e) => { setStartChatInput(""); setStop(false); setStart(false); handleSend(e) }}><Icons.FiSend /></Button>
								}
							</div>

						</div>
					</div>
					<div className="d-block text-center my-md-3 my-2">
						<Link to="https://uu2jrgc224l.typeform.com/to/FbBo1dax" target="_blank"><small className="welcome-color text-slate-500">Give us your feedback!</small></Link>
					</div>
				</div>
			</div>

			<Modals show={show} handleclose={handleClose} prompt={prompt} setUsePrompt={setUsePrompt} />
			{!user?.appsumocode &&
				<Fragment>
					<Drawer show={drawer} setDrawer={setDrawer} />
					<Button className="ms-auto editor-open-btn tour-step-5" style={{ backgroundColor: "#7959a6", borderColor: "#7959a6", zIndex: "100" }} onClick={() => { setDrawer(true) }}>Editor</Button>
				</Fragment>
			}
		</Fragment >
	)
}

const ChatSkell = () => {
	return (
		<>
			<Skeleton />
			<Skeleton />
			<Skeleton width="25%" />
		</>
	)
}