import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: false,
  type: "",
  otpData: {},
}
const dialog = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    showDialog: (state, { payload }) => {
      state.show = true
      state.type = payload
    },
    showOtpDialog: (state, { payload }) => {
      state.show = true
      state.type = payload.type
      state.otpData = payload.data
    },
    hideDialog: (state) => {
      state.show = false
      state.type = ""
    },
    showShareWorkspaceDialog: (state, { payload }) => {
      state.show = true
      state.type = "ShareWorksapce"
      state.shareToken = payload
    },
  }
})

export const { showDialog, hideDialog, showOtpDialog, showShareWorkspaceDialog } = dialog.actions
export default dialog.reducer