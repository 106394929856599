import { headers, checktoken, hideLadingLoader } from '../../../helper';
import { Table, Card, Pagination, Button } from 'react-bootstrap';
import { FiPlus, FiEye, FiEdit2, FiTrash } from "react-icons/fi";
import Loader from "../../../components/frontend/TextLoader";
import Toaster from '../../../components/frontend/Toaster';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const Category = () => {
    const [page, setPage] = useState(1);
    const [spinner, setSpinner] = useState(false);
    const [pagination, setPagination] = useState([]);
    const [categories, setCategories] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    const categoryList = (page) => {
        setSpinner(true)
        axios.get(`${process.env.REACT_APP_API_URL}/admin/prompt/category/list?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategories(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            } else {
                toast.error(res.data.error);
            }
            setSpinner(false)
            hideLadingLoader();
        }).catch((err) => {
            setSpinner(false)
            checktoken(err);
            hideLadingLoader();
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    const deletePromptCategory = (id) => {
        setSpinner(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/prompt/category/delete/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                categoryList(page)
            }
        }).catch((err) => {
            setSpinner(false);
            checktoken(err);
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        categoryList(1);
    }, [])

    return (
        <Fragment>
            {spinner === true && <Loader />}
            <Toaster />
            <Card className="shadow-lg border-0">
                <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                    <Card.Title className="m-0 text-capitalize">Categories <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                    <Link to="/admin/add-prompts-cate" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiPlus /> category</Link>
                </Card.Header>
                <Card.Body>
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categories && categories.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {page > 1 ?
                                                        (10 * page) + (index - 9) : index + 1
                                                    }
                                                </td>
                                                <td className='text-capitalize'>{item.name}</td>
                                                <td>
                                                    <Link className="btn btn-sm bg-info me-1 text-white" to={`/admin/list/prompt/${item._id}`}><FiEye /></Link>
                                                    <Link className="btn btn-sm bg-primary me-1 text-white" to={`/admin/prompt/category/edit/${item._id}`}><FiEdit2 /></Link>
                                                    <Button type="button" variant="danger btn btn-sm" onClick={() => { deletePromptCategory(item._id) }}><FiTrash /></Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                    {
                        pagination.length > 1 &&
                        <Pagination className='mt-3 justify-content-center'>
                            {
                                pagination.map((item, index) => {
                                    return (
                                        <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); categoryList(item) }}>{item}</Pagination.Item>)
                                })
                            }
                        </Pagination>
                    }
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default Category;