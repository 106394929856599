import Loader from '../../../components/frontend/TextLoader';
import { Card, Form, Row, Col, Button} from 'react-bootstrap';
import { useState, Fragment, useEffect } from "react";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';

const Withdrawfunds = ()=>{
    const [spinner, setSpinner] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const { id } = useParams();
    const [data, setData] = useState({
        name:'',
        amount:'',
        account_number:'',
        confirm_account_number:'',
        ifsc_code:'',
    });
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleChange = (e)=>{
        let value = e.target.value;
        let name = e.target.name;
        setData({
            ...data,
            [name]:value
        })
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        setSpinner('true');
    }

    useEffect(()=>{
        setTimeout(()=>{
            setSpinner(false);
        }, 100);
    },[]);

    return (
        <Fragment>
            {
                spinner === true ? 
                <Loader/>:''
            }
            <ToastContainer autoClose={2000} theme="dark" closeOnClick newestOnTop={true}/>
            <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="user-dashboard-header d-flex border-bottom flex-column">
                        <h5 className='m-0 mb-2 page-header'>Withdraw funds</h5>
                    </div>
                    <div className="d-block brand-form">
                    <Card className="shadow-lg border-0">
                        <ToastContainer newestOnTop={true} theme="dark" pauseOnHover closeOnClick autoClose={10000}/>
                        <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                            <Card.Title className="m-0 text-capitalize">Withdraw funds</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="m-auto">
                                        <Row className="my-5">
                                            <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                                <Form.Label>Account Holder Name</Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Enter Name" name="name" value={data.name} onChange={handleChange}/>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                                <Form.Label>Amount</Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="number" placeholder="Enter amount" name="amount" value={data.amount} onChange={handleChange}/>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                                <Form.Label>Account Number </Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="password" placeholder="account number" name="account_number" value={data.account_number} onChange={handleChange}/>
                                                </Form.Group>
                                            </Col>

                                            <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                                <Form.Label>Confirm Account Number </Form.Label>
                                                <Form.Group className="mb-3">
                                                <Form.Control type={showPassword ? 'text' : 'password'} name="confirm_account_number" placeholder="confirm account number" value={data.confirm_account_number} onChange={handleChange}/>
                                                <span className="password-eyes" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <FiEyeOff/> : <FiEye/>}
                                                </span>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                                <Form.Label>IFSC Code</Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Form.Control type="text" placeholder="Enter IFSC" name="amount" value={data.ifsc_code} onChange={handleChange}/>
                                                </Form.Group>
                                            </Col>
                                            
                                        
                                            <Col xxl={12} xl={12} lg={12} md={6} sm={6} xs={12}>
                                                <Form.Group>
                                                    <Button type="submit" className="text-capitalize">Submit</Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Withdrawfunds;