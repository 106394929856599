
import { Card, Table, Form, Pagination } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from '../../../components/frontend/Toaster';
import { useEffect, useState, Fragment } from 'react';
import { FiPlus, FiEye } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';

const Templates = () => {
    const [page, setPage] = useState(1);
    const [spinner, setSpinner] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const templateList = (page) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/template/list?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setTemplates(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            } else {
                setTemplates([]);
                toast.error(res.data.error);
            }
            setSpinner(false);
        }).catch((err) => { setSpinner(false); })
    }

    const handleSearch = (searchQuery) => {
        axios.post(`${process.env.REACT_APP_API_URL}/template/search`, { name: searchQuery }, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setTemplates(res.data.data);
            }
        }).catch((err) => { })
    }

    useEffect(() => {
        hideLadingLoader();
        templateList(page);
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">templates <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                <div className="d-flex">
                    <Form.Control type="search" placeholder="Search" className="me-2 search-form" aria-label="Search" name="searchQuery" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value); handleSearch(searchQuery) }} />
                    <Link to="/admin/add-template" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiPlus /> template</Link>
                </div>

            </Card.Header>
            <Card.Body>
                {(templates.length > 0) &&
                    <Fragment>
                        <div className="table-responsive">
                            <Table striped hover bordered>
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Name</th>
                                        <th>description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        templates && templates.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{page > 1 ?
                                                        (10 * page) + (index - 9) : index + 1
                                                    }</td>
                                                    <td className='text-capitalize'>{item.name?.length > 30 ? item.name?.slice(0, 30) + '...' : item.name}</td>
                                                    <td className='text-capitalize'>{item.description?.length > 50 ? item.description?.slice(0, 50) + '...' : item.description}</td>
                                                    <td>
                                                        <Link to={`/admin/template/edit/${item._id}`} className='btn btn-sm btn-primary'><FiEye /></Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-block">
                            {
                                pagination.length > 1 &&
                                <Pagination className='mt-3 justify-content-center'>
                                    {
                                        pagination.map((item, index) => {
                                            return (
                                                <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); templateList(item) }}>{item}</Pagination.Item>)
                                        })
                                    }
                                </Pagination>
                            }
                        </div>
                    </Fragment>

                }
            </Card.Body>
        </Card>
    )
}

export default Templates;