import { headers, checktoken, hideLadingLoader } from '../../../helper';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import Toaster from '../../../components/frontend/Toaster';
import { useEffect, useState } from 'react';
import { FiList } from "react-icons/fi";
import { toast } from 'react-toastify';
import axios from 'axios';

const EditTemplateCategory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(true);
    const [categoryName, setCategoryName] = useState('')

    const TemplateCategoryDetail = (id) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/template/category/edit/${id}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setCategoryName(res.data.data.name)
            }
            setSpinner(false);
        }).catch((err) => {
            setSpinner(false);
            checktoken(err);
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = { name: categoryName };
        axios.put(`${process.env.REACT_APP_API_URL}/admin/template/category/update/${id}`, formData, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.message);
                navigate('/admin/templates-categories');
            }
        }).catch((err) => {
            checktoken(err)
            if (err?.response?.data?.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        hideLadingLoader();
        if (id !== null && id !== undefined) {
            TemplateCategoryDetail(id);
        }
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Edit category</Card.Title>
                <Link to="/admin/templates-categories" className="text-capitalize bg-primary text-decoration-none text-white btn"><FiList /> category</Link>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className='m-auto'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Name" name="categoryName" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Button type="submit" variant='success text-capitalize'>submit</Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )

}

export default EditTemplateCategory;