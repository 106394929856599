import { Table, Card, Form, Badge, Pagination } from 'react-bootstrap';
import Loader from "../../../components/frontend/TextLoader";
import { headers, hideLadingLoader } from '../../../helper';
import Toaster from "../../../components/frontend/Toaster";
import { useEffect, useState } from 'react';
import axios from 'axios';

const PaymentList = () => {
    const [page, setPage] = useState(1);
    const [spinner, setSpinner] = useState(true);
    const [payments, setPayments] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const handleSearch = (searchQuery) => {
        axios.post(`${process.env.REACT_APP_API_URL}/payment/search`, { name: searchQuery }, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setPayments(res.data.data);
            }
        }).catch((err) => { })
    }
    const dateTimeFormate = (seconds) => {
        const date = new Date(seconds * 1000);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-in', options);
        return formattedDate;
    }

    const payList = (page) => {
        setSpinner(true);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/active-payment-list?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setPayments(res.data.data);
                setPage(res.data.current);
                setTotalRecords(res.data.total);
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            }
            setSpinner(false);
        }).catch((err) => { setSpinner(false) })
    }

    useEffect(() => {
        hideLadingLoader();
        payList(page);
    }, []);

    return (
        <Card className="shadow-lg border-0">
            {spinner === true && <Loader />}
            <Toaster />
            <Card.Header className="p-3 d-flex align-items-center justify-content-between">
                <Card.Title className="m-0 text-capitalize">Transactions <small style={{ fontSize: '12px' }}>total records: {totalRecords}</small></Card.Title>
                <Form.Control style={{ maxWidth: '200px' }} type="search" placeholder="Search" className="me-2 search-form" aria-label="Search" name="searchQuery" value={searchQuery} onKeyUp={(e) => { handleSearch(searchQuery) }} onChange={(e) => { setSearchQuery(e.target.value); }} />
            </Card.Header>
            <Card.Body>
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Email Id</th>
                                <th>Subscription Id</th>
                                <th>Amount</th>
                                <th>Currency</th>
                                <th>Subscription</th>
                                <th>Start Date</th>
                                <th>Exp Date</th>
                                <th>status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                payments && payments.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {page > 1 ?
                                                    (10 * page) + (index - 9) : index + 1
                                                }
                                            </td>
                                            <td>{item.userId?.email}</td>
                                            <td>{item.subscriptionId}</td>
                                            <td className="fw-bold">${item.amount}</td>
                                            <td className="text-uppercase fw-bold">{item.currency}</td>
                                            <td className="text-capitalize">{item.interval + 'ly'}</td>
                                            <td className="fw-bold">{dateTimeFormate(item.start_date)}</td>
                                            <td className="fw-bold">{dateTimeFormate(item.exp_date)}</td>
                                            <td className="text-capitalize text-info">
                                                {item.status === 'free' ? <Badge bg="info">{item.status}</Badge> : item.status === 'active' ? <Badge bg="success">{item.status}</Badge> : item.status === 'canceled' ? <Badge bg="danger">{item.status}</Badge> : <Badge bg="info">free</Badge>}

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                {
                    pagination.length > 1 &&
                    <Pagination className='mt-3 justify-content-center'>
                        {
                            pagination.map((item, index) => {
                                return (
                                    <Pagination.Item key={index} active={item === +page} onClick={(e) => { e.preventDefault(); payList(item) }}>{item}</Pagination.Item>)
                            })
                        }
                    </Pagination>
                }
            </Card.Body>
        </Card>
    )
}

export default PaymentList;